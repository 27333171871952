import { styled } from '@frontend/theme';
import { Tab as MUITab } from '@mui/material';

export const TabSt = styled(MUITab)(({ theme }) => ({
  '&.MuiTab-root': {
    textTransform: 'none',
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: '100%',
    minHeight: '29px',
    padding: '0 0 4px 0',
    color: theme.palette.neutral[500],
    borderBottomWidth: '3px',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.neutral[50],
  },
}));
