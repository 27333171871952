import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const PickerToolbarWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.neutral[50],
  width: '100%',
  '& > .MuiTypography-root': {
    padding: '16px 20px',
  },
  '& > .MuiIconButton-root': {
    margin: '4px 12px',
  },
}));
