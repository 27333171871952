import { IDocumentContext } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Typography } from '@mui/material';
import { ContainerDocumentsWrapper } from './ContainerDocuments.css';
import { ContainerDocumentsProps } from './ContainerDocuments.types';
import DocumentList from '../../../../../../DocumentList/DocumentList';

export function ContainerDocuments({
  documents,
  uuid,
  containerNumber,
}: ContainerDocumentsProps) {
  const documentContextList: IDocumentContext[] = documents
    ? documents.map(document => {
        return {
          document,
          parentRecordPublicId: containerNumber,
          parentRecordId: uuid,
          parentRecordType: 'container',
        };
      })
    : [];
  return (
    <ContainerDocumentsWrapper data-testid="container-documents">
      <Typography variant="h5">
        <Translate
          id={
            'customer-platform.request-details.order-list.container-drawer.container-documents.title'
          }
        />
      </Typography>
      <DocumentList
        documentContextList={documentContextList}
        fullWidth={true}
      />
    </ContainerDocumentsWrapper>
  );
}

export default ContainerDocuments;
