import { styled } from '@frontend/theme';
import { Container as MUIContainer } from '@mui/material';

export const MainWrapper = styled(MUIContainer)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  width: '100%',
  backgroundColor: theme.palette.tertiary[500],
  padding: '8px 16px',
  margin: 0,
}));
