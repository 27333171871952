import { IDocument } from './getDocument.types';
import { documentTypesTranslations } from './getDocument.const';

export const formatDocumentName = (documentType: string): string => {
  const docName = documentType.split('.');
  return docName[docName.length - 1];
};

export const getDocumentNameID = ({ documentType, title }: IDocument) => {
  const docName = formatDocumentName(documentType as string);
  if (docName === 'other') {
    return title;
  } else {
    return documentTypesTranslations[docName] ?? '';
  }
};

export const getHumanReadableName = (document: IDocument): string => {
  const { documentType, title, subTitle } = document;

  const docName = documentType?.includes('other')
    ? `${title}${subTitle ? '-' + subTitle : ''}`
    : `${formatDocumentName(documentType as string)}${subTitle ? '-' + subTitle : ''}`;

  // This function replaces the usage of 'capitalize' from lodash by replacing the first char by an upperCase
  // and lowercasing the rest (same functionality). The reason: fixtures are now shared between unitary tests
  // and playwright, since playwright is configured to be a commonJS module, it cannot process 'lodash' so the
  // integration test will fail if you try to import it normally, leaving it as lodash/capitalize.js was breaking
  // static-website, so instead of choosing what to break it was redone.
  const capitalize = (string: string): string =>
    string.length > 0
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : string;

  return capitalize(docName);
};
