import { InnerCircle, OuterCircle } from './LocationMarker.css';
import LocationMarkerProps from './LocationMarker.types';

const LocationMarker = ({ active, children, left }: LocationMarkerProps) => {
  return (
    <OuterCircle active={active} left={left}>
      <InnerCircle>{children}</InnerCircle>
    </OuterCircle>
  );
};

export default LocationMarker;
