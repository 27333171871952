import { BiddableListing } from '@frontend/api';
import { formatNumberToUS } from '@frontend/utils';
import { Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';
import {
  BiddableListingDetailsDataItemLabelWrapper,
  BiddableListingDetailsDataItemListWrapper,
  BiddableListingDetailsDataItemWrapper,
  BiddableListingDetailsHeadingWrapper,
  BiddableListingDetailsWrapper,
} from './BiddableListingDetails.css';
import { BiddableListingDetailsDataItemProps } from './BiddableListingDetails.types';
import { RiArrowUpDownLine } from '@remixicon/react';
import { RiMapPin2Line } from '@remixicon/react';
import { RiRecycleLine } from '@remixicon/react';

const BiddableListingCard = ({
  metalType,
  productDescription,
  isriGrade,
  quantity,
  materialFromCountry,
}: BiddableListing) => {
  const intl = useIntl();
  const BiddableListingCardDataItem = ({
    icon,
    label,
    value,
  }: BiddableListingDetailsDataItemProps) => {
    return (
      <BiddableListingDetailsDataItemWrapper>
        <BiddableListingDetailsDataItemLabelWrapper>
          {icon}
          <Typography variant="p2">{label}</Typography>
        </BiddableListingDetailsDataItemLabelWrapper>
        <Typography variant="p4">{value ?? '-'}</Typography>
      </BiddableListingDetailsDataItemWrapper>
    );
  };

  return (
    <BiddableListingDetailsWrapper>
      <BiddableListingDetailsHeadingWrapper>
        <Typography variant="h5">
          {capitalize(
            intl.formatMessage({
              id: `customer-platform.api.${metalType}`,
              defaultMessage: '-',
            }),
          )}
        </Typography>
        <Typography variant="p3">{productDescription || '-'}</Typography>
      </BiddableListingDetailsHeadingWrapper>
      <BiddableListingDetailsDataItemListWrapper>
        <BiddableListingCardDataItem
          icon={<RiRecycleLine size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.isri_grade',
          })}
          value={intl.formatMessage({
            id: `customer-platform.api.${isriGrade}`,
            defaultMessage: '-',
          })}
        />
        <BiddableListingCardDataItem
          icon={<RiArrowUpDownLine size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.quantity',
          })}
          value={`${formatNumberToUS(quantity)} ${intl.formatMessage({ id: 'customer-platform.bidding.biddable-listing-card.data-item.weight-unit' })}`}
        />
        <BiddableListingCardDataItem
          icon={<RiMapPin2Line size={16} />}
          label={intl.formatMessage({
            id: 'customer-platform.bidding.biddable-listing-card.data-item.material_from_country',
          })}
          value={
            materialFromCountry !== ''
              ? intl.formatMessage({
                  id: `customer-platform.api.country.${materialFromCountry}`,
                  defaultMessage: materialFromCountry,
                })
              : '-'
          }
        />
      </BiddableListingDetailsDataItemListWrapper>
    </BiddableListingDetailsWrapper>
  );
};

export default BiddableListingCard;
