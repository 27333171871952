import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import fileSaver from 'file-saver';
import { IGenericFile } from '../getImage/getImage.types';

const useDownloadContainerImage = () => {
  const { apiUrl } = useContext(ApiContext);
  const downloadContainerImage = async (
    containerId: string,
    image: IGenericFile,
  ) => {
    return axiosClient
      .get(
        `${apiUrl}/orders/containers/${containerId}/loading-photos/${image.uniqueIdentifier}/download/`,
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        const imageName = image?.name
          ? `${image.name}.${image.fileType}`
          : `img_${image.uniqueIdentifier}.${image.fileType}`;

        fileSaver.saveAs(response.data, imageName);
      });
  };

  return { downloadContainerImage };
};
export default useDownloadContainerImage;
