import { OrderDocumentsProps } from './OrderDocuments.types';
import { MainWrapper } from './OrderDocuments.css';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Translate } from '@frontend/translation';
import DocumentList from '../DocumentList';
import { IDocumentContext } from '@frontend/api';

export default function OrderDocuments({
  order,
  documentsSectionRef,
}: OrderDocumentsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const documentContextList: IDocumentContext[] = order.documents
    ? order.documents.map(document => {
        return {
          document,
          parentRecordPublicId: order.publicId,
          parentRecordId: order.uuid,
          parentRecordType: 'order',
        };
      })
    : [];
  return (
    <MainWrapper ref={documentsSectionRef}>
      <Typography variant={isMobile ? 'h4' : 'h5'}>
        <Translate id="customer-platform.request-details.order-card.documents" />
      </Typography>
      <DocumentList documentContextList={documentContextList} />
    </MainWrapper>
  );
}
