import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import getQueryKey from './deleteSellerOrderFiles.const';
import {
  ISellerOrderFilesDeleteData,
  ISellerOrderFilesDeleteErrors,
  ISellerOrderFilesDeleteFileVariant,
} from './deleteSellerOrderFiles.types';

const deleteSellerOrderFiles = (
  apiUrl: string,
  uuids: ISellerOrderFilesDeleteData,
  orderFileVariant: ISellerOrderFilesDeleteFileVariant,
  orderId: string,
) => {
  return axiosClient.delete(
    `${apiUrl}/orders/seller/${orderId}/${orderFileVariant}/delete/`,
    { data: uuids },
  );
};

const useDeleteSellerOrderFiles = (
  orderFileVariant: ISellerOrderFilesDeleteFileVariant,
  orderId: string,
) => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<ISellerOrderFilesDeleteData>,
    AxiosError<ISellerOrderFilesDeleteErrors>,
    ISellerOrderFilesDeleteData
  >({
    mutationFn: (uuids: ISellerOrderFilesDeleteData) =>
      deleteSellerOrderFiles(apiUrl, uuids, orderFileVariant, orderId),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: getQueryKey(orderId, orderFileVariant),
        })
        .then();
    },
  });
};

export default useDeleteSellerOrderFiles;
