import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { DefaultTheme } from '@mui/system';
import { theme as defaultTheme } from '../theme/theme';

export interface IThemeProviderProps<Theme = DefaultTheme> {
  children?: ReactNode;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
}

export function ThemeProvider({ children, theme }: IThemeProviderProps) {
  return (
    <MUIThemeProvider theme={theme ?? defaultTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
