import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IGetNotificationBannerResponse,
  IGetNotificationBannerResponseFailure,
  INotificationBannerParams,
} from './getNotificationBanner.types';
import { QueryKey as notificationBannerQueryKey } from './getNotificationBanner.const';

const submitGetNotificationBanner = (
  apiUrl: string,
  notificationBannerParams: INotificationBannerParams,
) => {
  const queryParams = new URLSearchParams({
    notification_source_uuid: notificationBannerParams.notificationSourceId,
    notification_banner_type: notificationBannerParams.notificationBannerType,
    notification_source_type: notificationBannerParams.notificationSourceType,
  });
  return axiosClient.get(`${apiUrl}/notifications?${queryParams}`);
};

const useGetNotificationBanner = (
  notificationBannerParams: INotificationBannerParams,
) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<IGetNotificationBannerResponse>,
    AxiosError<IGetNotificationBannerResponseFailure>,
    AxiosResponse<IGetNotificationBannerResponse>
  >({
    queryFn: () =>
      submitGetNotificationBanner(apiUrl, notificationBannerParams),
    queryKey: notificationBannerQueryKey,
  });
};

export default useGetNotificationBanner;
