import { mockedDocument } from '../getDocument/getDocument.fixture';
import { IBillOfLading } from '../upsertBillOfLading/upsertBillOfLading.types';
import { IContainer, IRequestOrder } from './getRequestOrderList.types';
import { format } from 'date-fns';

const containersWithData: IContainer[] = [
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb97564cd167',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCD1234567',
    sealNumber: 'Seal number',
    netWeight: 1000,
    tare: 1000,
    total: 2000,
  },
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb973cd17',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCDE43231',
    sealNumber: 'Seal number',
    netWeight: 100,
    tare: 0,
    total: 100,
  },
];

export const mockedBillOfLadingData: IBillOfLading = {
  consigneeName: 'Henry Minmark',
  consigneeAddress: 'Madrid, Spain',
  consigneeAdditionalInformation: 'No additional remarks for now',
  notifyName: 'Alex Muller',
  notifyAddress: 'Hamburg, Deutschland',
  materialDescription: 'Material has been packed in small bags',
  hsnCode: '110-009',
  dischargePort: 'A port in China',
  finalDestination: 'China',
  freeTime: 'Anytime between 2:00-3:00PM',
  additionalRemarks: "Material doesn't need a second inspection",
};

export const mockedRequestOrder: IRequestOrder = {
  uuid: 'ec9de6ca-75f9-4ab5-bafc-cb97564cd167',
  status: 'order.status.in_transit',
  publicId: 'DEF',
  orderNumberId: '123456',
  quantity: 50000.0,
  createdDate: '2024-01-18T14:59:07.603000+00:00',
  shippingTo: 'Las Palmas de G.C.',
  cost: 13000.0,
  billOfLading: mockedBillOfLadingData,
  annexViiUrl: 'url',
  billOfLadingUrl: 'url',
  certificateOfOriginUrl: 'url',
  finalInvoiceUrl: 'url',
  greenListContractUrl: 'url',
  nonRadioactiveCertificateUrl: 'url',
  packListUrl: 'url',
  documents: [mockedDocument],
  containers: containersWithData,
};
export const mockedOrderList: IRequestOrder[] = [mockedRequestOrder];

const mockedPartiallyEmptyBillOfLadingProps: IBillOfLading = {
  consigneeAddress: 'Madrid, Spain',
};

export const mockedOrderWithPartiallyEmptyBol: IRequestOrder = {
  uuid: 'asdasd',
  status: 'order.status.preparing',
  publicId: 'DEF',
  orderNumberId: '1234',
  quantity: 1000,
  createdDate: '01.Jul.2023',
  shippingTo: 'Las Palmas de G.C.',
  cost: 10000,
  billOfLading: mockedPartiallyEmptyBillOfLadingProps,
  documents: [],
};

export const mockedOrderWithBol: IRequestOrder = {
  uuid: 'asdasd',
  status: 'order.status.preparing',
  publicId: 'DEF',
  orderNumberId: '1234',
  quantity: 1000,
  createdDate: '01.Jul.2023',
  shippingTo: 'Las Palmas de G.C.',
  cost: 10000,
  billOfLading: mockedBillOfLadingData,
  documents: [],
};

export const mockedOrderWithoutBOL: IRequestOrder = {
  uuid: 'asdasd',
  status: 'order.status.preparing',
  publicId: 'DEF',
  orderNumberId: '1234',
  quantity: 1000,
  createdDate: '01.Jul.2023',
  shippingTo: 'Las Palmas de G.C.',
  cost: 10000,
  documents: [],
};
