import { RouteEvent } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Typography } from '@mui/material';
import {
  find,
  findIndex,
  findLast,
  groupBy,
  head,
  isEqual,
  sortBy,
} from 'lodash';
import {
  ContainerTrackingDataWrapper,
  ContainerTrackingDetailsWrapper,
  ContainerTrackingWrapper,
  EmptyContainerTrackingWrapper,
} from './ContainerTracking.css';
import { ContainerTrackingProps } from './ContainerTracking.types';
import { LocationRouteEvent } from './LocationEventTabs/LocationEventTabs.types';
import { RiCompass3Line } from '@remixicon/react';
import LocationEventTabs from './LocationEventTabs/LocationEventTabs';
import LocationProgressBar from './LocationProgressBar/LocationProgressBar';
import LocationMap from './LocationMap/LocationMap';

export function ContainerTracking({ tracking }: ContainerTrackingProps) {
  if (tracking === undefined)
    return (
      <ContainerTrackingWrapper data-testid="container-tracking">
        <ContainerTrackingDetailsWrapper>
          <Typography variant="h5">
            <Translate id="customer-platform.request-details.order-list.container-drawer.container-tracking.title" />
          </Typography>
          <EmptyContainerTrackingWrapper>
            <RiCompass3Line size={24} />
            <Typography variant="p3">
              <Translate id="customer-platform.request-details.order-list.container-drawer.container-tracking.empty" />
            </Typography>
          </EmptyContainerTrackingWrapper>
        </ContainerTrackingDetailsWrapper>
      </ContainerTrackingWrapper>
    );

  const {
    actualTimeOfArrival,
    actualTimeOfDeparture,
    currentLocation,
    route,
    routeEvents,
    vessels,
    startLocationId,
    endLocationId,
    locations,
    expectedTimeOfArrival,
    expectedTimeOfDeparture,
    shippingCompany,
  } = tracking;

  const departurePort = find(locations, { id: startLocationId });
  const arrivalPort = find(locations, { id: endLocationId });

  const routeProgress = () => {
    const currentPosition = head(currentLocation) || [];
    const index = findIndex(route, position =>
      isEqual(position, currentPosition),
    );
    return index === -1 ? 0 : (index / (route.length - 1)) * 100;
  };

  const routeEventsByLocation = () => {
    const locationRouteEvents: LocationRouteEvent[] = [];
    const sortedRouteEvents = sortBy(routeEvents, 'timestamp');
    const currentEvent = findLast(sortedRouteEvents, {
      alreadyOccurred: true,
    });
    const groupedEvents = groupBy(sortedRouteEvents, 'locationId');

    locations.forEach(location => {
      const label = `${location.name}, ${location.countryCode}`;
      const buildLocationRouteEvent = (
        isCurrent: boolean,
        events: RouteEvent[],
      ) => {
        return { label, isCurrent, events } as LocationRouteEvent;
      };

      if (currentEvent && currentEvent.locationId === location.id) {
        const { timestamp: currentEventTimestamp } = currentEvent;
        const pastEventsOnCurrentLocation = groupedEvents[location.id].filter(
          routeEvent => routeEvent.timestamp < currentEventTimestamp,
        );
        const futureEventsOnCurrentLocation = groupedEvents[location.id].filter(
          routeEvent => routeEvent.timestamp > currentEventTimestamp,
        );

        locationRouteEvents.push(
          buildLocationRouteEvent(false, pastEventsOnCurrentLocation),
        );
        locationRouteEvents.push(buildLocationRouteEvent(true, [currentEvent]));
        futureEventsOnCurrentLocation.length &&
          locationRouteEvents.push(
            buildLocationRouteEvent(false, futureEventsOnCurrentLocation),
          );
      } else {
        locationRouteEvents.push(
          buildLocationRouteEvent(false, groupedEvents[location.id] || []),
        );
      }
    });

    return locationRouteEvents.sort(
      (eventA, eventB) =>
        (eventA.events[0]?.timestamp ?? 0) - (eventB.events[0]?.timestamp ?? 0),
    );
  };

  const vesselsWithLocationName = () => {
    return vessels.map(vessel => ({
      ...vessel,
      loadingLocationName: find(locations, { id: vessel.loadingLocationId })
        ?.name,
      dischargeLocationName: find(locations, { id: vessel.dischargeLocationId })
        ?.name,
    }));
  };

  return (
    <ContainerTrackingWrapper data-testid="container-tracking">
      <ContainerTrackingDetailsWrapper>
        <Typography variant="h5">
          <Translate id="customer-platform.request-details.order-list.container-drawer.container-tracking.title" />
        </Typography>
        <ContainerTrackingDataWrapper>
          <LocationProgressBar
            actualTimeOfArrival={actualTimeOfArrival}
            actualTimeOfDeparture={actualTimeOfDeparture}
            arrivalPort={arrivalPort}
            departurePort={departurePort}
            expectedTimeOfArrival={expectedTimeOfArrival}
            expectedTimeOfDeparture={expectedTimeOfDeparture}
            shippingCompany={shippingCompany}
            value={routeProgress()}
          />
          <LocationEventTabs
            events={routeEventsByLocation()}
            vessels={vesselsWithLocationName()}
          />
        </ContainerTrackingDataWrapper>
      </ContainerTrackingDetailsWrapper>
      <LocationMap
        route={route}
        currentLocation={currentLocation}
        locations={locations}
      />
    </ContainerTrackingWrapper>
  );
}

export default ContainerTracking;
