import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ProgressBar as BaseProgressBar } from '@frontend/ui-elements';

export const EstimationWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px',
  width: '100%',
});

export const LocationProgressBarWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 0',
});

export const ProgressBar = styled(BaseProgressBar)<{ value: number }>(
  ({ value, theme }) => ({
    height: 2,
    borderRadius: 4,
    '&': {
      backgroundColor: theme.palette.primary[200],
    },
    '& > .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary[500],
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: `calc(${value}% - 24px)`,
      backgroundImage: `repeating-linear-gradient(
        90deg,
        transparent,
        transparent 5px,
        ${theme.palette.common.white} 5px,
        ${theme.palette.common.white} 10px
      )`,
    },
  }),
);

export const LocationDetailsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
});

export const SpaceBetweenWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});
