import { styled } from '@frontend/theme';
import { Step as MUIStep, StepLabel as MUIStepLabel } from '@mui/material';

export const StepSt = styled(MUIStep)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,

  // Completed StepIcon Color
  '&.Mui-completed > .MuiStepLabel-root > .MuiStepLabel-iconContainer > svg': {
    circle: {
      fill: theme.palette.primary[500],
      stroke: theme.palette.primary[500],
    },
    '.MuiStepIcon-text': {
      fill: theme.palette.common.white,
    },
  },

  '&:not(:first-of-type):not(:last-of-type) > .MuiStepLabel-root': {
    alignItems: 'center',
    width: 'auto',
    transform: 'translateX(-50%)',
  },

  '&:first-of-type > .MuiStepLabel-root': {
    alignItems: 'flex-start',
    width: 'auto',
  },

  '&:last-of-type > .MuiStepLabel-root': {
    alignItems: 'flex-end',
    width: 'max-content',
    transform: 'translateX(-100%)',
  },

  // Step Label Alignments
  '& > .MuiStepLabel-root > .MuiStepLabel-labelContainer': {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.neutral[100]}`,
  },
  '&:last-of-type > .MuiStepLabel-root > .MuiStepLabel-labelContainer': {
    borderBottom: 0,
  },
  [theme.breakpoints.up('tablet')]: {
    '& > .MuiStepLabel-root > .MuiStepLabel-labelContainer': {
      borderBottom: 0,
    },
    '&:first-of-type > .MuiStepLabel-root > .MuiStepLabel-labelContainer': {
      width: 'auto',
      textAlign: 'left',
    },
    '&:last-of-type > .MuiStepLabel-root > .MuiStepLabel-labelContainer': {
      width: 'auto',
      textAlign: 'right',
    },
  },

  [theme.breakpoints.down('tablet')]: {
    gap: '6px',
    '&:first-of-type > .MuiStepLabel-root': {
      alignItems: 'center',
      width: 'auto',
    },

    '&:last-of-type > .MuiStepLabel-root': {
      transform: 'initial',
      alignItems: 'center',
      width: 'auto',
    },

    '&:not(:first-of-type):not(:last-of-type) > .MuiStepLabel-root': {
      transform: 'initial',
      width: 'auto',
    },
  },
}));

export const StepLabelSt = styled(MUIStepLabel, {
  shouldForwardProp: prop => prop !== 'optional',
})(({ theme, optional }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
  padding: 0,
  position: 'absolute',
  top: 'calc(50% - 12px)',
  width: '0px',

  // Icon
  '& > .MuiStepLabel-iconContainer': {
    paddingRight: '0',
    '& > svg': {
      height: '24px',
      width: '24px',
      circle: {
        r: '11px',
        fill: theme.palette.common.white,
        stroke: theme.palette.primary[500],
        strokeWidth: '1.714px',
      },
      '.MuiStepIcon-text': {
        fill: theme.palette.primary[500],
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 'normal',
      },
    },
  },

  '&.Mui-disabled > .MuiStepLabel-iconContainer > svg': {
    circle: {
      fill: theme.palette.neutral[50],
      stroke: theme.palette.neutral[50],
    },
    '.MuiStepIcon-text': {
      fill: theme.palette.neutral[500],
    },
  },

  // Label
  '& > .MuiStepLabel-labelContainer > .MuiStepLabel-label': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 0',
    gap: '4px',
    margin: 0,
    color: theme.palette.neutral[500],
  },
  [theme.breakpoints.down('tablet')]: {
    position: 'relative',
    flexDirection: 'row',
    padding: '0 0 0 20px',
    gap: '12px',
    '& > .MuiStepLabel-labelContainer': {
      padding: optional ? '8px 0' : '16px 0',
      '& > .MuiStepLabel-label': {
        textAlign: 'left',
      },
    },
  },
}));
