import { RefObject, useEffect, useRef } from 'react';

export function useCardScroll({ enableScroll }: { enableScroll: boolean }) {
  const cardRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (enableScroll && cardRef.current) {
      setTimeout(() => {
        if (cardRef.current) {
          cardRef.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 100);
    }
  }, [enableScroll]);

  return {
    cardRef,
  };
}
