import { Accordion as MUIAccordion } from '@mui/material';
import { styled } from '@frontend/theme';

export const MUIAccordionSt = styled(MUIAccordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.neutral[500],
    width: '100%',

    '& > .MuiCollapse-root.MuiCollapse-hidden': {
      display: 'none',
    },
  },
}));
