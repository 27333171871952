import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IBuyerRequestErrors,
  IBuyerRegistrationRequest,
} from './createBuyerAndRequest.types';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

const submitCreateBuyerAndRequest = (
  registrationRequest: IBuyerRegistrationRequest,
  apiUrl: string,
) => {
  return axiosClient.post(
    `${apiUrl}/accounts/user/buyer-request-registration/`,
    registrationRequest,
  );
};

const useCreateBuyerAndRequest = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse<IBuyerRegistrationRequest>,
    AxiosError<IBuyerRequestErrors>,
    IBuyerRegistrationRequest
  >({
    mutationFn: (registrationRequest: IBuyerRegistrationRequest) =>
      submitCreateBuyerAndRequest(registrationRequest, apiUrl),
  });
};

export default useCreateBuyerAndRequest;
