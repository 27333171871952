import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ActionListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  padding: '0px 8px 8px 8px',
}));
