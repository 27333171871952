import { styled } from '@frontend/theme';
import { SideWrapper as BaseSideWrapper } from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

// Layout system
export const BackgroundWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'auto',
  minHeight: '100vh',
  background: `linear-gradient( to right, #FBFBFB 0%, #FBFBFB 50%, ${theme.palette.secondary[50]} 50%, ${theme.palette.secondary[50]} 100%)`,
  [theme.breakpoints.down('tabletL')]: {
    background: 'inherit',
  },
}));

export const LayoutWrapper = styled(BaseSideWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  maxWidth: theme.breakpoints.values.desktopL,
  width: '100%',
  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
    paddingBottom: '40px',
  },
}));

export const LeftWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  paddingTop: '70px',
  paddingBottom: '40px',
  width: '100%',
  height: 'auto',
  [theme.breakpoints.down('tabletL')]: {
    paddingTop: '40px',
    paddingBottom: 0,
  },
}));

export const FakeSeparator = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#FBFBFB',
  width: '135px',
  height: 'auto',
  [theme.breakpoints.down('tabletL')]: {
    width: '100%',
    height: '24px',
  },
  [theme.breakpoints.down('tablet')]: {
    height: '36px',
  },
}));

export const RightWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
  backgroundColor: theme.palette.secondary[50],
  padding: '240px 58px',
  maxWidth: '600px',
  width: '100%',
  height: 'auto',
  [theme.breakpoints.down('tabletL')]: {
    maxWidth: '100%',
    padding: '120px 58px',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
    padding: '100px 20px',
  },
}));

// Left column layout
export const HeadingWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
});

export const SubheadingBox = styled(MUIBox)(() => ({
  display: 'inline-flex',
  gap: '8px',
  alignItems: 'center',
}));

// Right column layout
export const ImageWrapper = styled(MUIBox)({
  display: 'flex',
  maxWidth: '480px',
  width: '100%',
  '& > img': {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
});
