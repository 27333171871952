import ProgressBarSt from './ProgressBar.css';
import ProgressBarProps from './ProgressBar.types';

export function ProgressBar(props: ProgressBarProps) {
  const { value, ...rest } = props;

  return <ProgressBarSt value={value} variant="determinate" {...rest} />;
}

export default ProgressBar;
