import { generatePath, Navigate, useLocation } from 'react-router-dom';
import { useGetRequestList } from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageContent } from './BuyerDashboard.css';
import DashboardHeader from '@customer-platform-shared/components/DashboardHeader/DashboardHeader';
import EmptyDashboard from '@customer-platform-shared/components/EmptyDashboard/EmptyDashboard';
import PlatformHeader from '@customer-platform-shared/components/PlatformHeader/PlatformHeader';
import RequestList from './RequestDetails/RequestList/RequestList';
import { HEADER_VARIANTS } from '@customer-platform-shared/components/PlatformHeader/PlatformHeader.types';

export default function BuyerDashboard() {
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const {
    isSuccess: isRequestListSuccess,
    isPending: isRequestListPending,
    data: requestListResponse,
  } = useGetRequestList();

  if (isRequestListSuccess) {
    if (!requestListResponse?.data.length) {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.BUYER} />
          <PageWrapper>
            <EmptyDashboard />
          </PageWrapper>
        </>
      );
    }
    if (isDesktop) {
      const firstRequest = requestListResponse?.data[0];
      const firstRequestDetailsPage = generatePath(
        PLATFORM_ROUTES.requestDetails,
        { uuid: firstRequest?.uuid },
      );
      return (
        <Navigate
          to={firstRequestDetailsPage}
          state={{ ...location.state, disableScrollToCard: true }}
          replace
        />
      );
    } else {
      return (
        <>
          <PlatformHeader variant={HEADER_VARIANTS.BUYER} />
          <PageWrapper>
            <PageContent>
              <DashboardHeader cardsCount={requestListResponse.data.length} />
              <RequestList requests={requestListResponse.data} />
            </PageContent>
          </PageWrapper>
        </>
      );
    }
  }

  if (isRequestListPending) {
    return <FullPageLoader />;
  }
}
