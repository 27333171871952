import { Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { LocationMapWrapper, Map } from './LocationMap.css';
import { DivIcon, LatLngTuple } from 'leaflet';
import { useTheme } from '@mui/material';
import { LocationMapProps } from './LocationMap.types';
import ReactDOMServer from 'react-dom/server';
import LocationMarker from '../LocationMarker/LocationMarker';
import { Coordinates, Location } from '@frontend/api';
import { RouteProcessor } from './LocationMap.utils';
import { RiShipFill } from '@remixicon/react';
import 'leaflet/dist/leaflet.css';
import { head } from 'lodash';

export default function LocationMap({
  route,
  currentLocation,
  locations,
}: LocationMapProps) {
  const theme = useTheme();
  const filledLineStyles = { color: theme.palette.primary[500], weight: 2 };
  const dottedLineStyles = {
    color: theme.palette.primary[300],
    dashArray: '5, 10',
    dashOffset: '0',
    weight: 2,
  };

  const bounds = [
    [69.778952, -72.015618],
    [-52.660611, 24.17544],
  ] as LatLngTuple[];

  const PassedMarkerIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<LocationMarker active />),
  });

  const PendingMarkerIcon = new DivIcon({
    html: ReactDOMServer.renderToString(<LocationMarker active={false} />),
  });

  const CurrentMarkerIcon = new DivIcon({
    html: ReactDOMServer.renderToString(
      <LocationMarker active>
        <RiShipFill size={12} color={theme.palette.primary[500]} />
      </LocationMarker>,
    ),
  });

  const routeProcessor = new RouteProcessor(route, head(currentLocation) || []);
  const processCentralPoint = routeProcessor.getProcessedCentralPoint();

  const [polylinePassed, polylineToCome] = routeProcessor.splitProcessedRoute();

  const getCoordinatesFromLocation = (location: Location) => {
    return routeProcessor.getProcessedCoordinate([
      location.lat,
      location.lng,
    ] as Coordinates);
  };

  return (
    <LocationMapWrapper>
      <Map
        center={processCentralPoint as unknown as LatLngTuple}
        zoom={3}
        attributionControl={false}
        scrollWheelZoom={true}
        bounds={bounds}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        {locations
          .filter(location => !!getCoordinatesFromLocation(location))
          .map(location => {
            const locationCoordinates = getCoordinatesFromLocation(location);
            const isLocationPassed = polylinePassed.some(passedCoordinates =>
              routeProcessor.sameCoordinates(
                passedCoordinates,
                locationCoordinates,
              ),
            );
            return (
              <Marker
                position={locationCoordinates as LatLngTuple}
                key={location.id}
                icon={isLocationPassed ? PassedMarkerIcon : PendingMarkerIcon}
              >
                <Popup>
                  {location.name}, {location.countryCode}
                </Popup>
              </Marker>
            );
          })}
        <Marker
          position={
            routeProcessor.getProcessedCoordinate(
              head(currentLocation) || [],
            ) as LatLngTuple
          }
          icon={CurrentMarkerIcon}
        />
        <Polyline
          pathOptions={filledLineStyles}
          positions={polylinePassed as LatLngTuple[]}
        />
        <Polyline
          pathOptions={dottedLineStyles}
          positions={polylineToCome as LatLngTuple[]}
        />
      </Map>
    </LocationMapWrapper>
  );
}
