import { IntlShape } from 'react-intl';

export interface ISelectDataOption {
  label: string;
  value: string;
}

export function buildSelectDataByOptionsAndNamespace(
  options: Readonly<string[]>,
  provider: IntlShape,
  namespace?: string,
) {
  return {
    options: options.map(option => {
      const id = namespace
        ? `customer-platform.api.${namespace}.${option}`
        : `customer-platform.api.${option}`;
      return {
        label: provider.formatMessage({
          id,
        }),
        value: option,
      } as ISelectDataOption;
    }),
  };
}
