import { styled } from '@frontend/theme';
import { Modal as MUIModal } from '@mui/material';

export const ModalSt = styled(MUIModal)(({ theme }) => ({
  // Requires that the first child of Modal is Box
  '& > .MuiBox-root': {
    position: 'absolute',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
