const colors = {
  primary: {
    50: '#E5EBEB',
    100: '#BECCCD',
    200: '#92ABAC',
    300: '#66898B',
    400: '#466F72',
    500: '#255659',
    600: '#214F51',
    700: '#1B4548',
    800: '#163C3E',
    900: '#0D2B2E',
    A100: '#6BF4FF',
    A200: '#38F0FF',
    A400: '#05EcFF',
    A700: '#00D9EB',
  },
  secondary: {
    50: '#EEFEFB',
    100: '#D4FBF5',
    200: '#B8F9EE',
    300: '#9BF7E7',
    400: '#85F5E1',
    500: '#70F3DC',
    600: '#68F1D8',
    700: '#5DEFD3',
    800: '#53EDCE',
    900: '#41EAC5',
    A100: '#FFFFFF',
    A200: '#FFFFFF',
    A400: '#D4FFF5',
    A700: '#BBFFEF',
  },
  tertiary: {
    50: '#FCFEFF',
    100: '#F6FEFF',
    200: '#F1FDFF',
    300: '#EBFCFF',
    400: '#E6FBFF',
    500: '#E2FAFF',
    600: '#DFF9FF',
    700: '#DAF9FF',
    800: '#D6F8FF',
    900: '#CFF6FF',
    A100: '#FFFFFF',
    A200: '#FFFFFF',
    A400: '#FFFFFF',
    A700: '#FFFFFF',
  },
  error: {
    50: '#FAE4E4',
    100: '#F2BBBB',
    200: '#EA8E8E',
    300: '#E16161',
    400: '#DA3F3F',
    500: '#D41D1D',
    600: '#CF1A1A',
    700: '#C91515',
    800: '#C31111',
    900: '#B90A0A',
    A100: '#FFE5E5',
    A200: '#FFB2B2',
    A400: '#FF7F7F',
    A700: '#FF6565',
  },
  warning: {
    50: '#FEF6E7',
    100: '#FDE9C4',
    200: '#FCDA9D',
    300: '#FBCB75',
    400: '#FABF58',
    500: '#F9B43A',
    600: '#F8AD34',
    700: '#F7A42C',
    800: '#F69C25',
    900: '#F58C18',
    A100: '#FFFFFF',
    A200: '#FFF9F3',
    A400: '#FFDFC0',
    A700: '#FFD2A7',
  },
  neutral: {
    50: '#E7E7E7',
    100: '#C4C4C4',
    200: '#9C9C9C',
    300: '#747474',
    400: '#575757',
    500: '#393939',
    600: '#333333',
    700: '#2C2C2C',
    800: '#242424',
    900: '#171717',
    A100: '#EF7171',
    A200: '#EA4343',
    A400: '#FA0000',
    A700: '#E00000',
  },
};

export default colors;
