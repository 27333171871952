import { SyntheticEvent, useEffect } from 'react';
import { ControllerRenderProps, useController, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import {
  INCOTERMS,
  IRequestSubmissionData,
  IRequestSubmissionDataKeys,
  ISRI_GRADE,
  METAL_FORM,
  METAL_TYPE,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Autocomplete, Button, Select, TextField } from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Typography } from '@mui/material';
import {
  CaptionBox,
  FieldsWrapper,
  FormBox,
  FormContainer,
} from './RequestForm.css';
import RequestFormProps from './RequestForm.types';
import {
  MAX_NUMBER_AFTER_DECIMAL,
  MIN_QUANTITY_REQUIRED_MT,
} from '../Forms.const';
import { maxNumbersAfterDecimalValidation } from './RequestForm.utils';

import FormType from '../utils/FormType.types';

import {
  buildSelectDataByOptionsAndNamespace,
  ISelectDataOption,
} from '../utils/utils';
import { useFormTracking } from '../utils/useFormTracking';

export default function RequestForm({
  errors,
  isSuccess,
  isPending,
  onSubmit,
  submitTranslationId,
}: RequestFormProps) {
  const intl = useIntl();
  const requiredField = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const formId = 'request-form.form';

  const metalTypeData = buildSelectDataByOptionsAndNamespace(METAL_TYPE, intl);
  const isriGradeData = buildSelectDataByOptionsAndNamespace(ISRI_GRADE, intl);
  const metalFormData = buildSelectDataByOptionsAndNamespace(METAL_FORM, intl);
  const incotermsData = buildSelectDataByOptionsAndNamespace(INCOTERMS, intl);

  const maxNumbersAfterDecimal = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.number.max_numbers_after_decimal',
    },
    { MAX_NUMBER_AFTER_DECIMAL: MAX_NUMBER_AFTER_DECIMAL.toString() },
  );

  const minQuantityRequired = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.quantify.min-quantity-required',
    },
    { MIN_QUANTITY_REQUIRED_MT: MIN_QUANTITY_REQUIRED_MT.toString() },
  );

  const requestFormSchema = yup.object().shape({
    metalType: yup
      .string()
      .oneOf(METAL_TYPE, requiredField)
      .required(requiredField),
    isriGrade: yup
      .string()
      .oneOf(['', ...ISRI_GRADE])
      .required(requiredField),
    metalForm: yup
      .string()
      .oneOf(['', ...METAL_FORM])
      .optional(),
    quantity: yup
      .number()
      .required(requiredField)
      .min(MIN_QUANTITY_REQUIRED_MT, minQuantityRequired)
      .test(
        'maxNumbersAfterDecimal',
        maxNumbersAfterDecimal,
        maxNumbersAfterDecimalValidation,
      )
      .transform((val, orig) => (orig === '' ? undefined : val)),
    shippingPort: yup.string().optional(),
    targetPrice: yup
      .number()
      .positive()
      .test(
        'maxNumbersAfterDecimal',
        maxNumbersAfterDecimal,
        maxNumbersAfterDecimalValidation,
      )
      .optional()
      .transform((val, orig) => (orig === '' ? undefined : val)),
    incoterms: yup
      .string()
      .oneOf(['', ...INCOTERMS])
      .optional(),
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors: formErrors },
  } = useForm<FormType<IRequestSubmissionData>>({
    defaultValues: {
      metalType: '',
      isriGrade: '',
      metalForm: '',
      quantity: '',
      shippingPort: '',
      targetPrice: '',
      incoterms: '',
    },
    resolver: yupResolver<FormType<IRequestSubmissionData>>(requestFormSchema),
  });

  const { field: metalTypeField } = useController({
    name: 'metalType',
    control,
  });
  const { field: isriGradeField } = useController({
    name: 'isriGrade',
    control,
  });
  const { field: metalFormField } = useController({
    name: 'metalForm',
    control,
  });
  const { field: incotermsField } = useController({
    name: 'incoterms',
    control,
  });

  const handleChange =
    (field: ControllerRenderProps) => (event: SelectChangeEvent<unknown>) => {
      field.onChange(event.target.value);
    };

  const handleIsriGradeChange = (
    _: SyntheticEvent<Element, Event>,
    option: ISelectDataOption,
  ) => {
    isriGradeField.onChange(option?.value);
  };

  useEffect(() => {
    if (errors !== undefined) {
      Object.keys(errors).map(field =>
        setError(field as IRequestSubmissionDataKeys, {
          type: 'manual',
          message: errors[field as IRequestSubmissionDataKeys][0],
        }),
      );
    }
  }, [errors, setError]);

  useFormTracking({ formId, formErrors, isSuccess });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [reset, isSuccess]);

  return (
    <FormContainer>
      <CaptionBox>
        <Typography variant="p1">
          <Translate id="customer-platform.shared.components.forms.request-form.paragraph" />
        </Typography>
        <Typography component="p" variant="caption2">
          <Translate id="customer-platform.shared.components.forms.request-form.caption" />
        </Typography>
      </CaptionBox>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        id={formId}
        name={formId}
      >
        <FormBox>
          <FieldsWrapper>
            <Select
              id="metal-type"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.select.metal.type" />
              }
              options={metalTypeData.options}
              required={true}
              value={metalTypeField.value}
              onChange={handleChange(metalTypeField as ControllerRenderProps)}
              error={!!formErrors?.metalType}
              helperText={formErrors?.metalType?.message}
              formId={formId}
            />
            <Autocomplete
              id="isri-grade"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.select.isri_grade" />
              }
              options={isriGradeData.options}
              required={true}
              onChange={handleIsriGradeChange}
              value={isriGradeData.options.find(
                option => option.value === isriGradeField?.value,
              )}
              error={!!formErrors?.isriGrade}
              helperText={formErrors?.isriGrade?.message}
              autoHighlight
              formId={formId}
            />
            <Select
              id="metal-form"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.select.metal.form" />
              }
              options={metalFormData.options}
              value={metalFormField.value}
              onChange={handleChange(metalFormField as ControllerRenderProps)}
              error={!!formErrors?.metalForm}
              helperText={formErrors?.metalForm?.message}
              formId={formId}
            />
            <TextField
              id="quantity"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.textfield.quantity" />
              }
              required={true}
              type="number"
              {...register('quantity')}
              error={!!formErrors?.quantity}
              helperText={formErrors?.quantity?.message}
              formId={formId}
            />
            <TextField
              id="shipping-port"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.textfield.shipping_port" />
              }
              {...register('shippingPort')}
              error={!!formErrors?.shippingPort}
              helperText={formErrors?.shippingPort?.message}
              formId={formId}
            />
            <TextField
              id="target-price"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.textfield.target_price" />
              }
              type="number"
              {...register('targetPrice')}
              error={!!formErrors?.targetPrice}
              helperText={formErrors?.targetPrice?.message}
              formId={formId}
            />
            <Select
              id="incoterms"
              label={
                <Translate id="customer-platform.shared.components.forms.request-form.select.incoterms" />
              }
              options={incotermsData.options}
              value={incotermsField.value}
              onChange={handleChange(incotermsField as ControllerRenderProps)}
              error={!!formErrors?.incoterms}
              helperText={formErrors?.incoterms?.message}
              formId={formId}
            />
          </FieldsWrapper>
          <Button
            buttonType="primary"
            id="request-form.button.submit"
            size="large"
            type="submit"
            isSubmitting={isPending}
          >
            <Translate id={submitTranslationId} />
          </Button>
        </FormBox>
      </form>
    </FormContainer>
  );
}
