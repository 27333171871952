import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  ISellerListingErrors,
  ISellerRegistrationListing,
} from './createSellerAndListing.types';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

const submitCreateSellerAndListing = (
  registrationListing: ISellerRegistrationListing,
  apiUrl: string,
) => {
  const form = new FormData();

  form.append('user', JSON.stringify(registrationListing.user));
  form.append('listing', JSON.stringify(registrationListing.listing));

  registrationListing.materialPhotos?.forEach(value => {
    form.append('materialPhotos', value, value.name);
  });

  return axiosClient.post(
    `${apiUrl}/accounts/user/seller-listing-registration/`,
    form,
  );
};

const useCreateSellerAndListing = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse<ISellerRegistrationListing>,
    AxiosError<ISellerListingErrors>,
    ISellerRegistrationListing
  >({
    mutationFn: (registrationListing: ISellerRegistrationListing) =>
      submitCreateSellerAndListing(registrationListing, apiUrl),
  });
};

export default useCreateSellerAndListing;
