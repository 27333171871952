import { IStatusPill } from './StatusPill.types';
import { MainWrapper } from './StatusPill.css';
import { statusIconMapping } from './StatusPill.const';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';

export default function StatusPill({ status }: IStatusPill) {
  const Icon = statusIconMapping[status] || undefined;

  return (
    <MainWrapper status={status}>
      {Icon && <Icon size={14} />}
      <Typography variant="p4">
        <Translate id={`customer-platform.api.${status}`} />
      </Typography>
    </MainWrapper>
  );
}
