import {
  AccordionSummary as MUIAccordionSummary,
  Box as MUIBox,
} from '@mui/material';
import { styled } from '@frontend/theme';

export const MUIAccordionSummarySt = styled(MUIAccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    gap: '8px',
    padding: '4px 0',
    width: '100%',
    minHeight: 'auto',

    '&.Mui-expanded': {
      padding: 0,
      minHeight: 'auto',
    },

    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));

export const AccordionSummaryFullWidthRowSt = styled(MUIBox)(() => ({
  width: 'calc(100% + 24px)',
}));
