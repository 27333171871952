import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IRequestEmailVerificationLinkResponseFailure } from './requestEmailVerificationLink.types';

const submitRequestEmailVerificationLink = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/accounts/user/email-verification-request/`);
};

const useRequestEmailVerificationLink = () => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse,
    AxiosError<IRequestEmailVerificationLinkResponseFailure>
  >({
    queryFn: () => submitRequestEmailVerificationLink(apiUrl),
    queryKey: ['email-verification'],
    enabled: false,
  });
};

export default useRequestEmailVerificationLink;
