import { styled } from '@frontend/theme';
import {
  Stepper as MUIStepper,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material';

export const RouteEventConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 0,
    paddingLeft: '11.2px',
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[100],
      borderRadius: 0,
      borderLeftStyle: 'dashed',
      borderLeftWidth: '2px',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[500],
      borderLeftStyle: 'solid',
    },
  },
}));

export const RouteEventListStepper = styled(MUIStepper)({
  width: '100%',
});
