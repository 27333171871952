import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { trackClickEvent } from '@frontend/tracking';
import {
  MenuItem as MUIMenuItem,
  MenuItemProps as MUIMenuItemProps,
  Typography,
} from '@mui/material';

type MenuItemProps = MUIMenuItemProps & {
  icon?: ReactElement;
};

export default function MenuItem({
  children,
  icon,
  id = 'menu-menuitem',
  onClick,
  ...rest
}: MenuItemProps) {
  const intl = useIntl();

  const getStringFromChildren = (children: ReactElement | string) => {
    if (typeof children === 'string') {
      return children;
    }
    return intl.formatMessage({
      id: get(children, 'props.id', 'unknown'),
    });
  };

  const handleTrackingClick = (event: never) => {
    trackClickEvent({
      elementId: id,
      elementText: getStringFromChildren(children as ReactElement),
    });

    if (onClick !== undefined) {
      onClick(event);
    }
  };
  return (
    <MUIMenuItem id={id} onClick={handleTrackingClick} {...rest}>
      {icon}
      <Typography variant="p3">{children}</Typography>
    </MUIMenuItem>
  );
}
