import { forwardRef, Ref, useRef } from 'react';

import { MUITextFieldSt } from './TextField.css';
import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';
import TextFieldProps, { FormFieldEvent } from './TextField.types';

const TextField = forwardRef(function (
  props: TextFieldProps,
  ref?: Ref<HTMLDivElement>,
) {
  const { formId, ...restProps } = props;

  const lastTracked = useRef<FormFieldEvent>({
    id: null,
    action: null,
  });
  const initialValue = useRef<string | null>(props.value as unknown as string);
  const inputRef = useRef<HTMLInputElement>(null);

  const trackFieldEvent = (
    action: FormFieldAction,
    oldValue?: string | null,
    newValue?: string | null,
  ) => {
    const currentId = props.id ?? 'unknown-field';
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: currentId,
      formFieldAction: action,
      formFieldOldValue: oldValue,
      formFieldNewValue: newValue,
    });
    lastTracked.current.id = currentId;
    lastTracked.current.action = action;
  };

  const handleFocus = () => {
    if (inputRef.current) {
      const currentId = props.id ?? 'unknown-field';
      if (
        lastTracked.current.id !== currentId ||
        lastTracked.current.action !== 'focus'
      ) {
        initialValue.current = inputRef.current.value;
        trackFieldEvent('focus');
      }
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      if (initialValue.current !== inputRef.current.value) {
        trackFieldEvent('update', initialValue.current, inputRef.current.value);
      }
      trackFieldEvent('unfocus');
    }
  };

  return (
    <MUITextFieldSt
      variant="outlined"
      {...restProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputRef={inputRef}
      ref={ref}
    />
  );
});

export default TextField;
