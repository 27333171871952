import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const BiddableListingDetailsDataItemLabelWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
});

export const BiddableListingDetailsDataItemListWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const BiddableListingDetailsDataItemWrapper = styled(MUIBox)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '12px 0px',
    width: '100%',
    borderTop: `1px solid ${theme.palette.neutral[50]}`,
  }),
);

export const BiddableListingDetailsHeadingWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  width: '100%',
});

export const BiddableListingDetailsWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
});
