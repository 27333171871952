import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import { trackClickEvent } from '@frontend/tracking';

export default function IconButton({
  children,
  id = 'icon',
  onClick,
  ...rest
}: IconButtonProps) {
  const handleTrackingClick = (event: never) => {
    trackClickEvent({
      elementId: id,
    });

    if (onClick !== undefined) {
      onClick(event);
    }
  };

  return (
    <MuiIconButton id={id} onClick={handleTrackingClick} {...rest}>
      {children}
    </MuiIconButton>
  );
}
