import { styled } from '@frontend/theme';
import { ToastAlert as BaseToastAlert } from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const DocumentCardWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '4px',
  padding: '20px 16px',
  border: `1px solid ${theme.palette.primary[100]}`,
  backgroundColor: theme.palette.common.white,
  '&:hover, &:active': {
    border: `1px solid ${theme.palette.primary[200]}`,
  },
  '&:active': {
    backgroundColor: theme.palette.primary[50],
  },
}));

export const DocumentListWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  gap: '20px',
  width: '100%',
  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  [theme.breakpoints.down('tabletL')]: {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  '& span': {
    wordBreak: 'break-all',
  },
}));

export const ImageListWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px 12px',
  width: '100%',
});

export const MainWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  width: '100%',
});

export const ToastAlert = styled(BaseToastAlert)({
  width: '100%',
  '& > .MuiAlert-message': {
    padding: 0,
  },
});
