import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';
import { Typography, useTheme } from '@mui/material';
import { ChangeEvent, forwardRef, Ref, useState } from 'react';
import {
  HelperTextWrapper,
  MUITextAreaSt,
  TextAreaWrapper,
} from './TextArea.css';
import TextAreaProps from './TextArea.types';

const TextArea = forwardRef(function (
  props: TextAreaProps,
  ref?: Ref<HTMLTextAreaElement>,
) {
  const { formId, helperText, placeholder, required, ...restProps } = props;
  const theme = useTheme();
  const [initialValue, setInitialValue] = useState<string>('');

  const trackFieldEvent = (
    action: FormFieldAction,
    oldValue?: string | null,
    newValue?: string | null,
  ) => {
    const currentId = props.id ?? 'unknown-field';
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: currentId,
      formFieldAction: action,
      formFieldOldValue: oldValue,
      formFieldNewValue: newValue,
    });
  };

  const handleFocus = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (initialValue !== event.target.value) {
      setInitialValue(event.target.value);
    }
    trackFieldEvent('focus');
  };

  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (initialValue !== event.target.value) {
      trackFieldEvent('update', initialValue, event.target.value);
      setInitialValue(event.target.value);
    }
    trackFieldEvent('unfocus');
  };

  return (
    <TextAreaWrapper>
      <MUITextAreaSt
        {...restProps}
        placeholder={required ? `${placeholder} *` : placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={ref}
        required
      />
      {helperText && (
        <HelperTextWrapper>
          <Typography variant="p3" color={theme.palette.error[500]}>
            {helperText}
          </Typography>
        </HelperTextWrapper>
      )}
    </TextAreaWrapper>
  );
});

export default TextArea;
