import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { IDocumentContext } from './getDocument.types';
import fileSaver from 'file-saver';
import { getHumanReadableName } from './getDocument.util';

const useGetDocument = () => {
  const { apiUrl } = useContext(ApiContext);
  const downloadDocument = async (documentContext: IDocumentContext) => {
    const { parentRecordId, parentRecordType, document } = documentContext;
    const { uniqueIdentifier } = document;

    let url = '';
    switch (parentRecordType) {
      case 'request':
        url = `${apiUrl}/trading/requests/${parentRecordId}/documents/${uniqueIdentifier}/download/`;
        break;
      case 'order':
        url = `${apiUrl}/orders/${parentRecordId}/buyer-documents/${uniqueIdentifier}/download/`;
        break;
      case 'container':
        url = `${apiUrl}/orders/containers/${parentRecordId}/documents/${uniqueIdentifier}/download/`;
        break;
      default:
        break;
    }

    return axiosClient.get(url, { responseType: 'blob' }).then(response => {
      fileSaver.saveAs(response.data, getHumanReadableName(document));
    });
  };

  return { downloadDocument };
};
export default useGetDocument;
