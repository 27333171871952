import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ContainerDocumentsListWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

export const ContainerDocumentsWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  paddingBottom: '24px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,
}));

export const DocumentContainer = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '4px',
  padding: '20px 16px',
  border: `1px solid ${theme.palette.primary[100]}`,
  backgroundColor: theme.palette.common.white,
  '&:hover, &:active': {
    border: `1px solid ${theme.palette.primary[200]}`,
  },
  '&:active': {
    backgroundColor: theme.palette.primary[50],
  },
}));
