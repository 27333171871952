import { findLastIndex } from 'lodash';
import {
  RouteEventConnector,
  RouteEventListStepper,
} from './RouteEventList.css';
import { RouteEventListProps } from './RouteEventList.types';
import RouteEvent from './RouteEvent/RouteEvent';

export function RouteEventList({ events, ...rest }: RouteEventListProps) {
  const currentEventIndex = findLastIndex(events, { isCurrent: true });

  return (
    <RouteEventListStepper
      {...rest}
      connector={<RouteEventConnector />}
      orientation="vertical"
    >
      {events.map((localizationEvents, index) => (
        <RouteEvent
          key={index}
          localizationEvents={localizationEvents}
          completed={index <= currentEventIndex}
        />
      ))}
    </RouteEventListStepper>
  );
}

export default RouteEventList;
