import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import { MainWrapper } from './EmptyDocuments.css';
import { RiTodoLine } from '@remixicon/react';

export interface EmptyDocumentsProps {
  fullWidth?: boolean;
}
export default function EmptyDocuments({ fullWidth }: EmptyDocumentsProps) {
  return (
    <MainWrapper style={{ width: fullWidth ? '100%' : 'auto' }}>
      <RiTodoLine size={24} />
      <Typography variant="p3">
        <Translate id="customer-platform.request-details.order-card.documents.empty" />
      </Typography>
    </MainWrapper>
  );
}
