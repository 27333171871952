import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const OrderStatusBarWrapper = styled(MUIBox)(({ theme }) => ({
  padding: '36px 0 16px 0',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
    marginBottom: 0,
  },
}));
