import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: '24px',
  },
}));

export const Wrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '24px',
}));

export const ButtonWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',

  [theme.breakpoints.down('desktop')]: {
    '& > a': {
      width: '100%',
    },
  },
}));

export const ImageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  maxWidth: '310px',
  width: '100%',
  '& > img': {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },

  [theme.breakpoints.down('desktop')]: {
    maxWidth: '240px',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '170px',
  },
}));

export const TitleSection = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  [theme.breakpoints.down('tablet')]: {
    gap: '12px',
  },
}));
