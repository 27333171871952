import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  padding: '24px 0',
}));

export const ListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '20px',
  width: '100%',
}));

export const MissingInfoWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'flex-start',
  padding: '8px 16px',
  width: '100%',
  backgroundColor: theme.palette.tertiary[500],
  '& > svg': {
    minWidth: '24px',
  },
}));
