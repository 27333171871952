import colors from './colors';
import { Theme } from '@mui/material';
import { Typography } from '@mui/material/styles/createTypography';

export const fontWeights = {
  semiBold: 600,
  medium: 500,
  regular: 400,
};

export const breakpoints = {
  mobile: 360, // xs
  tablet: 768, // sm
  tabletL: 1024, // md
  desktop: 1280, // lg
  desktopM: 1366,
  desktopL: 1440, // xl
  desktopXL: 1600,
  desktopXXL: 1920,
};

export function getTypography(theme: Theme): Typography {
  return {
    ...theme.typography,
    body1: {
      ...theme.typography.body1,
      color: colors.neutral[500],
    },
    display1: {
      fontSize: '60px',
      fontWeight: fontWeights.semiBold,
      lineHeight: '68px',
      letterSpacing: '-2.5px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-2px',
      },
    },
    display2: {
      fontSize: '72px',
      fontWeight: fontWeights.medium,
      lineHeight: '88px',
      letterSpacing: '-2.5px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-2px',
      },
    },
    h1: {
      fontSize: '48px',
      fontWeight: fontWeights.medium,
      lineHeight: '56px',
      letterSpacing: '-2px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '-0.5px',
      },
    },
    h2: {
      fontSize: '32px',
      fontWeight: fontWeights.medium,
      lineHeight: '40px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
    },
    h3: {
      fontSize: '28px',
      fontWeight: fontWeights.medium,
      lineHeight: '36px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '24px',
        lineHeight: '30px',
      },
    },
    h4: {
      fontSize: '24px',
      fontWeight: fontWeights.medium,
      lineHeight: '30px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: 0,
      },
    },
    h5: {
      fontSize: '20px',
      fontWeight: fontWeights.medium,
      lineHeight: '26px',
      letterSpacing: 0,
      [theme.breakpoints.down('tablet')]: {
        fontSize: '16px',
      },
    },
    h6: {
      fontSize: '16px',
      fontWeight: fontWeights.medium,
      lineHeight: '26px',
      letterSpacing: 0,
      [theme.breakpoints.down('tablet')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    sh1: {
      fontSize: '16px',
      fontWeight: fontWeights.medium,
      lineHeight: '26px',
      letterSpacing: 0,
    },
    sh2: {
      fontSize: '14px',
      fontWeight: fontWeights.medium,
      lineHeight: '22px',
      letterSpacing: 0,
    },
    sh3: {
      fontSize: '16px',
      fontWeight: fontWeights.regular,
      lineHeight: '26px',
      letterSpacing: 0,
    },
    sh4: {
      fontSize: '14px',
      fontWeight: fontWeights.regular,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    p1: {
      fontSize: '16px',
      fontWeight: fontWeights.medium,
      lineHeight: '26px',
      letterSpacing: 0,
    },
    p2: {
      fontSize: '14px',
      fontWeight: fontWeights.medium,
      lineHeight: '22px',
      letterSpacing: 0,
    },
    p3: {
      fontSize: '16px',
      fontWeight: fontWeights.regular,
      lineHeight: '26px',
      letterSpacing: 0,
    },
    p4: {
      fontSize: '14px',
      fontWeight: fontWeights.regular,
      lineHeight: '22px',
      letterSpacing: 0,
    },
    caption1: {
      fontSize: '12px',
      fontWeight: fontWeights.medium,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    caption2: {
      fontSize: '12px',
      fontWeight: fontWeights.regular,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    caption3: {
      fontSize: '10px',
      fontWeight: fontWeights.medium,
      lineHeight: '14px',
      letterSpacing: 0,
    },
    caption4: {
      fontSize: '10px',
      fontWeight: fontWeights.regular,
      lineHeight: '14px',
      letterSpacing: 0,
    },
  };
}
