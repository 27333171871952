function Logo() {
  return (
    <svg
      className="metycle-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="45"
      viewBox="2.5 0 130 45"
      fill="none"
    >
      <title>Metycle Logo</title>
      <path
        id="vector-circle"
        d="M36.9879 36.375C33.9024 38.8957 29.9611 40.4077 25.6662 40.4077C15.7759 40.4077 7.7584 32.3902 7.7584 22.5C7.7584 12.6097 15.7759 4.59217 25.6662 4.59217C35.5564 4.59217 43.5742 12.6097 43.5742 22.5C43.5742 24.7553 43.1572 26.9132 42.3962 28.901H47.2005C47.8027 26.8725 48.1259 24.7241 48.1259 22.5C48.1259 10.096 38.0704 0.0405273 25.6665 0.0405273C13.2625 0.0405273 3.20703 10.096 3.20703 22.5C3.20703 34.9039 13.2625 44.9594 25.6665 44.9594C31.2824 44.9594 36.4167 42.8978 40.3545 39.4905C40.3868 39.4626 40.5786 39.2921 40.6109 39.2639L36.9879 36.375Z"
        fill="#255659"
      />
      <path
        id="vector-arrow-tip"
        d="M44.2449 31.5338L43.0333 40.6181C43.0083 40.8035 42.8341 40.9147 42.668 40.8851L35.2703 35.2328C35.08 35.0863 35.1191 34.7873 35.3419 34.6958L43.8156 31.2036C44.0384 31.111 44.2772 31.295 44.2449 31.5338Z"
        fill="#70F3DC"
      />
      <path
        id="vector-letter-m"
        d="M38.3404 20.7985V28.894H33.8835V22.0132C33.8835 19.9402 33.0485 19.0157 31.1761 19.0157C29.5438 19.0157 27.8966 20.1511 27.8966 22.6878V28.894H23.4397V22.0132C23.4397 20.9931 23.2329 20.2602 22.8071 19.772C22.3634 19.2631 21.6769 19.0157 20.7086 19.0157C19.088 19.0157 17.453 20.1511 17.453 22.6878V28.894H12.9961V15.3481H17.4544L17.4129 18.6949C17.7971 17.6569 18.3632 16.8282 19.1005 16.2257C20.0321 15.4643 21.2229 15.0781 22.6399 15.0781C24.1783 15.0781 25.3959 15.4548 26.2592 16.197C27.0043 16.8382 27.5053 17.7693 27.7514 18.969C28.1047 17.836 28.7196 16.8966 29.5449 16.2352C30.5025 15.4675 31.7012 15.0781 33.1077 15.0781C34.9057 15.0781 36.2588 15.5994 37.129 16.6274C37.9328 17.5769 38.3404 18.9801 38.3404 20.7985Z"
        fill="#255659"
      />

      <path
        id="vector-letter-e"
        d="M62.4102 23.9539C62.0403 23.9539 61.6916 24.1702 61.4997 24.5189C61.0997 25.2465 60.0257 25.6003 58.2167 25.6003C55.8534 25.6003 54.616 24.7406 54.3332 22.898H64.6077C65.1787 22.898 65.6432 22.4139 65.6432 21.8191V21.685C65.6432 19.4476 65.0161 17.7244 63.7798 16.5638C62.4978 15.3603 60.5459 14.7502 57.9782 14.7502C52.8651 14.7502 49.8125 17.4335 49.8125 21.9279C49.8125 23.86 50.4293 25.4994 51.5962 26.669C53.0306 28.1069 55.258 28.8358 58.2167 28.8358C60.6873 28.8358 62.5836 28.3669 63.853 27.4424C64.4267 27.0242 64.8707 26.5121 65.1719 25.9197C65.453 25.3672 65.6115 24.7433 65.6432 24.0652L65.6484 23.9539H62.4102ZM54.4439 20.0604C54.8404 18.646 55.9896 17.9858 58.0466 17.9858C60.1299 17.9858 61.2631 18.7022 61.335 20.0604H54.4439ZM61.4446 20.1667H61.4449V20.2007C61.4449 20.1893 61.4449 20.1781 61.4446 20.1667Z"
        fill="#255659"
      />

      <path
        id="vector-letter-t"
        d="M73.1307 19.3381V23.4908C73.1307 23.8615 73.4167 24.163 73.7681 24.163H78.2918V28.7765H72.6688C70.4737 28.7765 68.6879 26.9095 68.6879 24.6146V20.5226H68.6735V19.3381C68.6735 18.9793 68.397 18.6874 68.0567 18.6874H66.5039V15.0198H68.4222C68.9394 15.0198 69.1267 14.7707 69.2227 13.9534L69.4482 11.9971H73.1307V14.3691C73.1307 14.7281 73.4075 15.0198 73.7475 15.0198H78.2918V18.6874H73.7475C73.4075 18.6874 73.1307 18.9793 73.1307 19.3381Z"
        fill="#255659"
      />

      <path
        id="vector-letter-y"
        d="M96.4638 14.7837L89.5911 30.5732C88.7928 32.4071 87.0321 33.5921 85.1052 33.5921H80.505V28.9786H84.9988C85.1559 28.9786 85.2954 28.8991 85.3814 28.7605C85.474 28.6115 85.4829 28.4283 85.4048 28.2701L78.8203 14.7837H83.9217L88.2585 23.7923C88.2625 23.8009 88.269 23.8004 88.2731 23.7915L92.0823 14.7837H96.4638Z"
        fill="#255659"
      />

      <path
        id="vector-letter-c"
        d="M110.464 22.8199V22.9263C110.464 24.6006 109.866 26.0199 108.735 27.0307C107.395 28.2283 105.358 28.8356 102.68 28.8356C101.334 28.8356 100.123 28.6296 99.0799 28.2231C98.1233 27.8502 97.3046 27.3086 96.6471 26.6128C95.4867 25.3849 94.8477 23.6826 94.8477 21.8197C94.8477 20.8463 95.0173 19.9269 95.3516 19.087C95.6949 18.2243 96.2042 17.4664 96.8655 16.8341C98.2918 15.4705 100.376 14.75 102.894 14.75C105.27 14.75 107.129 15.2705 108.422 16.2976C109.544 17.189 110.221 18.4732 110.379 20.011C110.402 20.2305 110.332 20.4484 110.187 20.6094C110.049 20.7621 109.861 20.8465 109.66 20.8465H106.438L106.436 20.7423C106.427 20.2984 106.285 19.662 105.659 19.1527C105.058 18.6647 104.168 18.4173 103.013 18.4173C100.656 18.4173 99.3046 19.6476 99.3046 21.7928C99.3046 23.9694 100.588 25.1681 102.918 25.1681C104.872 25.1681 106.114 24.5352 106.416 23.386C106.503 23.0527 106.791 22.8199 107.117 22.8199H110.464Z"
        fill="#255659"
      />

      <path
        id="vector-letter-l"
        d="M115.817 9.45947H111.359V28.5656H115.817V9.45947Z"
        fill="#255659"
      />

      <path
        id="vector-letter-e"
        d="M129.242 23.9537C128.872 23.9537 128.524 24.17 128.332 24.5187C127.932 25.2462 126.858 25.6001 125.049 25.6001C122.685 25.6001 121.448 24.7404 121.165 22.8978H131.44C132.011 22.8978 132.475 22.4137 132.475 21.8188V21.6848C132.475 19.4474 131.848 17.7242 130.612 16.5635C129.33 15.36 127.378 14.75 124.81 14.75C119.697 14.75 116.645 17.4333 116.645 21.9277C116.645 23.8598 117.261 25.4991 118.428 26.6687C119.863 28.1067 122.09 28.8356 125.049 28.8356C127.519 28.8356 129.416 28.3667 130.685 27.4421C131.259 27.0239 131.703 26.5119 132.004 25.9195C132.285 25.367 132.444 24.7431 132.475 24.0649L132.48 23.9537H129.242ZM121.276 20.0601C121.672 18.6458 122.822 17.9855 124.879 17.9855C126.962 17.9855 128.095 18.7019 128.167 20.0601H121.276ZM128.277 20.1665L128.277 20.2004C128.277 20.189 128.277 20.1779 128.277 20.1665Z"
        fill="#255659"
      />
    </svg>
  );
}

export default Logo;
