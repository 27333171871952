import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { IMAGE_VARIANT_TYPE } from './getImage.types';

const useGetImageUrl = () => {
  const { apiUrl } = useContext(ApiContext);
  const getImageUrl = (
    parentId: string,
    imageId: string,
    variant: IMAGE_VARIANT_TYPE,
  ) => {
    const IMAGE_URL_VARIANTS = {
      BIDDING: `${apiUrl}/trading/listings/${parentId}/photos/${imageId}/download/`,
      CONTAINER: `${apiUrl}/orders/containers/${parentId}/loading-photos/${imageId}/download/`,
      LISTING: `${apiUrl}/trading/seller/listings/${parentId}/photos/${imageId}/download/`,
    } as Record<IMAGE_VARIANT_TYPE, string>;

    return IMAGE_URL_VARIANTS[variant] || '';
  };
  return { getImageUrl };
};

export default useGetImageUrl;
