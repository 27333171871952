import { generateUrlFromLocation } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { ToastAlert } from '@frontend/ui-elements';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function WhatsappConsentBanner() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isWhatsappConsentBannerVisible, setWhatsappConsentBannerVisible] =
    useState(false);

  const handleClose = () => {
    setWhatsappConsentBannerVisible(false);
  };

  useEffect(() => {
    if (location.state?.whatsappConsentSuccess) {
      setWhatsappConsentBannerVisible(true);
      navigate(
        {
          pathname: generateUrlFromLocation(location),
        },
        {
          state: { ...location.state, whatsappConsentSuccess: false },
          replace: true,
        },
      );
    }
  }, [location]);

  if (isWhatsappConsentBannerVisible) {
    return (
      <ToastAlert
        title={
          <Translate id="customer-platform.request-list.whatsapp-consent.accepted.title" />
        }
        message={
          <Translate id="customer-platform.request-list.whatsapp-consent.accepted.subtitle" />
        }
        severity="success"
        onClose={handleClose}
      />
    );
  }
}
