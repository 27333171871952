import { IModalProps } from './Modal.types';
import { ModalSt as MUIModal } from './Modal.css';

export function Modal(props: IModalProps) {
  const { children, ...rest } = props;

  return <MUIModal {...rest}>{children}</MUIModal>;
}

export default Modal;
