import { styled } from '@frontend/theme';
import { PickersLayout as MUIPickersLayout } from '@mui/x-date-pickers/PickersLayout/PickersLayout';
import { DateView } from '@mui/x-date-pickers';

export const DatePickerLayout = styled(
  MUIPickersLayout<Date | null, Date, DateView>,
)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 4px 12px 0px rgba(36, 36, 36, 0.08)',
  '& > .MuiPickersLayout-contentWrapper > .MuiDateCalendar-root': {
    height: '100%',
    maxHeight: 'none',
    // Header
    '& > .MuiPickersCalendarHeader-root': {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      paddingLeft: '24px',
      maxHeight: 'none',
      minHeight: 'auto',

      // Header Label
      '& > .MuiPickersCalendarHeader-labelContainer': {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        flex: '1 0 0',
        // Header Label Icon
        '& > .MuiPickersCalendarHeader-switchViewButton': {
          marginRight: 0,
          padding: '4px',
          '& > svg': {
            fill: theme.palette.neutral[300],
            width: '34px',
            height: '34px',
          },
        },
      },

      // Header Arrows
      '& > .MuiPickersArrowSwitcher-root': {
        display: 'flex',
        alignItems: 'flex-start',
        '& > .MuiPickersArrowSwitcher-button > svg': {
          fill: theme.palette.neutral[500],
        },
      },
    },

    // Calendar Month Section
    '& > .MuiPickersFadeTransitionGroup-root .MuiPickersSlideTransition-root': {
      display: 'flex',
      flexDirection: 'column',
      // height: '264px',
      paddingTop: '12px',
      alignItems: 'center',
      gap: '2px',
      // Calendar Day
      '.MuiPickersDay-root': {
        color: theme.palette.neutral[500],
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        '&.Mui-selected': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary[500],
          fontWeight: theme.typography.fontWeightMedium,
        },
        '&:hover': {
          color: theme.palette.neutral[500],
          backgroundColor: theme.palette.primary[50],
        },
      },
    },
  },
}));
