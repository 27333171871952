import { styled } from '@frontend/theme';
import {
  FormControlLabel as MUIFormControlLabel,
  Radio as MUIRadio,
  FormControl as MUIFormControl,
} from '@mui/material';

export const FormControlLabel = styled(MUIFormControlLabel)({
  marginRight: '24px',
  marginLeft: '0px',
  display: 'flex',
  gap: '4px',
});

export const Radio = styled(MUIRadio)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
  },
  '&.MuiRadio-root': {
    color: theme.palette.neutral[300],
    padding: '0px',
    '&:hover': {
      color: theme.palette.primary[500],
    },
    '&.Mui-checked': {
      color: theme.palette.primary[500],
    },
  },
}));

export const FormControl = styled(MUIFormControl)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
