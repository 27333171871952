import { styled } from '@frontend/theme';
import { Avatar as MUIAvatar, Box as MUIBox } from '@mui/material';

export const NameRolBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
}));

export const UserAvatar = styled(MUIAvatar)(() => ({
  width: '46px',
  height: '48px',
}));
