import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ContainerDrawerBodyWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.up('desktop')]: {
    width: '970px',
  },
}));

export const LoaderWrapper = styled(MUIBox)({
  display: 'flex',
  justifyContent: 'center',
});
