import { styled, theme } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    borderTop: `1px solid ${theme?.palette.neutral[50]}`,
  },
  '& > *:first-of-type': {
    borderTop: 'none',
  },
}));

export const DataWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  gap: '40px',
  padding: '36px 0 24px 0',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: '0',
    padding: '24px 0',
  },
}));

export const Column = styled(MUIBox)(() => ({
  flex: '1',
  '& > *': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
  '& > *:last-child': {
    borderBottom: 'none',
  },
}));

export const OrderValue = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  paddingTop: '12px',
  '&:first-of-type': {
    paddingTop: '0',
  },
  paddingBottom: '12px',
  [theme.breakpoints.down('tablet')]: {
    '&:first-of-type': {
      paddingTop: '12px',
    },
  },
}));

export const OrderValueLabel = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
