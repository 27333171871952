import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const VesselCardWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  paddingTop: '12px',
  paddingBottom: '12px',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.neutral[100]}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
}));

export const VesselRowWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});
