import { useContext } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IListingErrors, IListingSubmissionData } from './createListing.types';
import { QueryKey as GetListingListQueryKey } from '../getListingList/getListingList.const';

const submitCreateListing = (
  createListing: IListingSubmissionData,
  apiUrl: string,
) => {
  const listingForm = new FormData();
  Object.entries(createListing).forEach(([key, value]) => {
    key === 'materialPhotos'
      ? createListing.materialPhotos?.forEach(file => {
          listingForm.append('materialPhotos', file, file.name);
        })
      : listingForm.append(key, JSON.stringify(value));
  });

  return axiosClient.post(`${apiUrl}/trading/listings/create/`, listingForm);
};

const useCreateListing = () => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IListingSubmissionData>,
    AxiosError<IListingErrors>,
    IListingSubmissionData
  >({
    mutationFn: (createListing: IListingSubmissionData) =>
      submitCreateListing(createListing, apiUrl),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GetListingListQueryKey,
      });
    },
  });
};

export default useCreateListing;
