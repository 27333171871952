import { useMediaQuery, useTheme } from '@mui/material';
import { StatusBarContainer, StepConnector } from './StatusBar.css';
import { StatusBarProps } from './StatusBar.types';
import MobileStatusBar from './MobileStatusBar/MobileStatusBar';
import SimplifiedMobileStatusBar from './SimplifiedMobileStatusBar/SimplifiedMobileStatusBar';
import StepsBar from '../StepsBar/StepsBar';

function StatusBar({
  activeStep,
  isSimplified = false,
  onClick,
  open = false,
  stages,
}: StatusBarProps) {
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('tablet'));

  return (
    <StatusBarContainer>
      {isDesktopView ? (
        <StepsBar
          activeStep={activeStep}
          connector={<StepConnector />}
          stages={stages}
        />
      ) : isSimplified ? (
        <SimplifiedMobileStatusBar
          progress={((activeStep + 1) / stages.length) * 100}
          stageLabel={
            stages.find(stage => stage?.order === activeStep)?.label as string
          }
          stageNumber={activeStep + 1}
        />
      ) : (
        <MobileStatusBar
          activeStep={activeStep}
          onClick={onClick}
          open={open}
          stages={stages}
        />
      )}
    </StatusBarContainer>
  );
}

export default StatusBar;
