import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '20px',
  gap: '20px',
  flex: '1 1 calc(100%/3 - 20px)',
  maxWidth: 'calc(100%/3 - 20px)',
  border: `1px solid ${theme.palette.primary[100]}`,
  [theme.breakpoints.down('tabletL')]: {
    flex: '1 1 calc(100%/2 - 10px)',
    maxWidth: 'calc(100%/2 - 10px)',
  },
  [theme.breakpoints.down('tablet')]: {
    flex: '1 1 100%',
    maxWidth: '100%',
  },
}));

export const DataWrapper = styled(MUIBox)(({ theme }) => ({
  '& > *': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
  '& > *:last-child': {
    borderBottom: 'none',
  },
}));

export const DetailsItem = styled(MUIBox)(() => ({
  display: 'flex',
  padding: '12px 0px',
  alignItems: 'center',
  gap: '8px',
}));

export const ImageWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  height: 'auto',
  '& > img': {
    width: '100%',
    maxWidth: '168.3px',
    height: '100%',
    objectFit: 'contain',
  },
}));
