import { Box as MUIBox } from '@mui/material';
import { styled } from '@frontend/theme';

export const DocumentsWrapper = styled(MUIBox)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '16px',
  gridAutoRows: '1fr',
  gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
  [theme.breakpoints.down('tabletL')]: {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ButtonsWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '20px',
}));
