import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ContainerImagesWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  paddingBottom: '24px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,
}));
