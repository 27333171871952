export const documentTypesTranslations: Record<string, string> = {
  annex_vii: 'customer-platform.api.order.document.annexVii',
  bill_of_lading: 'customer-platform.api.order.document.billOfLading',
  certificate_of_origin:
    'customer-platform.api.order.document.certificateOfOrigin',
  final_invoice: 'customer-platform.api.order.document.finalInvoice',
  green_list_contract: 'customer-platform.api.order.document.greenListContract',
  non_radioactive_certificate:
    'customer-platform.api.order.document.nonRadioactiveCertificate',
  pack_list: 'customer-platform.api.order.document.packList',
  down_payment_invoice:
    'customer-platform.api.request.document.down-payment-invoice',
  sales_order: 'customer-platform.api.request.document.sales-order',
  freight_insurance: 'customer-platform.api.request.document.freight-insurance',
  weighbridge_ticket:
    'customer-platform.request-details.order-list.container-drawer.container-documents.document-container.weighbridge_ticket',
};
