import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IBuyer,
  IBuyerErrors,
} from '../createBuyerAndRequest/createBuyerAndRequest.types';
import { IUseRegisterUserRequest } from './registerUserRequest.types';

const submitRegisterUserRequest = ({
  registerUserRequest,
  apiUrl,
  redirectUrl,
  sourceUrl,
}: {
  registerUserRequest: IBuyer;
  apiUrl: string;
  redirectUrl?: string;
  sourceUrl?: string;
}) => {
  const params = new URLSearchParams();
  if (sourceUrl) {
    params.append('sourceUrl', sourceUrl);
  }
  if (redirectUrl) {
    params.append('redirectUrl', redirectUrl);
  }
  const queryParams = params.toString();

  return axiosClient.post(
    `${apiUrl}/accounts/user/registration/?${queryParams}`,
    registerUserRequest,
  );
};

const useRegisterUserRequest = ({
  redirectUrl,
  sourceUrl,
}: IUseRegisterUserRequest) => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<AxiosResponse<IBuyer>, AxiosError<IBuyerErrors>, IBuyer>({
    mutationFn: (registerUserRequest: IBuyer) =>
      submitRegisterUserRequest({
        registerUserRequest,
        apiUrl,
        redirectUrl,
        sourceUrl,
      }),
  });
};

export default useRegisterUserRequest;
