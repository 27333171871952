import { DatePickerToolbarProps } from '@mui/x-date-pickers';
import { Typography, useTheme } from '@mui/material';
import { PickerToolbarWrapper } from './DatePickerToolbar.css';

export default function DatePickerToolbar(props: DatePickerToolbarProps<Date>) {
  const { hidden = true } = props;
  const theme = useTheme();

  return !hidden ? (
    <PickerToolbarWrapper>
      <Typography variant="p1" color={theme.palette.neutral[500]}>
        Select Date
      </Typography>
    </PickerToolbarWrapper>
  ) : null;
}
