type TFileType =
  | 'gif'
  | 'heic'
  | 'jpeg'
  | 'jpg'
  | 'pdf'
  | 'png'
  | 'svg'
  | 'tiff';

export interface IGetImageResponseFailure {
  detail: string;
}

export interface IGenericFile {
  uniqueIdentifier: string;
  name?: string; // @todo: This prop will be removed after migrating IContainerPhoto to IGenericFile
  fileType: TFileType;
}

export const IMAGE_VARIANT = {
  BIDDING: 'BIDDING',
  CONTAINER: 'CONTAINER',
  LISTING: 'LISTING',
} as const;
export type IMAGE_VARIANT_TYPE = keyof typeof IMAGE_VARIANT;
