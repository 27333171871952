import { RefObject, useEffect, useState } from 'react';

type useGetDistanceToTopProps = {
  elementRef: RefObject<HTMLDivElement>;
};

export function useGetDistanceToTop({
  elementRef,
}: useGetDistanceToTopProps): number {
  const [distanceToTop, setDistanceToTop] = useState(0);

  useEffect(() => {
    if (elementRef.current && distanceToTop === 0) {
      const rect = elementRef.current.getBoundingClientRect();
      setDistanceToTop(rect.top);
    }
  }, [distanceToTop, elementRef]);

  return distanceToTop;
}
