export const FILE_MANAGER_MAX_FILE_SIZE = 10485760; // 10MB

export const FILE_MANAGER_SUPPORTED_FORMATS = {
  pdf: 'application/pdf',
  zip: 'application/zip',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  gif: 'image/gif',
  heic: 'image/heic',
  tiff: 'image/tiff',
  webp: 'image/webp',
} as Record<string, string>;
