import { useIntl } from 'react-intl';
import { Toast, TransitionRight } from '@frontend/ui-elements';
import { SuccessToastProps } from './SuccessToast.types';

export default function SuccessToast({
  alertType = 'success',
  isToastOpen,
  closeToast,
  titleId,
  messageId,
}: SuccessToastProps) {
  const intl = useIntl();

  return (
    <Toast
      snackbarProps={{
        open: isToastOpen,
        autoHideDuration: 8000,
        alertType: alertType,
        disableWindowBlurListener: true,
        onClose: closeToast,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        TransitionComponent: TransitionRight,
      }}
      alertProps={{
        onClose: closeToast,
        severity: alertType,
        title: intl.formatMessage({
          id: titleId,
        }),
        message: intl.formatMessage({
          id: messageId,
        }),
      }}
    />
  );
}
