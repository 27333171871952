import { ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ControllerRenderProps, useController, useForm } from 'react-hook-form';
import { matchIsValidTel } from 'mui-tel-input';
import * as yup from 'yup';
import { ISeller, ISellerKeys } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Button, CheckBox, RadioGroup, TextField } from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import {
  ButtonsBox,
  CaptionBox,
  DualBox,
  FieldsWrapper,
  FormBox,
  FormContainer,
} from './SellerForm.css';
import { ISellerFormData, SellerFormProps } from './SellerForm.types';
import FormType from '@customer-platform-shared/components/Forms/utils/FormType.types';
import PhoneField from '@customer-platform-shared/components/ui-csr/PhoneField/PhoneField';
import { useFormTracking } from '@customer-platform-shared/components/Forms/utils/useFormTracking';
import { PHONE_NUMBER_AS_WHATSAPP_NUMBER_TYPE } from './SellerForm.const';
import { buildSelectDataByOptionsAndNamespace } from '@customer-platform-shared/components/Forms/utils/utils';

export default function SellerForm({
  errors,
  isSuccess,
  isPending,
  onPrevStepClick,
  onSubmit: onSubmitParam,
}: SellerFormProps) {
  const onSubmit = (data: FormType<ISellerFormData>) => {
    const { usePhoneNumberAsWhatsAppNumber: _, ...rest } = data;

    const customerData = {
      ...rest,
      whatsappPhoneNumber: rest.whatsappPhoneNumber?.replace(/ /g, ''),
      phoneNumber: rest.phoneNumber?.replace(/ /g, ''),
    };

    onSubmitParam(customerData);
  };

  const intl = useIntl();
  const requiredField = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const formId = 'seller-form.form';

  const sellerFormSchema = yup.object().shape({
    firstName: yup.string().required(requiredField),
    lastName: yup.string().required(requiredField),
    companyName: yup.string().required(requiredField),
    street: yup.string().required(requiredField),
    postalCode: yup.string().required(requiredField),
    vatNumber: yup.string().required(requiredField),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'customer-platform.shared.components.forms.errors.email.invalid',
        }),
      )
      .required(requiredField),
    phoneNumber: yup
      .string()
      .test(
        'phoneNumberValidation',
        intl.formatMessage({
          id: 'customer-platform.shared.components.forms.errors.phone_number.invalid',
        }),
        (phoneNumber = '') => {
          if (!phoneNumber) {
            return true;
          }
          return matchIsValidTel(phoneNumber);
        },
      )
      .required(requiredField),
    whatsappPhoneNumber: yup
      .string()
      .test(
        'phoneNumberValidation',
        intl.formatMessage({
          id: 'customer-platform.shared.components.forms.errors.phone_number.invalid',
        }),
        (phoneNumber = '') => {
          if (!phoneNumber) {
            return true;
          }
          return matchIsValidTel(phoneNumber);
        },
      )
      .required(requiredField),
    whatsappConsent: yup.boolean().optional(),
  });

  const usePhoneNumberAsWhatsAppNumberData =
    buildSelectDataByOptionsAndNamespace(
      PHONE_NUMBER_AS_WHATSAPP_NUMBER_TYPE,
      intl,
      'user.use_phone_number_as_whatsapp_number',
    );

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    setValue,
    formState: { errors: formErrors },
  } = useForm<FormType<ISellerFormData>>({
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      street: '',
      postalCode: '',
      vatNumber: '',
      email: '',
      phoneNumber: '',
      whatsappPhoneNumber: '',
      usePhoneNumberAsWhatsAppNumber: 'yes',
      whatsappConsent: false,
    },
    resolver: yupResolver<FormType<ISeller>>(sellerFormSchema),
  });

  const usePhoneNumberAsWhatsAppNumber =
    watch('usePhoneNumberAsWhatsAppNumber') === 'yes';
  const phoneNumber = watch('phoneNumber');
  const displayWhatsappNumberField = !usePhoneNumberAsWhatsAppNumber;

  const handleChange = (field: ControllerRenderProps) => (value: string) => {
    field.onChange(value);
  };

  const { field: phoneNumberField } = useController({
    name: 'phoneNumber',
    control,
  });

  const { field: usePhoneNumberAsWhatsAppNumberField } = useController({
    name: 'usePhoneNumberAsWhatsAppNumber',
    control,
  });

  const { field: whatsappPhoneNumberField } = useController({
    name: 'whatsappPhoneNumber',
    control,
  });

  const { field: whatsappConsentField } = useController({
    name: 'whatsappConsent',
    control,
  });

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    whatsappConsentField.onChange(event.target.checked);
  };

  const handleRadioBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    usePhoneNumberAsWhatsAppNumberField.onChange(event.target.value);
  };

  useEffect(() => {
    if (errors !== undefined) {
      Object.keys(errors).map((field: string) =>
        setError(field as ISellerKeys, {
          type: 'manual',
          message: errors[field as ISellerKeys][0] as string,
        }),
      );
    }
  }, [errors, setError]);

  useFormTracking({ formId, formErrors, isSuccess });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [reset, isSuccess]);

  useEffect(() => {
    if (usePhoneNumberAsWhatsAppNumber) {
      setValue('whatsappPhoneNumber', phoneNumber);
    } else {
      setValue('whatsappPhoneNumber', '');
    }
  }, [phoneNumber, setValue, usePhoneNumberAsWhatsAppNumber]);

  return (
    <FormContainer>
      <CaptionBox>
        <Typography variant="p1">
          <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.paragraph" />
        </Typography>
        <Typography component="p" variant="caption2">
          <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.caption" />
        </Typography>
      </CaptionBox>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        id={formId}
        name={formId}
      >
        <FormBox>
          <FieldsWrapper>
            <DualBox>
              <TextField
                id="first-name"
                label={
                  <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.first_name" />
                }
                {...register('firstName')}
                error={!!formErrors?.firstName}
                helperText={formErrors?.firstName?.message}
                required
                formId={formId}
              />
              <TextField
                id="last-name"
                label={
                  <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.last_name" />
                }
                {...register('lastName')}
                error={!!formErrors?.lastName}
                helperText={formErrors?.lastName?.message}
                required
                formId={formId}
              />
            </DualBox>
            <TextField
              id="company-name"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.company_name" />
              }
              {...register('companyName')}
              error={!!formErrors?.companyName}
              helperText={formErrors?.companyName?.message}
              required
              formId={formId}
            />
            <DualBox>
              <TextField
                id="street"
                label={
                  <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.street" />
                }
                {...register('street')}
                error={!!formErrors?.street}
                helperText={formErrors?.street?.message}
                required
                formId={formId}
              />
              <TextField
                id="postal-code"
                label={
                  <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.postal_code" />
                }
                {...register('postalCode')}
                error={!!formErrors?.postalCode}
                helperText={formErrors?.postalCode?.message}
                required
                formId={formId}
              />
            </DualBox>
            <TextField
              id="vat-number"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.vat_number" />
              }
              {...register('vatNumber')}
              error={!!formErrors?.vatNumber}
              helperText={formErrors?.vatNumber?.message}
              required
              formId={formId}
            />
            <TextField
              id="email"
              type="email"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.email" />
              }
              {...register('email')}
              error={!!formErrors?.email}
              helperText={formErrors?.email?.message}
              required
              formId={formId}
            />
            <PhoneField
              id="phone-number"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.phone_number" />
              }
              defaultCountry="DE"
              value={phoneNumberField.value}
              onChange={handleChange(phoneNumberField as ControllerRenderProps)}
              error={!!formErrors.phoneNumber}
              helperText={formErrors?.phoneNumber?.message}
              formId={formId}
              required
            />
            <RadioGroup
              id="use-phone-number-as-whatsApp-number"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.radio.use_phone_number_as_whatsapp_number" />
              }
              options={usePhoneNumberAsWhatsAppNumberData.options}
              value={usePhoneNumberAsWhatsAppNumberField.value}
              onChange={handleRadioBoxChange}
              formId={formId}
            />
            {displayWhatsappNumberField && (
              <PhoneField
                id="whatsapp-number"
                label={
                  <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.textfield.whatsapp_number" />
                }
                defaultCountry="DE"
                value={whatsappPhoneNumberField.value}
                onChange={handleChange(
                  whatsappPhoneNumberField as ControllerRenderProps,
                )}
                error={!!formErrors.whatsappPhoneNumber}
                helperText={formErrors?.whatsappPhoneNumber?.message}
                required
                formId={formId}
              />
            )}
            <CheckBox
              id="whatsapp-consent"
              label={
                <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.checkbox.whatsapp_consent" />
              }
              value={whatsappConsentField.value as boolean}
              onChange={handleCheckBoxChange}
              error={!!formErrors?.whatsappConsent}
              helperText={formErrors?.whatsappConsent?.message}
              formId={formId}
              size="small"
            />
          </FieldsWrapper>
          <ButtonsBox>
            <Button
              buttonType="linked"
              id="seller-form.button.previous-tab"
              size="large"
              onClick={onPrevStepClick}
            >
              <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.button.previous" />
            </Button>
            <Button
              buttonType="primary"
              id="seller-form.button.submit"
              size="large"
              type="submit"
              isSubmitting={isPending}
            >
              <Translate id="customer-platform.sell-metal.listing-form.tabs.seller-tab.button.submit" />
            </Button>
          </ButtonsBox>
        </FormBox>
      </form>
    </FormContainer>
  );
}
