import { styled } from '@frontend/theme';
import { Container as MUIContainer, Box as MUIBox } from '@mui/material';

export const ButtonsBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '32px',

  '& > .MuiButton-root:last-of-type': {
    flex: '1 0 0',
  },

  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: '20px',

    '& > .MuiButton-root': {
      width: '100%',
    },
  },
}));

export const CaptionBox = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
});

export const DualBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
    gap: '20px',
  },

  '& > .MuiFormControl-root': {
    width: '100%',
  },
}));

export const FieldsWrapper = styled(MUIContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '24px',
  padding: 0,

  '& > .MuiFormControl-root': {
    width: '100%',
  },

  [theme.breakpoints.down('tabletL')]: {
    gap: '20px',
  },
}));

export const FormBox = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const FormContainer = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  '& > form': {
    width: '100%',
  },
  [theme.breakpoints.down('tabletL')]: {
    gap: '20px',
  },
  [theme.breakpoints.down('tablet')]: {
    gap: '24px',
  },
}));
