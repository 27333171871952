import { styled } from '@frontend/theme';
import { TextareaAutosize as MUITextArea } from '@mui/base';
import { Box as MUIBox } from '@mui/material';

export const MUITextAreaSt = styled(MUITextArea, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
  // TextArea font
  color: theme.palette.neutral[500],
  fontFamily: '"Clash Grotesk", sans-serif',
  fontSize: theme.typography.p3.fontSize,
  lineHeight: theme.typography.p3.lineHeight,
  fontWeight: theme.typography.p3.fontWeight,

  // TextArea
  borderColor: error ? theme.palette.error[500] : theme.palette.primary[200],
  borderRadius: 0,
  padding: '12px 16px',
  minHeight: '50px',
  width: '100%',
  resize: 'none',

  '&::placeholder': {
    color: error ? theme.palette.error[500] : theme.palette.neutral[500],
  },
  '&:hover': {
    borderColor: theme.palette.primary[500],
  },
  '&:active': {
    borderColor: theme.palette.primary[500],
  },
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
    borderColor: theme.palette.primary[500],
  },
}));

export const TextAreaWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '100%',
});
export const HelperTextWrapper = styled(MUIBox)({
  paddingLeft: '16px',
  width: '100%',
});
