import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IBiddingSubmissionData,
  IBiddingSubmissionResponse,
  IBiddingSubmissionResponseFailure,
} from './createBidding.types';
import { getQueryKey as GetBiddingSubmissionQueryKey } from './createBidding.const';

const submitCreateBidding = (
  apiUrl: string,
  createBidding: IBiddingSubmissionData,
  uuid: string,
) => {
  return axiosClient.post(
    `${apiUrl}/trading/listings/${uuid}/bidding/`,
    createBidding,
  );
};

const useCreateBidding = (uuid: string) => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IBiddingSubmissionResponse>,
    AxiosError<IBiddingSubmissionResponseFailure>,
    IBiddingSubmissionResponse
  >({
    mutationFn: (createBidding: IBiddingSubmissionData) =>
      submitCreateBidding(apiUrl, createBidding, uuid),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GetBiddingSubmissionQueryKey(uuid),
      });
    },
  });
};

export default useCreateBidding;
