import { styled } from '@frontend/theme';
import { Container as MUIContainer } from '@mui/material';

export const MainWrapper = styled(MUIContainer)(({ theme }) => ({
  width: 'auto',
  padding: '8px 16px 8px 16px',
  backgroundColor: theme.palette.tertiary[500],
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'unset',
  gap: '12px',
}));
