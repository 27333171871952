import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ContainerInformationWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  paddingBottom: '24px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,
}));

export const InformationItemWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  padding: '8px 12px',
  borderRight: `1px solid ${theme.palette.neutral[50]}`,
  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-of-type': {
    borderRight: 0,
  },

  [theme.breakpoints.down('tabletL')]: {
    padding: '12px 0',
    borderRight: 0,
    borderBottom: `1px solid ${theme.palette.neutral[50]}`,
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
}));

export const InformationItemsWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
}));

export const MissingInfoWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'flex-start',
  padding: '8px 16px',
  width: '100%',
  backgroundColor: theme.palette.tertiary[500],
  '& > svg': {
    minWidth: '24px',
  },
}));
