import { createContext } from 'react';
import { IApiContext, IApiContextProvider } from './ApiContext.types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const ApiContext = createContext<IApiContext>({ apiUrl: '' });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function ApiContextProvider({
  apiUrl,
  children,
}: IApiContextProvider) {
  return (
    <ApiContext.Provider value={{ apiUrl }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiContext.Provider>
  );
}
