import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetRequestList } from '@frontend/api';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageColumns, PageContent } from './RequestDetails.css';
import DashboardHeader from '@customer-platform-shared/components/DashboardHeader/DashboardHeader';
import RequestOrderList from './RequestOrderList/RequestOrderList';
import PlatformHeader from '@customer-platform-shared/components/PlatformHeader/PlatformHeader';
import RequestList from './RequestList/RequestList';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { HEADER_VARIANTS } from '@customer-platform-shared/components/PlatformHeader/PlatformHeader.types';

export default function RequestDetails() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const {
    isSuccess: isRequestListSuccess,
    isPending: isRequestListPending,
    data: requestListResponse,
  } = useGetRequestList();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  useEffect(() => {
    if (isRequestListSuccess && requestListResponse) {
      const currentRequest =
        requestListResponse.data.find(request => request.uuid === uuid) ??
        requestListResponse.data[0];

      if (currentRequest.uuid !== uuid) {
        const urlDestination = generatePath(PLATFORM_ROUTES.requestDetails, {
          uuid: requestListResponse.data[0].uuid,
        });
        navigate(urlDestination, { replace: true });
      }
    }
  }, [isRequestListSuccess, requestListResponse, uuid, navigate]);

  if (isRequestListSuccess) {
    const currentRequest =
      requestListResponse.data.find(request => request.uuid === uuid) ??
      requestListResponse.data[0];

    return (
      <>
        <PlatformHeader variant={HEADER_VARIANTS.BUYER} />
        {isDesktop ? (
          <PageWrapper>
            <PageContent>
              <DashboardHeader cardsCount={requestListResponse.data.length} />
              <PageColumns>
                <RequestList requests={requestListResponse.data} />
                <RequestOrderList request={currentRequest} />
              </PageColumns>
            </PageContent>
          </PageWrapper>
        ) : (
          <PageContent>
            <DashboardHeader />
            <RequestOrderList request={currentRequest} />
          </PageContent>
        )}
      </>
    );
  }

  if (isRequestListPending) {
    return <FullPageLoader />;
  }
}
