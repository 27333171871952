import { styled } from '@frontend/theme';
import { Box as MUIBox, Drawer as MUIDrawer } from '@mui/material';

export const Drawer = styled(MUIDrawer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down('tabletL')]: {
    '& .MuiPaper-root': {
      width: '100%',
    },
  },
}));

export const DrawerHeader = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '32px',
  borderBottom: `1px solid ${theme.palette.neutral[50]}`,

  [theme.breakpoints.down('tabletL')]: {
    paddingLeft: '24px',
  },
  [theme.breakpoints.down('tablet')]: {
    paddingLeft: '20px',
  },
}));

export const DrawerBody = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '24px 32px 48px',

  [theme.breakpoints.down('tabletL')]: {
    gap: '24px',
    padding: '24px 24px 48px',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '24px 20px 48px',
  },
}));
