import { styled } from '@frontend/theme';
import { IconButton as BaseIconButton } from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const IconButton = styled(BaseIconButton)(() => ({
  position: 'absolute',
  top: '8px',
  right: '16px',
}));

export const IconWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  position: 'relative',
  '& > svg': {
    '&:first-of-type': {
      zIndex: 1,
    },
    '&:last-of-type': {
      position: 'absolute',
      top: 3,
      left: 3,
    },
  },
}));

export const ModalBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '40px',
  maxWidth: '424px',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '332px',
  },
  '& > .MuiButton-root': {
    width: '100%',
  },
}));

export const ModalHeadingBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  marginTop: '32px',
}));

export const ModalTextBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
}));
