import { ToastProps } from './Toast.types';
import ToastSnackbar from './ToastSnackbar/ToastSnackbar';
import ToastAlert from './ToastAlert/ToastAlert';

export default function Toast(props: ToastProps) {
  const { alertProps, children, snackbarProps } = props;

  return (
    <ToastSnackbar {...snackbarProps}>
      <ToastAlert {...alertProps}>{children}</ToastAlert>
    </ToastSnackbar>
  );
}
