import { SyntheticEvent, useState } from 'react';
import { trackClickEvent } from '@frontend/tracking';

const useTabs = ({ initialTabIndex }: { initialTabIndex: number }) => {
  const [value, setValue] = useState<number>(initialTabIndex);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== value) {
      const tabButton = event.target as HTMLElement;
      handleTabClick(tabButton?.id, tabButton?.innerText);
    }

    setValue(newValue);
  };

  const handleTabClick = (id?: string, innerText?: string) => {
    trackClickEvent({
      elementId: id,
      elementText: innerText,
    });
  };

  const changeSelectedTab = (newTabIndex: number) => {
    setValue(newTabIndex);
  };

  return {
    handleChange,
    handleTabClick,
    value,
    changeSelectedTab,
  };
};

export default useTabs;
