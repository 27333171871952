import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import {
  ICreateSessionRequest,
  ICreateSessionResponseFailure,
} from './createSession.types';
import axiosClient from '../axiosClient';

const submitCreateSession = (
  { token, loginSource }: ICreateSessionRequest,
  apiUrl: string,
) => {
  const queryParams = loginSource ? `?login_source=${loginSource}` : '';

  return axiosClient.get(
    `${apiUrl}/accounts/user/login/${token}/${queryParams}`,
  );
};

const useCreateSession = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse,
    AxiosError<ICreateSessionResponseFailure>,
    ICreateSessionRequest
  >({
    mutationFn: (createSessionRequest: ICreateSessionRequest) =>
      submitCreateSession(createSessionRequest, apiUrl),
  });
};

export default useCreateSession;
