import { Translate } from '@frontend/translation';
import { Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Step,
  StepContent,
  StepHeadingWrapper,
  StepListWrapper,
  StepNumber,
  Wrapper,
} from './BiddingOnboardingSteps.css';
import { OnboardingStep } from './BiddingOnboardingStep.types';

const BiddingOnboardingSteps = () => {
  const theme = useTheme();
  const intl = useIntl();

  const stepData = [
    {
      order: 1,
      title: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-1.title',
      }),
      text: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-1.text',
      }),
    },
    {
      order: 2,
      title: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-2.title',
      }),
      text: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-2.text',
      }),
    },
    {
      order: 3,
      title: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-3.title',
      }),
      text: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-3.text',
      }),
    },
    {
      order: 4,
      title: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-4.title',
      }),
      text: intl.formatMessage({
        id: 'customer-platform.biddable-listing-page.bidding-onboarding-steps.step-4.text',
      }),
    },
  ] as OnboardingStep[];

  const [activeStep, setActiveStep] = useState(1);
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep(prevActiveStep => {
        if (prevActiveStep >= stepData.length) {
          return 1;
        }
        return prevActiveStep + 1;
      });
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [stepData.length]);

  return (
    <Wrapper>
      <StepHeadingWrapper>
        <Typography variant="h5" color={theme.palette.primary[500]}>
          <Translate id="customer-platform.biddable-listing-page.bidding-onboarding-steps.title" />
        </Typography>
        <Typography variant="sh3" color={theme.palette.primary[500]}>
          <Translate id="customer-platform.biddable-listing-page.bidding-onboarding-steps.subtitle" />
        </Typography>
      </StepHeadingWrapper>
      <StepListWrapper>
        {stepData
          .sort((stepA, stepB) => stepA.order - stepB.order)
          .map(stepContent => {
            return (
              <Step key={stepContent.order}>
                <StepNumber active={stepContent.order <= activeStep}>
                  <Typography variant="p1">{stepContent.order}</Typography>
                </StepNumber>
                <StepContent>
                  <Typography variant="p1" color={theme.palette.primary[500]}>
                    {stepContent.title}
                  </Typography>
                  <Typography variant="p3" color={theme.palette.primary[500]}>
                    {stepContent.text}
                  </Typography>
                </StepContent>
              </Step>
            );
          })}
      </StepListWrapper>
    </Wrapper>
  );
};

export default BiddingOnboardingSteps;
