import { styled } from '@frontend/theme';
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers';

export const DatePicker = styled(MUIDatePicker)(({ theme }) => ({
  // Label
  '& > .MuiInputLabel-root': {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '26px',
    transform: 'translate(16px, 12px) scale(1)',
    '&.MuiInputLabel-animated.MuiFormLabel-filled': {
      transform: 'translate(16px, -15px) scale(1)',
    },
    '&.MuiInputLabel-animated.Mui-focused': {
      transform: 'translate(16px, -15px) scale(1)',
    },
  },

  // HelperText
  '& .MuiFormHelperText-root': {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '26px',
    marginTop: '4px',
    marginRight: '12px',
    marginLeft: '16px',
    color: theme.palette.neutral[700],

    '&.Mui-disabled': {
      color: theme.palette.neutral[300],
    },
    '&.Mui-error': {
      color: theme.palette.error[500],
    },
  },

  // Input Box
  '& > .MuiInputBase-root': {
    borderRadius: 0,
    paddingRight: 0,

    '& > .MuiInputBase-input': {
      maxHeight: '26px',
      padding: '12px 12px 12px 16px',
    },
    '& > .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.neutral[200],
      '& legend': {
        fontSize: '16px',
      },
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.primary[500],
    },
    '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.neutral[100],
    },
    '&.Mui-error > .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error[500],
    },

    // Adornment/Icon
    '& > .MuiInputAdornment-root': {
      marginRight: '6px',
      marginLeft: '6px',
      '& > .MuiIconButton-root': {
        marginRight: 0,
        '& > svg': {
          color: theme.palette.neutral[700],
        },
      },
    },
    '&.Mui-disabled > .MuiInputAdornment-root > .MuiIconButton-root > svg': {
      color: theme.palette.neutral[300],
    },
    '&.Mui-error > .MuiInputAdornment-root > .MuiIconButton-root > svg': {
      color: theme.palette.error[500],
    },
  },
  '& .MuiPickersDay-root:hover': {
    backgroundColor: theme.palette.primary[50],
  },
}));
