import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LOGIN_LINK_SOURCE } from './ProtectedRoute.const';
import { IProtectedRoute } from './ProtectedRoute.types';
import useAuth from '../AuthContext/useAuth';
import PlatformHelmet from '../PlatformHelmet/PlatformHelmet';
import { createQueryParams, generateUrlFromLocation } from '@frontend/routing';

const ProtectedRoute = ({ allowedRoles, fallbackUrl }: IProtectedRoute) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  const search = createQueryParams({
    source: LOGIN_LINK_SOURCE,
    redirectUrl: generateUrlFromLocation(location),
  });

  if (!currentUser || !allowedRoles.includes(currentUser.role)) {
    return <Navigate to={`${fallbackUrl}?${search}`} replace />;
  }
  return (
    <>
      <PlatformHelmet />
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
