import { MUIPhoneFieldSt } from './PhoneField.css';
import { PhoneFieldProps } from './PhoneField.types';
import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';
import { useRef } from 'react';

/**
 * This component has a unique behavior and interaction that require dynamic client-side rendering.
 * It can't be added to the lib/ui-elements library because it will break the server-side rendering (SSR)
 * By keeping this components in customer-platform, we ensure that it is handled independently.
 */
const PhoneField = (props: PhoneFieldProps) => {
  const { formId, ...restProps } = props;
  const initialValue = useRef<string | null | undefined>(props.value);
  const inputRef = useRef<HTMLInputElement>(null);

  const trackFieldEvent = (
    action: FormFieldAction,
    oldValue?: string | null,
    newValue?: string | null,
  ) => {
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: props.id ?? 'unknown-field',
      formFieldAction: action,
      formFieldOldValue: oldValue,
      formFieldNewValue: newValue,
    });
  };

  const handleFocus = () => {
    if (inputRef.current) {
      initialValue.current = inputRef.current.value;
      trackFieldEvent('focus');
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      if (initialValue.current !== inputRef.current.value) {
        trackFieldEvent('update', initialValue.current, inputRef.current.value);
      }
      trackFieldEvent('unfocus');
    }
  };

  return (
    <MUIPhoneFieldSt
      {...restProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputRef={inputRef}
    />
  );
};

export default PhoneField;
