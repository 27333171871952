import {
  Typography,
  TypographyPropsVariantOverrides,
  useTheme,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

interface IInnerTypographyProps {
  dataTestId?: string;
  color?: string;
  message: string;
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}
export default function InnerTypography({
  color,
  message,
  variant,
  dataTestId,
}: IInnerTypographyProps) {
  const theme = useTheme();
  const array = message.split('*');
  const style = {
    color: color !== undefined ? color : theme.palette.neutral[500],
  };

  return array?.map((element, index) => {
    if (index % 2 === 0) {
      return element;
    }
    return (
      <Typography
        key={index}
        component="span"
        style={style}
        variant={variant}
        data-testid={dataTestId}
      >
        {element}
      </Typography>
    );
  });
}
