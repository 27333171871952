import CommonFormProps from './VariantForm.types';
import { HEADER_VARIANTS } from '../../PlatformHeader/PlatformHeader.types';
import RequestForm from '../RequestForm/RequestForm';
import {
  IListingSubmissionData,
  IRequestSubmissionData,
  useCreateListing,
  useCreateRequest,
} from '@frontend/api';
import ListingForm from '../ListingForm/ListingForm';
import { SubmitHandler } from 'react-hook-form';
import FormType from '../utils/FormType.types';
import { identity, pickBy } from 'lodash';
import { useEffect, useRef } from 'react';

export default function VariantForm({ onSubmit, variant }: CommonFormProps) {
  const {
    mutate: mutateRequest,
    isSuccess: isRequestSuccess,
    isPending: isRequestPending,
    error: errorRequest,
    reset: resetRequest,
  } = useCreateRequest();

  const {
    mutate: mutateListing,
    isSuccess: isListingSuccess,
    isPending: isListingPending,
    error: errorListing,
    reset: resetListing,
  } = useCreateListing();

  const isBuyer = variant === HEADER_VARIANTS.BUYER;
  const userVariantPath = isBuyer ? 'request' : 'listing';
  const submitTranslationId = `customer-platform.shared.components.user-header.${userVariantPath}-form.button`;
  const requestFormData = useRef<IRequestSubmissionData>({});
  const listingFormData = useRef<IListingSubmissionData>({});
  const handleFormSubmit: SubmitHandler<
    FormType<IRequestSubmissionData | IListingSubmissionData>
  > = formData => {
    if (isBuyer) {
      requestFormData.current = pickBy(formData, identity);
      mutateRequest(requestFormData.current);
    } else {
      listingFormData.current = pickBy(formData, identity);
      mutateListing(listingFormData.current);
    }
  };

  useEffect(() => {
    if (isRequestSuccess) {
      resetRequest();
      onSubmit();
    }
  }, [onSubmit, resetRequest, isRequestSuccess]);

  useEffect(() => {
    if (isListingSuccess) {
      resetListing();
      onSubmit();
    }
  }, [onSubmit, resetListing, isListingSuccess]);

  if (variant === HEADER_VARIANTS.BUYER) {
    return (
      <RequestForm
        onSubmit={handleFormSubmit}
        isSuccess={isRequestSuccess}
        isPending={isRequestPending}
        submitTranslationId={submitTranslationId}
        errors={errorRequest?.response?.data}
      />
    );
  } else {
    return (
      <ListingForm
        onSubmit={handleFormSubmit}
        isSuccess={isListingSuccess}
        isPending={isListingPending}
        submitTranslationId={submitTranslationId}
        errors={errorListing?.response?.data}
      />
    );
  }
}
