import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
import { DashboardHeaderWrapperProps } from './DashboardHeader.types';

export const ButtonWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const CardCounter = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'flex-start',
});

export const CardCounterWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  width: 'max-content',
});

export const BannerWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const DashboardHeaderWrapper = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'isCardsView',
})<DashboardHeaderWrapperProps>(({ theme, isCardsView }) => {
  const mediaQueries = !isCardsView && {
    [theme.breakpoints.down('desktopM')]: {
      paddingLeft: '32px',
      paddingRight: '32px',
    },

    [theme.breakpoints.down('desktop')]: {
      paddingTop: '40px',
    },

    [theme.breakpoints.down('tabletL')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },

    [theme.breakpoints.down('tablet')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  };

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '100%',
    ...mediaQueries,
  };
});

export const Separator = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  height: '2px',
  width: '100%',
  backgroundColor: theme.palette.primary[500],
}));
