import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface ITabPanelProps {
  'aria-labelledby'?: string;
  children: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, index, value, ...rest } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {<Box>{children}</Box>}
    </div>
  );
}

export default TabPanel;
