import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import axiosClient from '../axiosClient';
import { ApiContext } from '../ApiContext/ApiContext';
import { getQueryKey } from './getBiddableListing.const';
import {
  TGetBiddableListingDataResponse,
  TGetBiddableListingDataResponseFailure,
} from './getBiddableListing.types';

const submitGetBiddableListingData = (apiUrl: string, uuid: string) => {
  return axiosClient.get(`${apiUrl}/trading/listings/${uuid}/published/`);
};

const useGetBiddableListingDataQuery = (uuid: string) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetBiddableListingDataResponse>,
    AxiosError<TGetBiddableListingDataResponseFailure>,
    AxiosResponse<TGetBiddableListingDataResponse>
  >({
    queryFn: () => submitGetBiddableListingData(apiUrl, uuid),
    queryKey: getQueryKey(uuid),
  });
};

export default useGetBiddableListingDataQuery;
