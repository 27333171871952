import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IDestroySessionResponseFailure } from './destroySession.types';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

const submitDestroySession = (apiUrl: string) => {
  return axiosClient.post(`${apiUrl}/accounts/user/logout/`);
};

const useDestroySessionMutation = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<AxiosResponse, AxiosError<IDestroySessionResponseFailure>>(
    {
      mutationFn: () => submitDestroySession(apiUrl),
    },
  );
};

export default useDestroySessionMutation;
