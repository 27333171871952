import { Typography } from '@mui/material';
import { SimplifiedMobileStatusBox } from './SimplifiedMobileStatusBar.css';
import SimplifiedMobileStatusBarProps from './SimplifiedMobileStatusBar.types';
import ProgressBar from '../../ProgressBar/ProgressBar';

function SimplifiedMobileStatusBar({
  progress,
  stageLabel,
  stageNumber,
}: SimplifiedMobileStatusBarProps) {
  return (
    <SimplifiedMobileStatusBox>
      <Typography variant="h6">
        {`Step ${stageNumber.toString()}: ${stageLabel}`}
      </Typography>
      <ProgressBar value={progress} />
    </SimplifiedMobileStatusBox>
  );
}

export default SimplifiedMobileStatusBar;
