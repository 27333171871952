import { styled } from '@frontend/theme';
import { Container as MUIContainer, Box as MUIBox } from '@mui/material';

export const CaptionBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FieldsWrapper = styled(MUIContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '24px',
  padding: 0,

  '& > .MuiFormControl-root': {
    width: '100%',
  },
});

export const FormBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const FormContainer = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  '& > form': {
    width: '100%',
  },
});
