import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import { RiCheckDoubleFill } from '@remixicon/react';
import {
  ButtonWrapper,
  IconButton,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './SuccessModal.css';
import { ISuccessModal } from './SuccessModal.types';
import { RiCloseLine } from '@remixicon/react';
import { useIntl } from 'react-intl';

export default function SuccessModal({
  title,
  text,
  primaryCta,
  secondaryCta,
  onClose,
}: ISuccessModal) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Modal open={true}>
      <ModalBox>
        <ModalHeadingBox>
          {onClose && (
            <IconButton
              id="success-modal.icon-button.close-modal"
              aria-label={intl.formatMessage({
                id: 'customer-platform.shared.close',
              })}
              onClick={onClose}
            >
              <RiCloseLine size={24} color={theme.palette.neutral[500]} />
            </IconButton>
          )}
          <IconWrapper>
            <RiCheckDoubleFill size={98} color={theme.palette.neutral[500]} />
            <RiCheckDoubleFill size={98} color={theme.palette.secondary[500]} />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="p3">{text}</Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <ButtonWrapper>
          {secondaryCta && (
            <Button
              buttonType="secondary"
              id="success-modal.button.secondary"
              size="large"
              onClick={secondaryCta.onClick}
            >
              {secondaryCta.text}
            </Button>
          )}

          {primaryCta && (
            <Button
              buttonType="primary"
              id="success-modal.button.primary"
              size="large"
              onClick={primaryCta.onClick}
            >
              {primaryCta.text}
            </Button>
          )}
        </ButtonWrapper>
      </ModalBox>
    </Modal>
  );
}
