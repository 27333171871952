import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

const SideWrapper = styled(MUIBox)(({ theme }) => ({
  paddingLeft: '64px',
  paddingRight: '64px',

  [theme.breakpoints.down('desktopM')]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },

  [theme.breakpoints.down('tabletL')]: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  [theme.breakpoints.down('tablet')]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

export default SideWrapper;
