import {
  IMAGE_VARIANT,
  useDownloadAllImages,
  useDownloadContainerImage,
} from '@frontend/api';
import { ContainerImagesProps } from './ContainerImages.types';
import { ContainerImagesWrapper } from './ContainerImages.css';
import ImageListWithMagnifier from '@customer-platform-shared/components/ImageListWithMagnifier/ImageListWithMagnifier';

export default function ContainerImages({
  containerId,
  imageList,
  containerNumber,
}: ContainerImagesProps) {
  const { downloadAllImages } = useDownloadAllImages();
  const { downloadContainerImage } = useDownloadContainerImage();

  const genericImageList = imageList.map(image => {
    return {
      uniqueIdentifier: image.uuid,
      name: image?.name || '',
      fileType: image.type,
    };
  });

  return (
    <ContainerImagesWrapper>
      <ImageListWithMagnifier
        uuid={containerId}
        imageList={genericImageList}
        variant={IMAGE_VARIANT.CONTAINER}
        displayAllImages={false}
        displayAllTypes={true}
        downloadAllOnClick={() =>
          downloadAllImages(
            containerId,
            IMAGE_VARIANT.CONTAINER,
            containerNumber,
          )
        }
        downloadSingleImage={downloadContainerImage}
      />
    </ContainerImagesWrapper>
  );
}
