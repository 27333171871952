import { createTheme } from '@mui/material';
import {
  ColorPartial,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from './colors';
import { breakpoints, getTypography } from './theme-variables';
import ClashGroteskWoff from '../../../public/fonts/ClashGrotesk-Variable.woff';
import ClashGroteskWoff2 from '../../../public/fonts/ClashGrotesk-Variable.woff2';
import { shadows } from './shadows';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,@typescript-eslint/no-empty-object-type
  interface PaletteColor extends ColorPartial {}

  interface Palette {
    primary: PaletteColor;
    neutral: PaletteColor;
    tertiary: PaletteColor;
  }

  interface PaletteOptions {
    neutral?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
  }

  interface BreakpointOverrides {
    // Removes the unused breakpoints
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    // Adds the new breakpoints
    mobile: true;
    tablet: true;
    tabletL: true;
    desktop: true;
    desktopM: true;
    desktopL: true;
    desktopXL: true;
    desktopXXL: true;
  }

  interface TypographyVariants {
    display1: TypographyStyleOptions;
    display2: TypographyStyleOptions;
    sh1: TypographyStyleOptions;
    sh2: TypographyStyleOptions;
    sh3: TypographyStyleOptions;
    sh4: TypographyStyleOptions;
    p1: TypographyStyleOptions;
    p2: TypographyStyleOptions;
    p3: TypographyStyleOptions;
    p4: TypographyStyleOptions;
    caption1: TypographyStyleOptions;
    caption2: TypographyStyleOptions;
    caption3: TypographyStyleOptions;
    caption4: TypographyStyleOptions;
  }

  interface TypographyVariantsOptions {
    display1?: TypographyStyleOptions;
    display2?: TypographyStyleOptions;
    sh1?: TypographyStyleOptions;
    sh2?: TypographyStyleOptions;
    sh3?: TypographyStyleOptions;
    sh4?: TypographyStyleOptions;
    p1?: TypographyStyleOptions;
    p2?: TypographyStyleOptions;
    p3?: TypographyStyleOptions;
    p4?: TypographyStyleOptions;
    caption1?: TypographyStyleOptions;
    caption2?: TypographyStyleOptions;
    caption3?: TypographyStyleOptions;
    caption4?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    sh1: true;
    sh2: true;
    sh3: true;
    sh4: true;
    p1: true;
    p2: true;
    p3: true;
    p4: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption4: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    background: {
      default: '#FBFBFB',
      paper: '#FFFFFF',
    },
    primary: colors.primary,
    secondary: colors.secondary,
    tertiary: colors.tertiary,
    neutral: colors.neutral,
    error: colors.error,
    warning: colors.warning,
  },
  typography: {
    fontFamily: '"Clash Grotesk", sans-serif',
    fontSize: 16,
  },
  shadows: shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Clash Grotesk';
          font-style: normal;
          font-display: swap;
          font-weight: 200 700;
          src:
            url(${ClashGroteskWoff2}) format('woff2'),
            url(${ClashGroteskWoff}) format('woff'),
        }
      `,
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          color: colors.neutral[500],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display1: 'h1',
          display2: 'h1',
          sh1: 'h3',
          sh2: 'h3',
          sh3: 'h3',
          sh4: 'h3',
          p1: 'p',
          p2: 'p',
          p3: 'p',
          p4: 'p',
          caption1: 'caption',
          caption2: 'caption',
          caption3: 'caption',
          caption4: 'caption',
        },
      },
    },
  },
});

theme.typography = getTypography(theme);
