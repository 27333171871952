import { Location } from 'react-router-dom';

function generateUrlFromLocation(location: Location) {
  const { pathname, search = '', hash = '' } = location;

  const validSearch = search.startsWith('?') ? search : '';
  const validHash = hash.startsWith('#') ? hash : '';
  return `${pathname}${validSearch}${validHash}`;
}

export default generateUrlFromLocation;
