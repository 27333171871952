import { IOrderPhotosListItem } from './OrderPhotos.types';

const ORDER_PHOTOS_ITEM_LIST = [
  {
    translationId: 'customer-platform.listing-details.order-photos.list.item-1',
    required: true,
  },
  {
    translationId: 'customer-platform.listing-details.order-photos.list.item-2',
    required: true,
  },
  {
    translationId: 'customer-platform.listing-details.order-photos.list.item-3',
    required: true,
  },
  {
    translationId: 'customer-platform.listing-details.order-photos.list.item-4',
    required: true,
  },
] as IOrderPhotosListItem[];

export default ORDER_PHOTOS_ITEM_LIST;
