import { IContainerDetails } from './getContainerDetails.types';
import {
  IContainer,
  IRequestOrder,
} from '../getRequestOrderList/getRequestOrderList.types';
import { mockedRequestOrder } from '../getRequestOrderList/getRequestOrderList.fixture';
import { format } from 'date-fns';

export const mockedContainerDetailsContainer = {
  uuid: 'zzz',
  createdDate: '2024-03-07T10:57:24.467322+00:00',
  containerNumber: 'ABC123',
  sealNumber: 'SEAL1234',
  netWeight: 2.0,
  tare: 3.0,
  total: 5.0,
  documents: [
    {
      uniqueIdentifier: 'aaa',
      documentType: 'container.document.weighbridge_ticket',
      fileType: 'pdf',
    },
  ],
  loadingPhotos: [
    {
      uuid: '0c220fc6-43bd-4272-8c36-3e2022a309d5',
      name: 'img_380e49f9d6ce403687e56d7aaf129848',
      type: 'jpg',
    },
  ],
} as IContainerDetails;

export const mockedContainerDetailsOrder = {
  uuid: 'yyy',
  status: 'order.status.loading',
  publicId: '9X8HJ6',
  quantity: 50000.0,
  shippingTo: 'China',
  cost: 13000.0,
  createdDate: '2024-01-18T14:59:07.603000+00:00',
  billOfLading: {
    consigneeName: 'Mr Consignee',
    consigneeAddress: 'Fake Address 123',
    consigneeAdditionalInformation: '',
    notifyName: 'Mister Consignee',
    notifyAddress: 'Somewhere over the rainbow',
    materialDescription: 'Shiny',
    hsnCode: '110-mock',
    dischargePort: 'Fake Port',
    finalDestination: 'China',
    freeTime: 'Anytime betweesn 2:00-3:00PM',
    additionalRemarks: 'none',
  },
  finalInvoiceUrl: '/documentUrl',
  billOfLadingUrl: '/documentUrl',
  annexViiUrl: '/documentUrl',
  packListUrl: '/documentUrl',
  certificateOfOriginUrl: '/documentUrl',
  greenListContractUrl: '/documentUrl',
  nonRadioactiveCertificateUrl: '/documentUrl',
  containers: [
    {
      uuid: '8c64d83f-0fbc-4d9d-861f-403cd0c875b7',
      createdDate: '2024-03-07T10:57:24.467322+00:00',
      containerNumber: 'ABC',
      sealNumber: 'seal-Number-ABC',
      netWeight: 1500.0,
      tare: 100.0,
      total: 1600.0,
    },
  ],
} as IRequestOrder;

const containersWithoutData: IContainer[] = [
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb97564cd167',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCD1234567',
    sealNumber: '',
    netWeight: null,
    tare: null,
    total: null,
  },
];

export const mockedOrderWithMissingContainersInfo: IRequestOrder = {
  ...mockedRequestOrder,
  containers: containersWithoutData,
};
