import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IEmailVerificationPayload,
  IEmailVerificationResponseFailure,
} from './getEmailVerification.types';

const submitEmailVerification = (token: string, apiUrl: string) => {
  return axiosClient.get(
    `${apiUrl}/accounts/user/email-verification/${token}/`,
  );
};

const useGetEmailVerification = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse,
    AxiosError<IEmailVerificationResponseFailure>,
    IEmailVerificationPayload
  >({
    mutationFn: ({ token }: IEmailVerificationPayload) =>
      submitEmailVerification(token as string, apiUrl),
  });
};

export default useGetEmailVerification;
