import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

import { IUpdateWhatsappConsentError } from './updateWhatsappConsent.types';

const submitWhatsappConsent = (apiUrl: string) => {
  return axiosClient.put(`${apiUrl}/accounts/user/whatsapp-consent/`);
};

const useUpdateWhatsappConsent = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<AxiosResponse, AxiosError<IUpdateWhatsappConsentError>>({
    mutationFn: () => submitWhatsappConsent(apiUrl),
  });
};

export default useUpdateWhatsappConsent;
