import { IMAGE_VARIANT_TYPE } from '@frontend/api';

export const getVariantTitle = (variant: IMAGE_VARIANT_TYPE) => {
  const TITLE_VARIANTS = {
    BIDDING: 'customer-platform.biddable-listing-page.images-title',
    CONTAINER:
      'customer-platform.request-details.order-list.container-drawer.images',
    LISTING: 'customer-platform.listing-details.order-list.photos',
  } as Record<IMAGE_VARIANT_TYPE, string>;

  return TITLE_VARIANTS[variant] || undefined;
};

export const getVariantMaxImageNumber = (
  variant: IMAGE_VARIANT_TYPE,
  isMobile: boolean,
) => {
  const IMAGE_NUMBER_VARIANTS = {
    CONTAINER: isMobile ? 4 : 5,
    LISTING: isMobile ? 3 : 5,
  } as Record<IMAGE_VARIANT_TYPE, number>;

  return IMAGE_NUMBER_VARIANTS[variant] || (isMobile ? 3 : 4);
};
