import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const OrderPhotosDrawerWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '534px',
  [theme.breakpoints.down('tabletL')]: {
    width: '100%',
  },
}));
