import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const Container = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  width: '100%',
}));

export const DropzoneArea = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'isDragActive',
})<{ isDragActive: boolean }>(({ theme, isDragActive }) => {
  const svgAsString = `<svg xmlns="http://www.w3.org/2000/svg">
    <rect
      width="100%"
      height="100%"
      fill="none"
      stroke="${isDragActive ? theme.palette.primary[500] : theme.palette.primary[200]}FF"
      stroke-dasharray="6, 14"
      stroke-dashoffset="2"
      stroke-linecap="square"
      stroke-width="2"
    />
  </svg>`;

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    cursor: 'pointer',
    backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgAsString)}")`,
    transition: 'border-color 0.3s ease',
  };
});

export const DropzoneTextWrapper = styled(MUIBox)(() => ({
  textAlign: 'center',
  '& span': {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

export const FileHelperContainer = styled(MUIBox)(() => ({
  display: 'flex',
  flexFlow: 'column',
}));

export const FileSelectorWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  justifyContent: 'space-between',
  padding: '4px 0 8px',
}));

export const FileSeparator = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '1px',
  borderRadius: '4px',
  background: theme.palette.neutral[50],
}));
