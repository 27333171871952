import { RequestDocumentsProps } from './RequestDocuments.types';
import { MainWrapper } from './RequestDocuments.css';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import DocumentList from '../DocumentList';
import { IDocumentContext } from '@frontend/api';

export default function RequestDocuments({ request }: RequestDocumentsProps) {
  const documentContextList: IDocumentContext[] = request.documents
    ? request.documents.map(document => {
        return {
          document,
          parentRecordPublicId: request.publicId,
          parentRecordId: request.uuid,
          parentRecordType: 'request',
        };
      })
    : [];
  return (
    documentContextList.length !== 0 && (
      <MainWrapper>
        <Typography variant="p1">
          <Translate id="customer-platform.request-details.order-card.documents" />
        </Typography>
        <DocumentList documentContextList={documentContextList} />
      </MainWrapper>
    )
  );
}
