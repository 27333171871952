import { styled } from '@frontend/theme';
import { LinearProgress as MUILinearProgress } from '@mui/material';

const ProgressBarSt = styled(MUILinearProgress)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.tertiary[500],
  '& > .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.secondary[500],
  },
}));

export default ProgressBarSt;
