import { Translate } from '@frontend/translation';
import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import { RiUserAddLine } from '@remixicon/react';
import {
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './CreateAdditionalRoleModal.css';
import { ICreateAdditionalRoleModal } from './CreateAdditionalRoleModal.types';
import { useAuth } from '@frontend/auth';
import { ButtonWrapper } from '../InfoModal/InfoModal.css';
import { useUpdateAccountToBuyerAndSeller } from '@frontend/api';
import { useEffect, useState } from 'react';

export function CreateAdditionalRoleModal({
  isOpen,
  newRoleName,
  onClose,
  onAccountUpdated,
}: ICreateAdditionalRoleModal) {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { mutate: updateAccount, isSuccess: updateAccountSuccess } =
    useUpdateAccountToBuyerAndSeller();

  useEffect(() => {
    if (updateAccountSuccess) {
      onClose();
      onAccountUpdated();
    }
  }, [onAccountUpdated, onClose, updateAccountSuccess]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalBox>
        <ModalHeadingBox>
          <IconWrapper>
            <RiUserAddLine size={77.28} color={theme.palette.neutral[500]} />
            <RiUserAddLine size={77.28} color={theme.palette.secondary[500]} />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">
              <Translate
                id="customer-platform.shared.create-additional-role.heading"
                values={{ ROLE_NAME: newRoleName }}
              />
            </Typography>
            <Typography variant="p3">
              <Translate
                id="customer-platform.shared.create-additional-role.subheading"
                values={{
                  ROLE_NAME: newRoleName,
                  USER_EMAIL: currentUser?.email,
                }}
              />
            </Typography>
          </ModalTextBox>

          <ButtonWrapper>
            <Button
              buttonType="secondary"
              id="create-buyer-seller-account-modal.button.secondary"
              size="large"
              onClick={() => {
                onClose();
              }}
            >
              <Translate id="customer-platform.shared.create-additional-role.secondary" />
            </Button>

            <Button
              buttonType="primary"
              id="create-buyer-seller-account-modal.button.primary"
              size="large"
              disabled={isProcessing}
              isSubmitting={isProcessing}
              onClick={() => {
                setIsProcessing(true);
                updateAccount();
              }}
            >
              <Translate
                id="customer-platform.shared.create-additional-role.cta"
                values={{
                  ROLE_NAME: newRoleName,
                }}
              />
            </Button>
          </ButtonWrapper>
        </ModalHeadingBox>
      </ModalBox>
    </Modal>
  );
}

export default CreateAdditionalRoleModal;
