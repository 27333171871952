import { forwardRef } from 'react';
import { AlertProps, Typography } from '@mui/material';
import { MUIAlertSt } from './ToastAlert.css';
import { ToastAlertProps } from './ToastAlert.types';
import { RiAlertLine } from '@remixicon/react';
import { RiCheckDoubleLine } from '@remixicon/react';
import { RiCloseLine } from '@remixicon/react';
import { RiErrorWarningLine } from '@remixicon/react';
import IconButton from '../../IconButton/IconButton';
import { RiInformationLine } from '@remixicon/react';
import ToastAlertTitle from '../ToastAlertTitle/ToastAlertTitle';

const ToastAlert = forwardRef(function (
  props: ToastAlertProps,
  ref?: AlertProps['ref'],
) {
  const { children, message, onClose, title, ...rest } = props;
  return (
    <MUIAlertSt
      action={
        onClose && (
          <IconButton
            id={`${props.severity}-toast-alert.icon-button.close`}
            onClick={onClose}
          >
            <RiCloseLine size={24} />
          </IconButton>
        )
      }
      ref={ref}
      iconMapping={{
        error: <RiErrorWarningLine size={24} />,
        info: <RiInformationLine size={24} />,
        success: <RiCheckDoubleLine size={24} />,
        warning: <RiAlertLine size={24} />,
      }}
      onClose={onClose}
      {...rest}
    >
      {title && <ToastAlertTitle>{title}</ToastAlertTitle>}
      {message && <Typography variant="p3">{message}</Typography>}
      {children}
    </MUIAlertSt>
  );
});
export default ToastAlert;
