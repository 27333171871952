import { styled } from '@frontend/theme';
import { TabPanel as BaseTabPanel } from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const LocationEventTabsWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
});

export const LocationEventTabPanel = styled(BaseTabPanel)({
  width: '100%',
});
