import { ApiContextProvider } from '@frontend/api';
import { AuthContextProvider, CheckExistingSession } from '@frontend/auth';
import { ThemeProvider } from '@frontend/theme';
import { TranslationProvider } from '@frontend/translation';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';

export function App() {
  return (
    <TranslationProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApiContextProvider apiUrl={import.meta.env.VITE_METYCLE_API_URL}>
            <AuthContextProvider>
              <HelmetProvider>
                <BrowserRouter>
                  <CheckExistingSession>
                    <Router />
                  </CheckExistingSession>
                </BrowserRouter>
              </HelmetProvider>
            </AuthContextProvider>
          </ApiContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </TranslationProvider>
  );
}

export default App;
