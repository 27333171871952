import { DownloadAllDocumentsProps } from './DownloadAllDocuments.types';
import { Button } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';
import { useGetAllDocument } from '@frontend/api';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export default function DownloadAllDocuments({
  documentContextList,
  id = 'button.download-all-documents',
}: DownloadAllDocumentsProps) {
  const { downloadAllDocument } = useGetAllDocument();
  const intl = useIntl();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const handleDownloadAll = async () => {
    const { parentRecordId, parentRecordType, parentRecordPublicId } =
      documentContextList[0];
    setIsDownloading(true);
    await downloadAllDocument(
      parentRecordId,
      parentRecordType,
      parentRecordPublicId,
    );
    setIsDownloading(false);
  };

  if (!documentContextList.length) {
    return;
  }

  return (
    <Button
      buttonType="linked"
      id={id}
      size="large"
      onClick={handleDownloadAll}
      isSubmitting={isDownloading}
      submittingCaption={intl.formatMessage({
        id: 'customer-platform.shared.components.button.submitting-caption',
      })}
    >
      <Translate id="customer-platform.request-details.order-card.download-all" />
    </Button>
  );
}
