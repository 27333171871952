import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { trackFormSubmitEvent } from '@frontend/tracking';

interface FormTrackingProps {
  formId: string;
  formErrors: unknown;
  isSuccess: boolean;
}
export const useFormTracking = ({
  formId,
  formErrors,
  isSuccess,
}: FormTrackingProps) => {
  useEffect(() => {
    if (!isEmpty(formErrors)) {
      trackFormSubmitEvent({
        formId,
        formName: formId,
        formStatus: 'error',
      });
    }
  }, [formErrors, formId]);

  useEffect(() => {
    if (isSuccess) {
      trackFormSubmitEvent({
        formId,
        formName: formId,
        formStatus: 'success',
      });
    }
  }, [isSuccess, formId]);
};
