import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ButtonsBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  width: '100%',
  '& > .MuiButtonBase-root': {
    width: '100%',
  },
  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
  },
}));

export const IconWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  position: 'relative',
  '& > svg': {
    '&:first-of-type': {
      zIndex: 1,
    },
    '&:last-of-type': {
      position: 'absolute',
      top: '3.22px',
      left: '3.22px',
    },
  },
}));

export const ModalBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '36px',
  padding: '30px 40px',
  maxWidth: '533px',
  width: '100%',
  [theme.breakpoints.down('tabletL')]: {
    padding: '30px',
    maxWidth: '420px',
  },
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '332px',
  },
}));

export const ModalTextBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '14px',
  maxWidth: '320px',
  width: '100%',
  [theme.breakpoints.down('tabletL')]: {
    gap: '10px',
  },
}));

export const ModalHeadingBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '14px',
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.down('tabletL')]: {
    gap: '8px',
  },
}));
