import { Box as MUIBox, styled } from '@mui/material';

export const DocumentContainer = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  gap: '4px',
  padding: '20px 16px 20px 16px',
  border: `1px solid ${theme.palette.primary[100]}`,
  '&:hover, &:active': {
    border: `1px solid ${theme.palette.primary[200]}`,
  },
  '&:active': {
    backgroundColor: theme.palette.primary[50],
  },
}));

export const DocumentTitle = styled(MUIBox)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
