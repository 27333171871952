import { useState } from 'react';

const useStepsWizard = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const goToNextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep(prevStep => (prevStep - 1 >= 0 ? prevStep - 1 : 0));
  };
  return {
    currentStep,
    goToNextStep,
    goToPreviousStep,
  };
};

export default useStepsWizard;
