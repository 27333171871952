import { styled } from '@frontend/theme';
import {
  RouterLink as BaseLink,
  SideWrapper as BaseSideWrapper,
} from '@frontend/ui-elements';
import {
  AppBar as MUIAppBar,
  Box as MUIBox,
  Toolbar as MUIToolbar,
} from '@mui/material';

export const AppBar = styled(MUIAppBar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.common.white,
  width: '100%',
  boxShadow: 'none',
}));

export const Container = styled(BaseSideWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: theme.breakpoints.values.desktopL,
  width: '100%',
  paddingTop: '32px',
  paddingBottom: '32px',

  [theme.breakpoints.down('desktop')]: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

export const HeaderDrawerWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '534px',
  [theme.breakpoints.down('tabletL')]: {
    width: '100%',
  },
}));

export const Link = styled(BaseLink)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
}));

export const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative',
  padding: 0,
  minHeight: 'auto',
  width: '100%',
  '& .metycle-logo': {
    width: '130px',
    height: '45.2px',
    [theme.breakpoints.down('tabletL')]: {
      width: '100px',
      height: '34.748px',
    },
  },
}));
