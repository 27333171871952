import { forwardRef, Ref } from 'react';
import { useTheme } from '@mui/material';
import { MUIAutocompleteSt, MUIPopperSt } from './Autocomplete.css';
import {
  AutocompleteOption,
  IAutocompleteProps,
  TOnChange,
} from './Autocomplete.types';
import { RiArrowDownSLine } from '@remixicon/react';
import { RiCloseLine } from '@remixicon/react';
import TextField from '../TextField/TextField';
import { trackFormFieldEvent } from '@frontend/tracking';

const Autocomplete = forwardRef(function (
  props: IAutocompleteProps,
  ref?: Ref<HTMLDivElement>,
) {
  const theme = useTheme();
  const {
    error,
    helperText,
    label,
    options,
    required,
    value,
    formId,
    onChange,
    ...rest
  } = props;

  const handleChange: TOnChange = (...params) => {
    const reason = params[2];
    if (reason === 'clear') {
      trackFormFieldEvent({
        formId: formId ?? 'unknown-form',
        formField: props.id ?? 'unknown-field',
        formFieldAction: 'clear',
      });
    }
    onChange && onChange(...params);
  };
  return (
    <MUIAutocompleteSt
      {...rest}
      ref={ref}
      clearIcon={<RiCloseLine size={24} color={theme.palette.neutral[500]} />}
      popupIcon={
        <RiArrowDownSLine size={24} color={theme.palette.neutral[500]} />
      }
      onChange={handleChange}
      PopperComponent={MUIPopperSt}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          required={required}
          formId={formId}
        />
      )}
      options={options}
      getOptionKey={(option: AutocompleteOption) => option?.value.toString()}
      getOptionLabel={(option: AutocompleteOption) => option?.label.toString()}
      isOptionEqualToValue={(
        optionA: AutocompleteOption,
        optionB: AutocompleteOption,
      ) => {
        return (
          optionB === undefined ||
          optionA?.value?.toString() === (optionB?.value ?? optionB)?.toString()
        );
      }}
      value={value && value !== '' ? value : null}
    />
  );
});

export default Autocomplete;
