import {
  ISellerOrderFileUploadSubmissionData,
  useDeleteSellerOrderFiles,
  useDownloadSellerOrderFiles,
  useUploadSellerOrderFiles,
} from '@frontend/api';
import { DrawerWithHeader } from '@frontend/ui-elements';
import { identity, pickBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { OrderPhotosDrawerWrapper } from './OrderPhotosDrawer.css';
import { OrderPhotosDrawerProps } from './OrderPhotosDrawer.types';
import FileManagerForm from '@customer-platform-shared/components/Forms/FileManagerForm/FileManagerForm';
import { FileManagerFormRef } from '@customer-platform-shared/components/Forms/FileManagerForm/FileManagerForm.types';
import InfoModal from '@customer-platform-shared/components/Modals/InfoModal/InfoModal';
import useModal from '@customer-platform-shared/components/Modals/useModal/useModal';
import FormType from '@customer-platform-shared/components/Forms/utils/FormType.types';

const OrderPhotosDrawer = ({
  orderId,
  alreadyUploadedFiles,
  onSuccess,
}: OrderPhotosDrawerProps) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [pendingToDelete, setPendingToDelete] = useState<string[]>([]);

  const { openInfoModal, closeModal, isInfoModalOpen } = useModal();
  const intl = useIntl();
  const navigate = useNavigate();
  const isPhotoManagerURL = useMatch(PLATFORM_ROUTES.listingOrderPhotos);

  const { uuid: listingId, orderId: sellerOrderId } = useParams();

  const isOpen = !!isPhotoManagerURL && sellerOrderId === orderId;

  const formRef = useRef<FileManagerFormRef | null>(null);
  const sellerOrderFilesFormData =
    useRef<ISellerOrderFileUploadSubmissionData>();

  const { downloadSingleSellerOrderFile, downloadAllSellerOrderFiles } =
    useDownloadSellerOrderFiles('photos', orderId);
  const { mutate: deleteFromRemote, isSuccess: isDeleteSuccess } =
    useDeleteSellerOrderFiles('photos', orderId);
  const {
    mutate: uploadFiles,
    isSuccess: isUploadSuccess,
    isPending: isUploadPending,
    error: uploadError,
    reset: uploadReset,
  } = useUploadSellerOrderFiles('photos', orderId);

  const handleSetHasUnsavedChanges = (value: boolean) => {
    setHasUnsavedChanges(value);
  };

  const handleOnSave: SubmitHandler<
    FormType<ISellerOrderFileUploadSubmissionData>
  > = formData => {
    if (pendingToDelete.length !== 0) {
      deleteFromRemote({ uuids: pendingToDelete });
    }

    if (Object.values(formData).filter(entry => entry !== undefined).length) {
      sellerOrderFilesFormData.current = pickBy(formData, identity);
      uploadFiles(sellerOrderFilesFormData.current);
    }
  };

  const handlePendingToDeleteList = (uniqueIdentifier: string) => {
    pendingToDelete.includes(uniqueIdentifier)
      ? setPendingToDelete(
          pendingToDelete.filter(id => {
            return id !== uniqueIdentifier;
          }),
        )
      : setPendingToDelete([...pendingToDelete, uniqueIdentifier]);
  };

  const handleOnClose = () => {
    if (hasUnsavedChanges || pendingToDelete.length !== 0) {
      return openInfoModal();
    }
    handleCloseDrawer();
  };

  const handleCloseDrawer = () => {
    uploadReset();
    setHasUnsavedChanges(false);
    setPendingToDelete([]);
    closeModal();
    const sellerOrderPath = generatePath(PLATFORM_ROUTES.listingOrderDetails, {
      uuid: listingId as string,
      orderId,
    });

    navigate(sellerOrderPath, { replace: true });
  };

  useEffect(() => {
    if (isUploadSuccess || isDeleteSuccess) {
      setHasUnsavedChanges(false);
      setPendingToDelete([]);
      onSuccess();
      handleCloseDrawer();
    }
    // onSuccess and handleCloseDrawer cannot be added into the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadSuccess, isDeleteSuccess]);

  const invokeSave = () => {
    if (formRef.current) {
      formRef.current.invokeSubmit();
      closeModal();
    }
  };

  return (
    <DrawerWithHeader
      headerLine={intl.formatMessage({
        id: 'customer-platform.listing-details.order-photos.drawer.heading',
      })}
      id="photo-manager.form-drawer"
      onClose={handleOnClose}
      open={isOpen}
    >
      <OrderPhotosDrawerWrapper>
        <FileManagerForm
          alreadyUploadedFiles={alreadyUploadedFiles}
          errors={uploadError?.response?.data}
          isSuccess={isUploadSuccess}
          isPending={isUploadPending}
          fileVariant="photos"
          hasUnsavedChanges={handleSetHasUnsavedChanges}
          deleteList={pendingToDelete}
          handleDeleteList={handlePendingToDeleteList}
          onDownloadSingle={downloadSingleSellerOrderFile}
          onDownloadAll={downloadAllSellerOrderFiles}
          onSubmit={handleOnSave}
          ref={formRef}
        />

        {isInfoModalOpen && (
          <InfoModal
            title={intl.formatMessage({
              id: 'customer-platform.listing-details.order-photos.modal.title',
            })}
            text={intl.formatMessage({
              id: 'customer-platform.listing-details.order-photos.modal.text',
            })}
            primaryCta={{
              text: intl.formatMessage({
                id: 'customer-platform.listing-details.order-photos.modal.cta.primary',
              }),
              onClick: () => {
                invokeSave();
              },
            }}
            secondaryCta={{
              text: intl.formatMessage({
                id: 'customer-platform.listing-details.order-photos.modal.cta.secondary',
              }),
              onClick: handleCloseDrawer,
            }}
            onClose={closeModal}
          />
        )}
      </OrderPhotosDrawerWrapper>
    </DrawerWithHeader>
  );
};

export default OrderPhotosDrawer;
