import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { VesselCardWrapper, VesselRowWrapper } from './VesselList.css';
import {
  VesselDataItemProps,
  VesselListProps,
  VesselTimeDataItemProps,
} from './VesselList.types';
import { formatUnixTimestampToDateWithSpaces } from '@frontend/utils';

const VesselList = ({ vessels }: VesselListProps) => {
  const intl = useIntl();

  return (
    vessels.length > 0 &&
    vessels.map(vessel => {
      const {
        name,
        voyage,
        loadingLocationName,
        dischargeLocationName,
        expectedTimeOfArrival,
        expectedTimeOfDeparture,
        actualTimeOfArrival,
        actualTimeOfDeparture,
      } = vessel;

      const VesselDataItem = ({
        fieldTranslationId,
        fieldValue,
      }: VesselDataItemProps) => {
        return (
          <VesselRowWrapper>
            <Typography variant="p4">
              {intl.formatMessage({
                id: `customer-platform.request-details.order-list.container-drawer.container-tracking.location-event-tabs.vessel-tab.vessel-list.${fieldTranslationId}`,
              })}
            </Typography>
            <Typography variant="p4">{fieldValue ?? '-'}</Typography>
          </VesselRowWrapper>
        );
      };

      const VesselTimeDataItem = ({
        fieldTranslationId,
        expectedTimestamp,
        actualTimestamp,
      }: VesselTimeDataItemProps) => {
        const timestamp =
          actualTimestamp !== null ? actualTimestamp : expectedTimestamp;
        const fieldValue =
          timestamp !== null
            ? formatUnixTimestampToDateWithSpaces(timestamp, true)
            : intl.formatMessage({
                id: 'customer-platform.request-details.order-list.container-drawer.container-tracking.location-event-tabs.vessel-tab.vessel-list.to-be-announced',
              });

        return (
          <VesselDataItem
            fieldTranslationId={
              actualTimestamp !== null
                ? `actual-${fieldTranslationId}`
                : `expected-${fieldTranslationId}`
            }
            fieldValue={fieldValue}
          />
        );
      };

      return (
        <VesselCardWrapper key={name}>
          <VesselRowWrapper>
            <Typography variant="p2">{name}</Typography>
          </VesselRowWrapper>

          <VesselDataItem fieldTranslationId={'voyage'} fieldValue={voyage} />
          <VesselDataItem
            fieldTranslationId={'loading'}
            fieldValue={loadingLocationName}
          />
          <VesselDataItem
            fieldTranslationId={'discharge'}
            fieldValue={dischargeLocationName}
          />

          <VesselTimeDataItem
            fieldTranslationId={'time-departure'}
            expectedTimestamp={expectedTimeOfDeparture}
            actualTimestamp={actualTimeOfDeparture}
          />
          <VesselTimeDataItem
            fieldTranslationId={'time-arrival'}
            expectedTimestamp={expectedTimeOfArrival}
            actualTimestamp={actualTimeOfArrival}
          />
        </VesselCardWrapper>
      );
    })
  );
};

export default VesselList;
