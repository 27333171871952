export default function PdfIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
    >
      <g filter="url(#filter0_ii_1148_15209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55 20C46.1634 20 39 27.1634 39 36V204C39 212.837 46.1634 220 55 220H185C193.837 220 201 212.837 201 204V70L151 20H55Z"
          fill="#FA4E4E"
        />
      </g>
      <path
        d="M152.481 154.693C145.732 154.192 139.235 151.686 133.986 147.175C123.739 149.43 113.99 152.687 104.242 156.698C96.495 170.482 89.2463 177.5 82.9975 177.5C81.7475 177.5 80.2488 177.25 79.2488 176.497C76.4988 175.245 75 172.487 75 169.731C75 167.475 75.5 161.21 99.2437 150.933C104.744 140.908 108.991 130.633 112.491 119.857C109.491 113.842 102.994 99.0547 107.491 91.5372C108.991 88.7797 111.991 87.2759 115.241 87.5272C117.74 87.5272 120.239 88.7797 121.739 90.7847C124.989 95.2959 124.739 104.82 120.489 118.855C124.489 126.363 129.728 133.141 135.985 138.905C141.235 137.901 146.482 137.15 151.731 137.15C163.479 137.4 165.229 142.913 164.979 146.172C164.979 154.693 156.73 154.693 152.481 154.693ZM82.4987 170.231L83.2487 169.981C86.7475 168.728 89.4962 166.222 91.4962 162.963C87.7462 164.467 84.7475 166.973 82.4987 170.232V170.231ZM115.74 95.0459H114.99C114.74 95.0459 114.24 95.0459 113.99 95.2959C112.99 99.5559 113.74 104.067 115.49 108.077C116.99 103.817 116.99 99.3059 115.74 95.0459ZM117.49 131.386L117.24 131.886L116.99 131.636C114.74 137.401 112.24 143.165 109.491 148.678L109.991 148.428V148.928C115.549 146.913 121.225 145.239 126.987 143.916L126.737 143.666H127.487C123.737 139.906 120.239 135.646 117.49 131.386ZM151.481 144.668C149.231 144.668 147.232 144.668 144.982 145.168C147.482 146.422 149.982 146.923 152.481 147.175C154.231 147.425 155.981 147.175 157.48 146.673C157.48 145.921 156.48 144.668 151.481 144.668Z"
        fill="white"
      />
      <path
        d="M196.309 65.3125H155.688L201 110.625V70L196.309 65.3125Z"
        fill="url(#paint0_linear_1148_15209)"
      />
      <path
        d="M167 70L201 70L151 20L151 54C151 62.8366 158.163 70 167 70Z"
        fill="#FDB8B8"
      />
      <defs>
        <filter
          id="filter0_ii_1148_15209"
          x="39"
          y="19"
          width="162"
          height="202"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1148_15209"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_1148_15209"
            result="effect2_innerShadow_1148_15209"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1148_15209"
          x1="165.844"
          y1="55.1563"
          x2="211.156"
          y2="100.469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
