import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import axiosClient from '../axiosClient';
import { ApiContext } from '../ApiContext/ApiContext';
import { getQueryKey } from './getContainerTrackingData.const';
import {
  TGetContainerTrackingDataResponse,
  TGetContainerTrackingDataResponseFailure,
} from './getContainerTrackingData.types';

const submitGetContainerTrackingData = (
  apiUrl: string,
  containerId: string,
) => {
  return axiosClient.get(
    `${apiUrl}/orders/containers/${containerId}/tracking-data/`,
  );
};

const useGetContainerTrackingDataQuery = (containerId: string) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetContainerTrackingDataResponse>,
    AxiosError<TGetContainerTrackingDataResponseFailure>,
    AxiosResponse<TGetContainerTrackingDataResponse>
  >({
    queryFn: () => submitGetContainerTrackingData(apiUrl, containerId),
    queryKey: getQueryKey(containerId),
  });
};

export default useGetContainerTrackingDataQuery;
