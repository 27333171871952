import { styled } from '@frontend/theme';
import { ProgressBar as BaseProgressBar } from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const WizardContent = styled(MUIBox)({});

export const WizardHeading = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const WizardProgressBar = styled(BaseProgressBar)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[50],
  '& > .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary[500],
  },
}));

export const Wrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  backgroundColor: theme.palette.common.white,
  padding: '20px',

  [theme.breakpoints.down('tablet')]: {
    padding: '20px 16px',
  },
}));
