import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetCurrentUserResponse,
  IGetCurrentUserResponseFailure,
} from './getCurrentUser.types';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { getQueryKey } from './getCurrentUser.const';

const submitGetCurrentUser = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/accounts/user/info/`);
};
const useGetCurrentUser = () => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<IGetCurrentUserResponse>,
    AxiosError<IGetCurrentUserResponseFailure>,
    AxiosResponse<IGetCurrentUserResponse>
  >({
    queryFn: () => submitGetCurrentUser(apiUrl),
    queryKey: getQueryKey(),
  });
};
export default useGetCurrentUser;
