import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getQueryKey } from './getListingOrderList.util';
import {
  IGetListingOrderListResponseFailure,
  TGetListingOrderListResponse,
} from './getListingOrderList.types';

const submitGetOrderList = (apiUrl: string, listingId: string) => {
  return axiosClient.get(`${apiUrl}/orders/seller?listing-uuid=${listingId}`);
};

const useGetListingOrderListQuery = (listingId: string) => {
  const { apiUrl } = useContext(ApiContext);

  return useQuery<
    AxiosResponse<TGetListingOrderListResponse>,
    AxiosError<IGetListingOrderListResponseFailure>,
    AxiosResponse<TGetListingOrderListResponse>
  >({
    queryFn: () => submitGetOrderList(apiUrl, listingId),
    queryKey: getQueryKey(listingId),
  });
};

export default useGetListingOrderListQuery;
