import { useEffect, useState } from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarContent,
  SnackbarProgressBar,
} from './ToastSnackbar.css';
import { ToastSnackbarProps } from './ToastSnackbar.types';

export default function ToastSnackbar(props: ToastSnackbarProps) {
  const { alertType, autoHideDuration, children, open, ...rest } = props;
  const [progress, setProgress] = useState(0);
  const duration = autoHideDuration ?? 0;
  const extraTime = 500; // Extra time to appreciate full progress bar

  useEffect(() => {
    if (open) {
      const intervalDuration = 10;
      const updateProgress = () => {
        setProgress(prevProgress => {
          const newProgress =
            prevProgress + (intervalDuration / duration) * 100;
          return newProgress >= 100 ? 100 : newProgress;
        });
      };

      const interval = setInterval(updateProgress, intervalDuration);
      return () => {
        clearInterval(interval);
        setProgress(0);
      };
    }
  }, [duration, open]);

  return (
    <MUISnackbar
      autoHideDuration={
        autoHideDuration ? autoHideDuration + extraTime : autoHideDuration
      }
      open={open}
      {...rest}
    >
      <SnackbarContent
        action={
          duration && (
            <SnackbarProgressBar value={progress} alertType={alertType} />
          )
        }
        message={children}
      />
    </MUISnackbar>
  );
}
