import { Coordinates } from '@frontend/api';
import { CrossingResult, ProcessedCoordinates } from './LocationMap.types';

export class RouteProcessor {
  private coordinates: Coordinates[];
  private currentLocation: Coordinates;
  private processedCoordinates: ProcessedCoordinates[];

  constructor(coordinates: Coordinates[], currentLocation: Coordinates) {
    this.coordinates = coordinates;
    this.currentLocation = currentLocation;
    this.processedCoordinates = this.processCoordinates();
  }

  private normalizeLongitude(longitude: number): number {
    return ((((longitude + 180) % 360) + 360) % 360) - 180;
  }

  private crossesInternationalDateLine(
    long1: number,
    long2: number,
  ): CrossingResult {
    long1 = this.normalizeLongitude(long1);
    long2 = this.normalizeLongitude(long2);

    const deltaLong = Math.abs(long1 - long2);
    if (deltaLong > 180) {
      const direction = long1 > long2 ? 'left' : 'right';
      return { crosses: true, direction };
    }
    return { crosses: false };
  }

  private changeCoordinatesBasedOnIDL(
    coordinate: Coordinates,
    direction: 'left' | 'right',
  ): Coordinates {
    const newLong =
      direction === 'left' ? coordinate[1] + 360 : coordinate[1] - 360;
    return [coordinate[0], newLong];
  }

  private processCoordinates(): ProcessedCoordinates[] {
    const processedCoordinates: ProcessedCoordinates[] = [];

    processedCoordinates.push({
      oldCoordinates: this.coordinates[0],
      newCoordinates: this.coordinates[0],
    });

    let crossesIDL: CrossingResult = { crosses: false };

    for (let i = 0; i < this.coordinates.length - 1; i++) {
      const long1 = this.coordinates[i][1];
      const long2 = this.coordinates[i + 1][1];

      const result = crossesIDL.crosses
        ? crossesIDL
        : this.crossesInternationalDateLine(long1, long2);
      if (result.crosses) {
        crossesIDL = result;
        processedCoordinates.push({
          oldCoordinates: this.coordinates[i + 1],
          newCoordinates: this.changeCoordinatesBasedOnIDL(
            this.coordinates[i + 1],
            crossesIDL.direction as 'left' | 'right',
          ),
        });
      } else {
        processedCoordinates.push({
          oldCoordinates: this.coordinates[i + 1],
          newCoordinates: this.coordinates[i + 1],
        });
      }
    }

    return processedCoordinates;
  }

  public splitProcessedRoute(): [Coordinates[], Coordinates[]] {
    const centralIndex = this.processedCoordinates.findIndex(coord =>
      this.sameCoordinates(coord.oldCoordinates, this.currentLocation),
    );

    if (centralIndex === -1) {
      throw new Error('Central element not found in the route.');
    }

    const firstPart = this.processedCoordinates
      .slice(0, centralIndex + 1)
      .map(coord => coord.newCoordinates);
    const secondPart = this.processedCoordinates
      .slice(centralIndex)
      .map(coord => coord.newCoordinates);

    return [firstPart, secondPart];
  }

  public getProcessedCentralPoint(): Coordinates | undefined {
    const centralPoint = this.processedCoordinates.find(coord =>
      this.sameCoordinates(coord.oldCoordinates, this.currentLocation),
    );
    return centralPoint?.newCoordinates;
  }

  public getProcessedRoute(): Coordinates[] {
    return this.processedCoordinates.map(coord => coord.newCoordinates);
  }

  public getProcessedCoordinate(oldCoordinate: Coordinates): Coordinates {
    return this.processedCoordinates.find(coord =>
      this.sameCoordinates(coord.oldCoordinates, oldCoordinate),
    )?.newCoordinates as Coordinates;
  }

  public sameCoordinates(coord1: Coordinates, coord2: Coordinates): boolean {
    const tolerance = 0.001;
    const latDifference = Math.abs(coord1[0] - coord2[0]);
    const lonDifference = Math.abs(coord1[1] - coord2[1]);
    return latDifference < tolerance && lonDifference < tolerance;
  }
}
