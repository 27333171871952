import { ITabProps } from './Tab.types';
import { TabSt } from './Tab.css';
import { Typography } from '@mui/material';

export default function Tab({
  label,
  component = 'h3',
  variant = 'p4',
  ...rest
}: ITabProps) {
  return (
    <TabSt
      label={
        <Typography component={component} variant={variant}>
          {label}
        </Typography>
      }
      {...rest}
    />
  );
}
