import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const FileDetailsWrapper = styled(MUIBox)({
  flex: '1',
});

export const UploadedFileWrapper = styled(MUIBox)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '8px',
  padding: '16px 0',
});
