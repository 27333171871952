import * as Sentry from '@sentry/react';

export const captureError = (error: unknown) => {
  const environment: string = import.meta.env.VITE_ENVIRONMENT || undefined;
  if (environment === 'prod') {
    Sentry.captureException(error);
  } else {
    console.error('Development error log: ', error);
  }
};
