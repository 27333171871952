import {
  DESTINATION_ADDRESS_INCOTERMS,
  INCOTERMS,
  SHIPPING_PORT_INCOTERMS,
} from '@frontend/api';
import { isValidDate } from '@frontend/utils';
import { formatISO, isDate } from 'date-fns';
import * as yup from 'yup';
import { IBiddingFormSchemaTranslations } from './BiddingForm.types';
import { MIN_QUANTITY_REQUIRED_MT } from '@customer-platform-shared//components/Forms/Forms.const';

function maxNumbersAfterDecimalValidation(number?: number) {
  if (number === undefined) {
    return true;
  }
  return /^\d+(\.\d{0,5})?$/.test(number.toString());
}

function dateIsNotPast(date: string | undefined) {
  return !date || (isValidDate(date) && new Date() < new Date(date));
}

export default function getBiddingFormSchema({
  required,
  requiredCheckbox,
  maxNumbersAfterDecimal,
  minQuantityRequired,
  dateMustBeFuture,
}: IBiddingFormSchemaTranslations) {
  return yup.object().shape({
    price: yup
      .number()
      .positive()
      .test(
        'maxNumbersAfterDecimal',
        maxNumbersAfterDecimal,
        maxNumbersAfterDecimalValidation,
      )
      .required(required)
      .transform((val, orig) => (orig === '' ? undefined : val)),
    paymentTerms: yup.string().required(required),
    quantity: yup
      .number()
      .required(required)
      .min(MIN_QUANTITY_REQUIRED_MT, minQuantityRequired)
      .test(
        'maxNumbersAfterDecimal',
        maxNumbersAfterDecimal,
        maxNumbersAfterDecimalValidation,
      )
      .transform((val, orig) => (orig === '' ? undefined : val)),
    incoterms: yup.string().oneOf(INCOTERMS, required).required(required),
    shippingPort: yup
      .string()
      .when('incoterms', ([selectedIncoterm], schema) => {
        return SHIPPING_PORT_INCOTERMS.includes(selectedIncoterm)
          ? schema.required(required)
          : schema.notRequired();
      }),
    destinationAddress: yup
      .string()
      .when('incoterms', ([selectedIncoterm], schema) => {
        return DESTINATION_ADDRESS_INCOTERMS.includes(selectedIncoterm)
          ? schema.required(required)
          : schema.notRequired();
      }),
    validity: yup
      .string()
      .transform((value: string, originalValue: Date) => {
        return isDate(originalValue) ? formatISO(originalValue) : value;
      })
      .test('is-future-date', dateMustBeFuture, dateIsNotPast)
      .optional(),
    additionalComments: yup.string().optional(),
    termsAgreed: yup
      .boolean()
      .oneOf([true], requiredCheckbox)
      .required(requiredCheckbox),
  });
}
