import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const IconWrapper = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '100%',
  '& > svg': {
    width: size,
    height: size,
  },
}));
