import { isNil, isNull, some, values } from 'lodash';
import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ListWrapper,
  MainWrapper,
  MissingInfoWrapper,
} from './ContainerList.css';
import { ContainerListProps } from './ContainerList.types';
import ContainerCard from './ContainerCard/ContainerCard';
import { RiInformationLine } from '@remixicon/react';

const MissingInfoToast = () => (
  <MissingInfoWrapper>
    <RiInformationLine size={24} />
    <Typography variant="p3">
      <Translate id="customer-platform.request-details.order-list.container-drawer.container-information.missing-info" />
    </Typography>
  </MissingInfoWrapper>
);

const isValueMissing = (value: string | null | number) => {
  return isNull(value) || value === '';
};

export default function ContainerList({
  order,
  containerSectionRef,
}: ContainerListProps) {
  const { containers } = order;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  if (isNil(containers) || containers.length === 0) return;

  const isAnyValueMissing = containers.some(container =>
    some(values(container), isValueMissing),
  );

  return (
    <MainWrapper ref={containerSectionRef}>
      <Typography
        component="h5"
        variant={isMobile ? 'h4' : 'h5'}
        data-testid="container-title"
      >
        <Translate id="customer-platform.request-details.order-list.container-list.title" />
      </Typography>
      {isAnyValueMissing && <MissingInfoToast />}
      <Typography variant="p1">
        <Translate id="customer-platform.request-details.order-list.container-list.number-of-containers" />
        <EmptySpace />
        {containers.length}
      </Typography>
      <ListWrapper>
        {containers.map(container => (
          <ContainerCard
            key={container.uuid}
            container={container}
            orderId={order.uuid}
          />
        ))}
      </ListWrapper>
    </MainWrapper>
  );
}
