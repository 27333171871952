import { getDocumentNameID, useGetDocument } from '@frontend/api';
import { trackClickEvent } from '@frontend/tracking';
import { Icon } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { DocumentContainer, DocumentTitle } from './DocumentCard.css';
import { DocumentCardProps } from './DocumentCard.types';
import { Translate } from '@frontend/translation';

export default function DocumentCard({
  documentContext,
  id,
}: DocumentCardProps) {
  const { downloadDocument } = useGetDocument();
  const { document } = documentContext;

  const documentId = `${id}.document-card.${document.documentType}`;

  const handleDownloadFile = async () => {
    trackClickEvent({
      elementId: documentId,
      elementText: document.documentType,
    });

    await downloadDocument(documentContext);
  };

  return (
    <DocumentContainer id={documentId} onClick={handleDownloadFile}>
      <DocumentTitle>
        <Icon size={24} variant="pdf" />
        <Typography variant="p2" noWrap component="span">
          <Translate id={getDocumentNameID(document) ?? ''} />
        </Typography>
      </DocumentTitle>
      <Typography variant="p4" noWrap component="span">
        {document.subTitle ?? '-'}
      </Typography>
    </DocumentContainer>
  );
}
