import { Translate } from '@frontend/translation';
import { Typography } from '@mui/material';
import { NullableCardDetailsItemProps } from './NullableCardDetailsItem.types';

export default function NullableCardDetailsItem({
  data,
  children,
}: NullableCardDetailsItemProps) {
  if (!data) {
    return (
      <Typography variant="caption2" component="span">
        <Translate id="customer-platform.request-list.request-card.missing-value" />
      </Typography>
    );
  }
  return children;
}
