export const SUPPORTED_MIME_IMAGE_FORMATS = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  gif: 'image/gif',
  webp: 'image/webp',
} as Record<string, string | undefined>;

export const SUPPORTED_IMAGE_DOCUMENT_FORMATS = ['pdf'];
