import { IOrderDocumentsListItem } from './OrderDocuments.types';

const ORDER_DOCUMENTS_ITEM_LIST = [
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-1',
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-2',
    required: true,
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-3',
    required: true,
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-4',
    required: true,
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-5',
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-6',
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-7',
  },
  {
    translationId:
      'customer-platform.listing-details.order-documents.list.item-8',
  },
] as IOrderDocumentsListItem[];

export default ORDER_DOCUMENTS_ITEM_LIST;
