import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const BodyMobileStatusWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  boxShadow: theme.shadows[3],
}));

export const HeadingMobileStatusWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  paddingBottom: '12px',
}));

export const MobileStatusBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.primary[500],
  paddingTop: '12px',
}));
