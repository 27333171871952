import { valueOf } from '@frontend/utils';

const PLATFORM_ROUTES = {
  buyMetal: '/buy-metal',
  sellMetal: '/sell-metal',
  emailUnsubscription: '/email/:userId/unsubscribe/:token',
  loginValidation: '/login-validation/:token',
  emailVerification: '/email-verification/:token',
  buyerDashboard: '/requests',
  requestInfo: '/requests/:uuid',
  requestDetails: '/requests/:uuid/orders',
  orderDetails: '/requests/:uuid/orders/:orderId',
  updateBillOfLading: '/requests/:uuid/orders/:orderId/bill-of-lading',
  containerDetails: '/requests/:uuid/orders/:orderId/containers/:containerId',
  biddableListing: '/biddable-listing/:uuid',
  sellerDashboard: '/listings',
  listingInfo: '/listings/:uuid',
  listingDetails: '/listings/:uuid/orders',
  listingOrderDetails: '/listings/:uuid/orders/:orderId',
  listingOrderDocuments: '/listings/:uuid/orders/:orderId/document-manager',
  listingOrderPhotos: '/listings/:uuid/orders/:orderId/photo-manager',
  acceptWhatsappConsent: '/accept-whatsapp-transaction-updates',
} as const;

export type TPlatformRoutes = valueOf<typeof PLATFORM_ROUTES>;
export default PLATFORM_ROUTES;
