import { Container as MUIContainer } from '@mui/material';
import { styled } from '@frontend/theme';

export const MainWrapper = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
  margin: 0,
  padding: 0,
}));
