import { styled } from '@frontend/theme';
import {
  Box as MUIBox,
  Step as MUIStep,
  StepContent as MUIStepContent,
  StepLabel as MUIStepLabel,
} from '@mui/material';

export const EventRowWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

export const StepSt = styled(MUIStep)(({ theme }) => ({
  '&.Mui-completed > .MuiStepContent-root': {
    borderColor: theme.palette.primary[500],
    borderLeftStyle: 'solid',
  },
}));

export const StepContentSt = styled(MUIStepContent)(({ theme }) => ({
  color: theme.palette.neutral[500],
  marginLeft: '11.2px',
  borderRadius: 0,
  borderLeftStyle: 'dashed',
  borderLeftWidth: '2px',
  borderColor: theme.palette.primary[100],
  '&.MuiStepContent-last': {
    borderColor: 'transparent',
  },
}));

export const StepLabelSt = styled(MUIStepLabel)(({ theme }) => ({
  padding: 0,
  color: theme.palette.neutral[500],
  '& > .MuiStepLabel-labelContainer > .MuiStepLabel-label': {
    color: theme.palette.neutral[500],
  },
}));
