import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { IGetImageResponseFailure, IMAGE_VARIANT_TYPE } from './getImage.types';
import useGetImageUrl from './getImageUrl';
import axiosClient from '../axiosClient';

const useGetImageQuery = (
  parentId: string,
  imageId: string,
  variant: IMAGE_VARIANT_TYPE,
) => {
  const QueryKey = ['image', variant, parentId, imageId];
  const { getImageUrl } = useGetImageUrl();

  const submitGetContainerImage = (imageUrl: string) => {
    return axiosClient.get(imageUrl, { responseType: 'blob' });
  };

  const imageUrl = getImageUrl(parentId, imageId, variant);

  return useQuery<
    AxiosResponse<Blob>,
    AxiosError<IGetImageResponseFailure>,
    AxiosResponse<Blob>
  >({
    queryFn: () => submitGetContainerImage(imageUrl),
    queryKey: QueryKey,
  });
};

export default useGetImageQuery;
