import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const Step = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '8px',
  width: '100%',
}));

export const StepContent = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  width: '100%',
}));

export const StepHeadingWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  width: '100%',
}));

export const StepNumber = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '36px',
  width: '100%',
  minHeight: '36px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  color: active ? theme.palette.common.white : theme.palette.primary[500],
  backgroundColor: active ? theme.palette.primary[500] : 'transparent',
  transition: 'all .3s ease-out',
  border: `2px solid ${theme.palette.primary[500]}`,
}));

export const StepListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
}));

export const Wrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  backgroundColor: theme.palette.warning[50],
  padding: '32px 20px',
  width: '100%',
}));
