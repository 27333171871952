import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const BiddableListingCardWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  padding: '24px 20px',
  maxWidth: '540px',
  width: '100%',
  border: `1px solid ${theme.palette.neutral[100]}`,
  background: theme.palette.common.white,
  [theme.breakpoints.down('desktop')]: {
    maxWidth: '100%',
  },
}));

export const HeadingWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
});

export const LoaderWrapper = styled(MUIBox)({
  display: 'flex',
  justifyContent: 'center',
});

export const PublicPageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '135px',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    gap: '20px',
  },
}));

export const PrivatePageWrapper = styled(PublicPageWrapper)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column-reverse',
    gap: '40px',
  },
}));

export const PublicPageLeftColumn = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '640px',
  width: '100%',
  height: '100%',
  paddingBottom: '20px',
  [theme.breakpoints.down('desktop')]: {
    maxWidth: '100%',
  },
}));

export const PrivatePageLeftColumn = styled(PublicPageLeftColumn)(
  ({ theme }) => ({
    gap: '16px',
    maxWidth: '540px',
    paddingBottom: 0,
    [theme.breakpoints.down('desktop')]: {
      maxWidth: '100%',
    },
  }),
);

export const PublicPageRightColumn = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  maxWidth: '540px',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    gap: '20px',
    maxWidth: '100%',
  },
}));

export const PrivatePageRightColumn = styled(PublicPageRightColumn)(
  ({ theme }) => ({
    gap: '24px',
    padding: '24px 36px',
    border: `1px solid ${theme.palette.neutral[100]}`,
    background: theme.palette.common.white,
    maxWidth: '640px',
    [theme.breakpoints.down('desktop')]: {
      gap: '20px',
    },
  }),
);

export const Separator = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  height: '1px',
  width: '100%',
  backgroundColor: theme.palette.neutral[100],
}));

export const SeparatorWrapper = styled(MUIBox)({
  display: 'inline-flex',
  gap: '48px',
  alignItems: 'center',
});

export const SubheadingWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
