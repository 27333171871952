import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const IconWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  position: 'relative',
  '& > svg': {
    '&:first-of-type': {
      zIndex: 1,
    },
    '&:last-of-type': {
      position: 'absolute',
      top: '3.22px',
      left: '3.22px',
    },
  },
}));

export const ModalBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 24px',
  maxWidth: '482px',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '332px',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    width: '100%',
    '& > .MuiTextField-root': {
      width: '100%',
    },
    '& > .MuiButton-root': {
      width: '100%',
    },
  },
}));

export const ModalHeadingBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  marginTop: '32px',
}));

export const ModalTextBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
}));
