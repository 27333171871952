import { useUpdateWhatsappConsent } from '@frontend/api';
import { LoaderWrapper } from './AcceptWhatsappConsent.css';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from '@frontend/auth';
import { useNavigate } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';

export function AcceptWhatsappConsent() {
  const {
    mutate: updateWhatsappConsent,
    status: isUpdateWhatsappConsentSuccess,
  } = useUpdateWhatsappConsent();

  const { isCurrentUserSeller } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    updateWhatsappConsent();
  }, [updateWhatsappConsent]);

  useEffect(() => {
    if (isUpdateWhatsappConsentSuccess) {
      navigate(
        {
          pathname: isCurrentUserSeller
            ? PLATFORM_ROUTES.sellerDashboard
            : PLATFORM_ROUTES.buyerDashboard,
        },
        { state: { whatsappConsentSuccess: true } },
      );
    }
    // Preventing navigate to be in the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWhatsappConsentSuccess, isCurrentUserSeller]);

  return (
    <LoaderWrapper>
      <CircularProgress size={160} />
    </LoaderWrapper>
  );
}

export default AcceptWhatsappConsent;
