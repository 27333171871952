import { IBillOfLading, useUpsertBillOfLading } from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { DrawerWithHeader } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Subtitle } from './BillOfLadingDrawer.css';
import { IBIllOfLadingDrawer } from './BillOfLadingDrawer.types';
import BillOfLadingForm from './BillOfLadingForm/BillOfLadingForm';
import FormType from '@customer-platform-shared/components/Forms/utils/FormType.types';

const BillOfLadingDrawer = ({ order, onSuccess }: IBIllOfLadingDrawer) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const updateBillOfLadingMatch = useMatch(PLATFORM_ROUTES.updateBillOfLading);

  const isUpdateBillOfLadingURL =
    !!updateBillOfLadingMatch &&
    updateBillOfLadingMatch.params.orderId === order.uuid;

  const { uuid: requestId } = useParams();
  const { billOfLading, uuid: orderId } = order;

  const {
    mutate: upsertBillOfLading,
    isSuccess: isUpsertBillOfLadingSuccess,
    isPending: isUpsertBillOfLadingPending,
    error: upsertBillOfLadingError,
  } = useUpsertBillOfLading(orderId as string, requestId as string);

  const handleCloseBillOfLadingForm = () => {
    const orderDetailsUrl = generatePath(PLATFORM_ROUTES.orderDetails, {
      uuid: requestId as string,
      orderId: orderId as string,
    });
    navigate(orderDetailsUrl, { replace: true });
  };

  const handleBillOfLadingFormSubmit: SubmitHandler<
    FormType<IBillOfLading>
  > = billOfLadingData => {
    upsertBillOfLading({
      billOfLading: billOfLadingData,
      newRecord: !billOfLading,
    });
  };

  useEffect(() => {
    if (isUpsertBillOfLadingSuccess) {
      onSuccess();
      handleCloseBillOfLadingForm();
    }
    // onSuccess and handleCloseBillOfLadingForm cannot be added into the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpsertBillOfLadingSuccess]);

  return (
    <DrawerWithHeader
      headerLine={intl.formatMessage({
        id: `customer-platform.request-details.bill-of-lading.form.heading.${billOfLading ? 'update-bol' : 'create-bol'}`,
      })}
      id="bill-of-lading.form-drawer"
      onClose={handleCloseBillOfLadingForm}
      open={isUpdateBillOfLadingURL}
    >
      <Subtitle>
        <Typography variant="p1">
          <Translate id="customer-platform.request-details.bill-of-lading.form.subheading" />
        </Typography>
        <Typography variant="caption2" component="p">
          <Translate id="customer-platform.request-details.bill-of-lading.form.subheading-cation" />
        </Typography>
      </Subtitle>
      <BillOfLadingForm
        errors={upsertBillOfLadingError?.response?.data}
        isSuccess={isUpsertBillOfLadingSuccess}
        isPending={isUpsertBillOfLadingPending}
        billOfLading={billOfLading}
        onSubmit={handleBillOfLadingFormSubmit}
      />
    </DrawerWithHeader>
  );
};

export default BillOfLadingDrawer;
