import { StatusBar } from '@frontend/ui-elements';
import { useState } from 'react';
import { OrderStatusBarWrapper } from './OrderStatusBar.css';
import { IOrderStatusBar } from './orderStatusBar.types';

export default function OrderStatusBar({
  isMobile = false,
  isOrderSelected = false,
  activeStep,
  stages,
}: IOrderStatusBar) {
  const [isStatusBarOpen, setIsStatusBarOpen] = useState(false);

  const handleStatusBarOpen = () => {
    setIsStatusBarOpen(!isStatusBarOpen);
  };

  if (isMobile && isOrderSelected) {
    return (
      <OrderStatusBarWrapper>
        <StatusBar
          activeStep={activeStep}
          onClick={handleStatusBarOpen}
          open={isStatusBarOpen}
          stages={stages}
        />
      </OrderStatusBarWrapper>
    );
  }
  return (
    <OrderStatusBarWrapper>
      <StatusBar
        activeStep={activeStep}
        onClick={handleStatusBarOpen}
        open={isStatusBarOpen}
        stages={stages}
        isSimplified={isMobile}
      />
    </OrderStatusBarWrapper>
  );
}
