import { init as sentryInit } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './app/app';
import sentryConfig from '../sentry.config';

const environment: string = import.meta.env.VITE_ENVIRONMENT || undefined;
if (environment === 'prod') {
  sentryInit(sentryConfig);
}

const gtmId: string = import.meta.env.VITE_GTM_TAG_ID || undefined;
if (gtmId && environment !== 'dev') {
  TagManager.initialize({ gtmId });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
