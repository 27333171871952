import { Typography } from '@mui/material';
import { Children } from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper,
  WizardContent,
  WizardHeading,
  WizardProgressBar,
} from './StepsWizard.css';
import { StepsWizardProps } from './StepsWizard.types';

const StepsWizard = ({ children, currentStep, labels }: StepsWizardProps) => {
  const intl = useIntl();
  const totalChildren = Children.count(children);
  const safeCurrentStep =
    currentStep > totalChildren ? totalChildren : currentStep;
  const wizardProgress = ((safeCurrentStep + 1) / totalChildren) * 100;

  return (
    <Wrapper>
      <WizardHeading>
        <Typography variant="h5">
          {intl.formatMessage(
            {
              id: 'customer-platform.shared.components.steps-wizard.heading',
            },
            {
              CURRENT_STEP: safeCurrentStep + 1,
              TOTAL_STEPS: totalChildren,
              STEP_NAME: intl.formatMessage({
                id: labels[safeCurrentStep],
                defaultMessage: '',
              }),
            },
          )}
        </Typography>
        {/* We are aria-hiding the bar because it does not provide any useful or meaningful information to screen readers */}
        <WizardProgressBar value={wizardProgress} aria-hidden={true} />
      </WizardHeading>
      {Children.map(children, (child, index) => {
        return (
          <WizardContent
            aria-current={safeCurrentStep === index ? 'step' : undefined}
            aria-hidden={safeCurrentStep !== index}
            hidden={safeCurrentStep !== index}
            key={labels[index]}
          >
            {child}
          </WizardContent>
        );
      })}
    </Wrapper>
  );
};

export default StepsWizard;
