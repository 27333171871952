import { styled } from '@frontend/theme';
import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
} from '@frontend/ui-elements';
import { Box as MUIBox } from '@mui/material';

export const ButtonWrapper = styled(MUIBox)({
  display: 'flex',
  alignItems: 'flex-start',
});

export const ListingAccordion = styled(BaseAccordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    gap: '16px',
    background: `${theme?.palette.common.white}`,
    padding: '20px 20px',
    border: `1px solid ${theme?.palette.primary[50]}`,
    [theme.breakpoints.up('desktop')]: {
      '&.Mui-expanded': {
        border: `1px solid ${theme?.palette.primary[500]}`,
      },
    },
  },
}));

export const ListingAccordionDetails = styled(BaseAccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const ListingCardWrapper = styled(MUIBox)(({ theme }) => ({
  cursor: 'pointer',
  '& .Mui-expanded, & .Mui-expanded:hover:not(.Mui-disabled)': {
    cursor: 'default',
    userSelect: 'initial',
  },

  [theme.breakpoints.down('desktop')]: {
    cursor: 'default',
    flexBasis: 'calc((100% - 12px)/2)',
  },

  [theme.breakpoints.down('tablet')]: {
    flexBasis: '100%',
  },
}));

export const ListingOverview = styled(MUIBox)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
});

export const ListingNoAndDateWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'start',
});

export const ListingDetails = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ListingDetailsItem = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  padding: '8px 0px',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  ':not(:last-of-type)': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
}));
