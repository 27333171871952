import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IUpdateAccountToBuyerAndSellerError } from './updateAccountToBuyerAndSeller.types';

const updateAccountToBuyerAndSeller = (apiUrl: string) => {
  return axiosClient.patch(`${apiUrl}/accounts/user/assign-role/`);
};

const useUpdateAccountToBuyerAndSeller = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse,
    AxiosError<IUpdateAccountToBuyerAndSellerError>
  >({
    mutationFn: () => updateAccountToBuyerAndSeller(apiUrl),
  });
};

export default useUpdateAccountToBuyerAndSeller;
