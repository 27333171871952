import { useContext } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { ApiContext } from '../ApiContext/ApiContext';
import {
  ILoginMutation,
  IUserLoginErrors,
  IUserLoginRequest,
} from './login.types';
import axiosClient from '../axiosClient';

const submitLogin = ({
  loginRequest,
  apiUrl,
  redirectUrl,
  sourceUrl,
}: {
  loginRequest: IUserLoginRequest;
  apiUrl: string;
  redirectUrl?: string;
  sourceUrl?: string;
}) => {
  const params = new URLSearchParams();
  if (sourceUrl) {
    params.append('sourceUrl', sourceUrl);
  }
  if (redirectUrl) {
    params.append('redirectUrl', redirectUrl);
  }
  const queryParams = params.toString();

  return axiosClient.post(
    `${apiUrl}/accounts/user/request-login/?${queryParams}`,
    loginRequest,
  );
};

const useLoginMutation = ({ redirectUrl, sourceUrl }: ILoginMutation) => {
  const { apiUrl } = useContext(ApiContext);

  return useMutation<
    AxiosResponse<IUserLoginRequest>,
    AxiosError<IUserLoginErrors>,
    IUserLoginRequest
  >({
    mutationFn: (loginRequest: IUserLoginRequest) =>
      submitLogin({ loginRequest, apiUrl, redirectUrl, sourceUrl }),
  });
};

export default useLoginMutation;
