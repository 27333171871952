import { LOGIN_LINK_SOURCE, useAuth } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ICreateSessionRequest } from '@frontend/api';
import { LoaderWrapper } from './EmailVerification.css';

export function EmailVerification() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const {
    emailVerification,
    isEmailVerificationInProgress,
    isEmailVerificationNotStarted,
    isEmailVerificationSuccess,
    isEmailVerificationFail,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (emailVerification && isEmailVerificationNotStarted) {
      if (currentUser) {
        if (currentUser.emailVerified) {
          navigate(PLATFORM_ROUTES.buyMetal);
          return;
        }
        const params: ICreateSessionRequest = {
          ...(token && { token }),
        };
        emailVerification(params);
      } else {
        navigate({
          pathname: PLATFORM_ROUTES.buyMetal,
          search: `?source=${LOGIN_LINK_SOURCE}`,
        });
      }
    }
  }, [
    token,
    searchParams,
    isEmailVerificationNotStarted,
    currentUser,
    emailVerification,
    navigate,
  ]);

  useEffect(() => {
    if (!isEmailVerificationInProgress && !isEmailVerificationNotStarted) {
      if (currentUser) {
        const destination = isCurrentUserBuyer
          ? PLATFORM_ROUTES.buyerDashboard
          : PLATFORM_ROUTES.sellerDashboard;

        if (isEmailVerificationSuccess) {
          navigate(
            {
              pathname: destination,
            },
            { state: { emailVerificationSuccess: true } },
          );
        } else if (isEmailVerificationFail) {
          navigate(
            {
              pathname: destination,
            },
            { state: { emailVerificationExpired: true } },
          );
        }
      } else {
        navigate({
          pathname: PLATFORM_ROUTES.buyMetal,
          search: `?source=${LOGIN_LINK_SOURCE}`,
        });
        return;
      }
    }
  }, [
    isEmailVerificationInProgress,
    isEmailVerificationNotStarted,
    isEmailVerificationSuccess,
    isEmailVerificationFail,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
    navigate,
    searchParams,
  ]);

  if (isEmailVerificationInProgress || isEmailVerificationNotStarted) {
    return (
      <LoaderWrapper>
        <CircularProgress size={160} />
      </LoaderWrapper>
    );
  }
}

export default EmailVerification;
