import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const PageColumns = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
});

export const PageContent = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [theme.breakpoints.down('tablet')]: {
    gap: '20px',
  },
}));
