import { useContext } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IRequestErrors, IRequestSubmissionData } from './createRequest.types';
import { QueryKey as GetRequestListQueryKey } from '../getRequestList/getRequestList.const';

const submitCreateRequest = (
  createRequest: IRequestSubmissionData,
  apiUrl: string,
) => {
  return axiosClient.post(`${apiUrl}/trading/requests/create/`, createRequest);
};

const useCreateRequest = () => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IRequestSubmissionData>,
    AxiosError<IRequestErrors>,
    IRequestSubmissionData
  >({
    mutationFn: (createRequest: IRequestSubmissionData) =>
      submitCreateRequest(createRequest, apiUrl),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GetRequestListQueryKey,
      });
    },
  });
};

export default useCreateRequest;
