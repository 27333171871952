import { styled, theme } from '@frontend/theme';
import { SideWrapper as BaseSideWrapper } from '@frontend/ui-elements';
import { Box as MUIBox, Container as MUIContainer } from '@mui/material';
import { MainWrapperProps } from './ListingOrderList.types';

export const Container = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  border: `1px solid ${theme.palette.primary[50]}`,
  backgroundColor: theme.palette.common.white,
  padding: '24px',
}));

export const ListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  width: '100%',
}));

export const LoaderWrapper = styled(MUIBox)({
  display: 'flex',
  justifyContent: 'center',
});

export const MainWrapper = styled(BaseSideWrapper, {
  shouldForwardProp: prop => prop !== 'distance',
})<MainWrapperProps>(({ theme, distance = 0 }) => ({
  flexBasis: 'calc(100% * (2 / 3) - 12px)', // 12px from gap with sibling
  height: '100%',
  paddingBottom: '40px',
  overflowY: 'inherit',

  [theme.breakpoints.up('desktop')]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: `calc(100vh - ${distance + 40}px)`, // +40px from padding bottom
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.primary[100]} transparent`,
    scrollbarWidth: 'thin',
  },
}));
