import { FormattedMessage } from 'react-intl';

type TranslationProps = {
  id: string;
  defaultMessage?: string;
  description?: string;
  values?: object;
};

export function Translate({
  id,
  defaultMessage,
  description,
  values,
}: TranslationProps) {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage || id}
      description={description}
      values={{ ...values }}
    />
  );
}

export default Translate;
