import { styled } from '@frontend/theme';
import { Box as MUIBox, List as MUIList } from '@mui/material';

export const List = styled(MUIList)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'flex-start',
  justifyItems: 'center',
  listStyle: 'disc',
  listStyleType: 'disc',
  paddingInlineStart: '22px',
}));

export const ListItem = styled('li')(() => ({
  fontSize: '9px',
  '& > .MuiTypography-root': {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
}));

export const ListWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  justifyItems: 'center',
  width: '100%',
}));

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  padding: '24px 0',
}));
