import { StepIcon, StepIconProps, Typography } from '@mui/material';
import { StepSt, StepLabelSt } from './Step.css';
import StepProps from './Step.types';

export function Step(props: StepProps) {
  const { label, number, optional = undefined, ...rest } = props;

  return (
    <StepSt {...rest}>
      <StepLabelSt
        optional={optional}
        StepIconComponent={(stepIconProps: StepIconProps) => (
          <StepIcon
            {...stepIconProps}
            icon={
              <svg>
                <circle cx="12" cy="12" r="11" />
                <text
                  className="MuiStepIcon-text"
                  x="12"
                  y="12"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {number}
                </text>
              </svg>
            }
          />
        )}
      >
        {label && <Typography variant="p2">{label}</Typography>}
        {optional && (
          <Typography component="p" variant="caption4">
            {optional}
          </Typography>
        )}
      </StepLabelSt>
    </StepSt>
  );
}

export default Step;
