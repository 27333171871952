import { styled, theme } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div:not(:last-of-type)': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
}));
