import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import {
  ISellerOrderUploadFileVariant,
  ISellerOrderFileUploadErrors,
  ISellerOrderFileUploadSubmissionData,
} from './uploadSellerOrderFiles.types';
import { getQueryKey } from './uploadSellerOrderFiles.const';
import axiosClient from '../axiosClient';

const uploadSellerOrderFiles = (
  apiUrl: string,
  orderFiles: ISellerOrderFileUploadSubmissionData,
  orderFileVariant: ISellerOrderUploadFileVariant,
  orderId: string,
) => {
  const uploadForm = new FormData();

  Object.entries(orderFiles).forEach(([key, values]) => {
    values.forEach((file: File) => {
      uploadForm.append(key, file, file.name);
    });
  });

  return axiosClient.post(
    `${apiUrl}/orders/seller/${orderId}/${orderFileVariant}/upload/`,
    uploadForm,
  );
};

const useUploadSellerOrderFiles = (
  orderFileVariant: ISellerOrderUploadFileVariant,
  orderId: string,
) => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<ISellerOrderFileUploadSubmissionData>,
    AxiosError<ISellerOrderFileUploadErrors>,
    ISellerOrderFileUploadSubmissionData
  >({
    mutationFn: (orderFiles: ISellerOrderFileUploadSubmissionData) =>
      uploadSellerOrderFiles(apiUrl, orderFiles, orderFileVariant, orderId),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: getQueryKey(orderId, orderFileVariant),
        })
        .then();
    },
  });
};

export default useUploadSellerOrderFiles;
