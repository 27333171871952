export type PlatformHeaderProps = {
  variant: THeaderVariants;
};

export const HEADER_VARIANTS = {
  BUYER: 'buyer_dashboard',
  SELLER: 'seller_dashboard',
  GUEST: 'no_dashboard',
} as Record<string, string>;

export type THeaderVariants = (typeof HEADER_VARIANTS)[number];
