import { IListing, IRequest } from '@frontend/api';
import {
  RemixiconComponentType,
  RiCheckDoubleLine,
  RiCloseLine,
  RiLoader4Fill,
  RiTimerLine,
} from '@remixicon/react';

export const statusIconMapping: Record<
  IRequest['status'] | IListing['status'],
  RemixiconComponentType
> = {
  'request.status.accepted': RiCheckDoubleLine,
  'request.status.cancelled': RiCloseLine,
  'request.status.confirming_details': RiLoader4Fill,
  'request.status.pending': RiTimerLine,
  'request.status.sourcing': RiLoader4Fill,
  'listing.status.accepted': RiCheckDoubleLine,
  'listing.status.cancelled': RiCloseLine,
  'listing.status.confirming_details': RiLoader4Fill,
  'listing.status.pending': RiTimerLine,
  'listing.status.sourcing': RiLoader4Fill,
};
