import TagManager from 'react-gtm-module';
import { validate } from 'uuid';
import { EVENTS } from './tracking.const';
import { captureError } from '@frontend/utils';
import {
  ClickEventTrackingData,
  FormFieldEventTrackingData,
  FormSubmitEventTrackingData,
  ModalViewEventTrackingData,
  PAGE_PATH_PARAMS,
  ScrollEventTrackingData,
  TCustomDataLayer,
  TDevice,
  TPagePathParam,
} from './tracking.types';
import {
  getPreviousPath,
  getPreviousPathAndUpdate,
  getSessionId,
  getUserId,
} from '../session/session';

function getDeviceTypeFromUserAgent(): TDevice | undefined {
  if (
    typeof window === 'undefined' ||
    window?.navigator?.userAgent === 'undefined'
  ) {
    return undefined;
  }

  const userAgent = window?.navigator?.userAgent.toLowerCase();
  if (/iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent)) {
    return 'mobile';
  }
  if (/(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent)) {
    return 'tablet';
  }
  return 'desktop';
}

function getPagePathFromWindow() {
  return window?.location?.pathname || '';
}

function extractParamFromPagePath(param: TPagePathParam, pagePath: string) {
  const pathSegments = pagePath.split('/').filter(Boolean);

  const paramIndex = pathSegments.findIndex(segment => segment === param);
  if (paramIndex !== -1 && paramIndex + 1 < pathSegments.length) {
    const potentialUuid = pathSegments[paramIndex + 1];
    return validate(potentialUuid) ? potentialUuid : undefined;
  }

  return undefined;
}

export function pushToDataLayer({
  event,
  elementId,
  elementText,
  elementNavigatesTo,
  pagePath: contextPath,
  scrolledPercentage,
  scrollDirection,
  formId,
  formName,
  formStatus,
  formField,
  formFieldAction,
  formFieldOldValue,
  formFieldNewValue,
}: TCustomDataLayer) {
  try {
    const userId = getUserId();
    const sessionId = getSessionId();
    const pagePath = contextPath ?? getPagePathFromWindow();
    const previousPagePath =
      event === EVENTS.PAGE_VIEW
        ? getPreviousPathAndUpdate(pagePath)
        : getPreviousPath();
    const listingId = extractParamFromPagePath(
      PAGE_PATH_PARAMS.LISTINGS,
      pagePath,
    );
    const requestId = extractParamFromPagePath(
      PAGE_PATH_PARAMS.REQUESTS,
      pagePath,
    );
    const orderId = extractParamFromPagePath(PAGE_PATH_PARAMS.ORDERS, pagePath);
    const deviceType = getDeviceTypeFromUserAgent();

    const dataLayerData = Object.fromEntries(
      Object.entries({
        event,
        device_type: deviceType,
        element_id: elementId,
        element_next_page_path: elementNavigatesTo,
        element_text: elementText,
        form_id: formId,
        form_name: formName,
        form_status: formStatus,
        form_field: formField,
        form_field_action: formFieldAction,
        form_field_old_value: formFieldOldValue,
        form_field_new_value: formFieldNewValue,
        listing_id: listingId,
        order_id: orderId,
        page_path: pagePath,
        previous_page_path: previousPagePath,
        request_id: requestId,
        scrolled_percentage: scrolledPercentage,
        scroll_direction: scrollDirection,
        session_id: sessionId,
        timestamp: new Date().getTime(),
        user_id: userId,
      }).filter(([_, value]) => value !== undefined),
    );

    TagManager.dataLayer({
      dataLayer: dataLayerData,
    });
  } catch (error) {
    captureError(error);
  }
}

function getPathFromURL(elementUrl: URL) {
  return window?.location?.host !== elementUrl.host
    ? elementUrl.toString()
    : elementUrl.pathname;
}

export function trackClickEvent({
  elementId,
  elementText,
  href,
}: ClickEventTrackingData) {
  try {
    const base = new URL(window?.location?.href).origin;
    const navigatesTo = href ? getPathFromURL(new URL(href, base)) : undefined;
    pushToDataLayer({
      event: EVENTS.CLICK,
      elementId: elementId,
      elementText: elementText,
      elementNavigatesTo: navigatesTo,
    });
  } catch (error) {
    captureError(error);
  }
}

export function trackScrollEvent({
  scrolledPercentage,
  scrollDirection,
}: ScrollEventTrackingData) {
  try {
    pushToDataLayer({
      event: EVENTS.SCROLL,
      scrolledPercentage,
      scrollDirection,
    });
  } catch (error) {
    captureError(error);
  }
}

export function trackFormSubmitEvent({
  formId,
  formName,
  formStatus,
}: FormSubmitEventTrackingData) {
  try {
    pushToDataLayer({
      event: EVENTS.FORM_SUBMIT,
      formId,
      formName,
      formStatus,
    });
  } catch (error) {
    captureError(error);
  }
}

export function trackFormFieldEvent({
  formId,
  formField,
  formFieldAction,
  formFieldOldValue,
  formFieldNewValue,
}: FormFieldEventTrackingData) {
  try {
    pushToDataLayer({
      event: EVENTS.FORM_FIELD,
      formId,
      formField,
      formFieldAction,
      formFieldOldValue,
      formFieldNewValue,
    });
  } catch (error) {
    captureError(error);
  }
}

export function trackModalViewEvent({ elementId }: ModalViewEventTrackingData) {
  try {
    pushToDataLayer({
      event: EVENTS.MODAL_VIEW,
      elementId,
    });
  } catch (error) {
    captureError(error);
  }
}

export function generateIdFromText(text: string): string {
  return text
    .toLowerCase()
    .normalize('NFD') // Unicode Canonical Decomposition.
    .replace(/[\u0300-\u036f]/g, '') // Normalize special characters like ç or ñ in case we add more languages
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/[^a-z0-9-]/g, ''); // Remove any characters that are not alphanumeric or dashes such as question marks
}
