import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { EVENTS, pushToDataLayer } from '@frontend/tracking';

export function usePageView() {
  const location = useLocation();

  useEffect(() => {
    pushToDataLayer({
      event: EVENTS.PAGE_VIEW,
      pagePath: location.pathname,
    });
  }, [location]);
}
