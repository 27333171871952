import { useAuth } from '@frontend/auth';
import { Translate } from '@frontend/translation';
import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonsBox,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './LogoutModal.css';
import { ILogoutModal } from './LogoutModal.types';
import { RiQuestionLine } from '@remixicon/react';

export default function LogoutModal({ onClose }: ILogoutModal) {
  const theme = useTheme();
  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('tabletL'));
  const { logout } = useAuth();

  const handleLogout = () => {
    logout && logout();
    onClose();
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalBox>
        <ModalHeadingBox>
          <IconWrapper>
            <RiQuestionLine
              size={isTabletOrLess ? 68.165 : 97.378}
              color={theme.palette.neutral[500]}
            />
            <RiQuestionLine
              size={isTabletOrLess ? 68.165 : 97.378}
              color={theme.palette.secondary[500]}
            />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">
              <Translate id="customer-platform.header.logout-modal.heading" />
            </Typography>
            <Typography variant="p3">
              <Translate id="customer-platform.header.logout-modal.subheading" />
            </Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <ButtonsBox>
          <Button
            buttonType="secondary"
            id="logout-modal.button.cancel"
            size="large"
            onClick={onClose}
          >
            <Translate id="customer-platform.header.logout-modal.heading.cta.cancel" />
          </Button>
          <Button
            buttonType="primary"
            id="logout-modal.button.logout"
            size="large"
            onClick={handleLogout}
          >
            <Translate id="customer-platform.header.logout-modal.heading.cta.log-out" />
          </Button>
        </ButtonsBox>
      </ModalBox>
    </Modal>
  );
}
