import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const Row = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: '24px',
  },
  '& > *': {
    flex: 1,
  },
}));

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
});
