import {
  IGenericFile,
  IMAGE_VARIANT,
  SUPPORTED_IMAGE_DOCUMENT_FORMATS,
  SUPPORTED_MIME_IMAGE_FORMATS,
  useGetImageUrl,
} from '@frontend/api';
import { trackClickEvent } from '@frontend/tracking';
import { Translate } from '@frontend/translation';
import { Button, Icon } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DocumentCardWrapper,
  DocumentListWrapper,
  MainWrapper,
  ImageListWrapper,
  ToastAlert,
} from './ImageListWithMagnifier.css';
import { ImageListWithMagnifierProps } from './ImageListWithMagnifier.types';
import {
  getVariantMaxImageNumber,
  getVariantTitle,
} from './ImageListWithMagnifier.utils';
import { isFileTypeIncluded } from '../../utils/fileType.utils';
import ImageCardWithMagnifier from './ImageCardWithMagnifier/ImageCardWithMagnifier';
import {
  ImageCardOverlayWrapper,
  OverlayWrapper,
} from './ImageCardWithMagnifier/ImageCardWithMagnifier.css';
import { RiImageLine } from '@remixicon/react';
import ImagesLightbox from '../ImagesLightbox/ImagesLightbox';

const ImageListWithMagnifier = ({
  uuid,
  imageList,
  variant,
  displayAllImages = true,
  displayAllTypes = false,
  downloadAllOnClick,
  downloadSingleImage,
}: ImageListWithMagnifierProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { getImageUrl } = useGetImageUrl();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isOverlayClosed, setIsOverlayClosed] =
    useState<boolean>(displayAllImages);
  const [lightboxActive, setLightboxActive] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const supportedImages = imageList.filter(
    image =>
      !isFileTypeIncluded(
        image.fileType || '',
        SUPPORTED_IMAGE_DOCUMENT_FORMATS,
      ),
  );

  const pdfImagesList = imageList.filter(image =>
    isFileTypeIncluded(image.fileType || '', SUPPORTED_IMAGE_DOCUMENT_FORMATS),
  );

  const titleId = getVariantTitle(variant);
  const MAX_IMAGES_NUMBER = getVariantMaxImageNumber(variant, isMobile);
  const remainingImages = intl.formatMessage(
    {
      id: 'customer-platform.request-details.order-list.container-drawer.images.remaining',
    },
    {
      REMAINING_FILES: (supportedImages.length - MAX_IMAGES_NUMBER).toString(),
    },
  );

  const handleDownload = async (document: IGenericFile) => {
    trackClickEvent({
      elementId: `${variant.toLowerCase()}.image-list.pdf-image.img_${document.uniqueIdentifier}`,
    });
    if (downloadSingleImage) {
      await downloadSingleImage(uuid, document);
    }
  };

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    if (downloadAllOnClick) {
      await downloadAllOnClick();
    }
    setIsDownloading(false);
  };

  const handleOverlayClick = () => {
    trackClickEvent({
      elementId: `${variant.toLowerCase()}.image-list.overlay`,
    });
    setIsOverlayClosed(true);
    return;
  };

  const openLightbox = (index: number, imageId: string) => {
    trackClickEvent({
      elementId: `${variant.toLowerCase()}.image-list.open-lightbox.${imageId}`,
    });
    setSelectedImageIndex(index);
    setLightboxActive(true);
  };

  const shouldRenderACard = (currentIndex: number) => {
    return isOverlayClosed || currentIndex < MAX_IMAGES_NUMBER;
  };

  const isCardWithOverlay = (currentIndex: number) => {
    return (
      !isOverlayClosed &&
      currentIndex === MAX_IMAGES_NUMBER - 1 &&
      supportedImages.length > MAX_IMAGES_NUMBER
    );
  };

  return (
    <MainWrapper>
      {titleId && (
        <Typography
          component="h5"
          variant={variant === IMAGE_VARIANT.CONTAINER ? 'h5' : 'p1'}
        >
          <Translate id={titleId} />
        </Typography>
      )}

      {supportedImages.length > 0 ? (
        <MainWrapper>
          <ImageListWrapper>
            {supportedImages.map((image, index) =>
              shouldRenderACard(index) ? (
                isCardWithOverlay(index) ? (
                  <ImageCardOverlayWrapper
                    key={image.uniqueIdentifier}
                    id={`${variant.toLowerCase()}.image-list.overlay`}
                    data-testid={`${variant.toLowerCase()}.image-list.overlay`}
                    cardsPerRow={MAX_IMAGES_NUMBER}
                    onClick={() => handleOverlayClick()}
                  >
                    <ImageCardWithMagnifier
                      id={`${variant.toLowerCase()}.image-list.image.${image.uniqueIdentifier}`}
                      parentId={uuid}
                      imageId={image.uniqueIdentifier}
                      variant={variant}
                      onClick={() => handleOverlayClick()}
                      cardsPerRow={MAX_IMAGES_NUMBER}
                    />
                    <OverlayWrapper>
                      <Typography
                        color={theme.palette.common.white}
                        component="p"
                        variant="caption1"
                      >
                        {remainingImages}
                      </Typography>
                    </OverlayWrapper>
                  </ImageCardOverlayWrapper>
                ) : (
                  <ImageCardWithMagnifier
                    key={image.uniqueIdentifier}
                    id={`${variant.toLowerCase()}.image-list.image.${image.uniqueIdentifier}`}
                    parentId={uuid}
                    imageId={image.uniqueIdentifier}
                    variant={variant}
                    onClick={() => openLightbox(index, image.uniqueIdentifier)}
                    cardsPerRow={MAX_IMAGES_NUMBER}
                  />
                )
              ) : null,
            )}
          </ImageListWrapper>

          {displayAllTypes && pdfImagesList.length > 0 && (
            <MainWrapper>
              <Typography variant="h6">
                <Translate
                  id={
                    'customer-platform.request-details.order-list.container-drawer.other-container-images.title'
                  }
                />
              </Typography>
              <DocumentListWrapper>
                {pdfImagesList.map(document => {
                  return (
                    <DocumentCardWrapper
                      key={document.uniqueIdentifier}
                      id={`container-documents.button.download.${document.uniqueIdentifier}`}
                      onClick={() => handleDownload(document)}
                    >
                      <Icon size={24} variant="pdf" />
                      <Typography variant="p2" noWrap component="span">
                        {document.name || `img_${document.uniqueIdentifier}`}
                      </Typography>
                    </DocumentCardWrapper>
                  );
                })}
              </DocumentListWrapper>
            </MainWrapper>
          )}

          {downloadAllOnClick !== undefined && (
            <Button
              buttonType="linked"
              id={`${variant.toLowerCase()}.image-list.button.download-all`}
              size="large"
              disabled={isDownloading}
              onClick={handleDownloadAll}
              isSubmitting={isDownloading}
              submittingCaption={intl.formatMessage({
                id: 'customer-platform.shared.components.button.submitting-caption',
              })}
            >
              <Translate
                id={'customer-platform.shared.components.button.download-all'}
              />
            </Button>
          )}
        </MainWrapper>
      ) : (
        <ToastAlert
          icon={<RiImageLine size={24} />}
          message={
            <Translate id="customer-platform.request-details.order-list.container-drawer.images.empty" />
          }
          severity="info"
        />
      )}

      <ImagesLightbox
        slidesList={supportedImages.map(image => ({
          uuid: image.uniqueIdentifier,
          slideType: isFileTypeIncluded(
            image.fileType || '',
            Object.keys(SUPPORTED_MIME_IMAGE_FORMATS),
          )
            ? 'image'
            : 'placeholder',
        }))}
        isOpen={lightboxActive}
        closeLightbox={() => setLightboxActive(false)}
        imageIndex={selectedImageIndex}
        getImageURLFromId={elementId => getImageUrl(uuid, elementId, variant)}
      />
    </MainWrapper>
  );
};

export default ImageListWithMagnifier;
