import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
import { IMAGE_VARIANT, IMAGE_VARIANT_TYPE } from '@frontend/api';

/**
 * The flex and maxWidth pixels comes from:
 * {column_gap} * {number_of_gaps_per_row} / {number_of_cards_per_row}
 *
 * For 4 cards per row is: 12px * 3 spaces / 4 cards = 9px
 *
 * For 3 cards per row is: 12px * 2 spaces / 3 cards = 8px
 */
export const ImageCardWrapper = styled(MUIBox, {
  shouldForwardProp: prop =>
    !['cardsPerRow', 'variant'].includes(prop as string),
})<{ cardsPerRow?: number; variant?: IMAGE_VARIANT_TYPE }>(
  ({ cardsPerRow = 4, variant }) => ({
    display: 'flex',
    justifyContent: 'center',
    flex: `1 1 calc(100%/${cardsPerRow} - (12px * (${cardsPerRow} - 1) / ${cardsPerRow}))`,
    maxWidth: `calc(100%/${cardsPerRow} - (12px * (${cardsPerRow} - 1) / ${cardsPerRow}))`,
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '& > img': {
      width: '100%',
      height: 'auto',
      objectFit: variant === IMAGE_VARIANT.CONTAINER ? 'contain' : 'cover',
      aspectRatio: variant === IMAGE_VARIANT.CONTAINER ? 'auto' : 1,
    },
  }),
);

export const ImageCardOverlayWrapper = styled(ImageCardWrapper, {
  shouldForwardProp: prop => prop !== 'cardsPerRow',
})<{ cardsPerRow?: number }>(({ cardsPerRow = 4 }) => ({
  position: 'relative',
  '& > .MuiBox-root': {
    flex: `1 1 calc(100%/${cardsPerRow} - (12px * (${cardsPerRow} - 1) / ${cardsPerRow}))`,
    maxWidth: '100%',
  },
}));

export const OverlayWrapper = styled(MUIBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '0',
  width: '100%',
  height: '100%',
  background: '#00000071',
  pointerEvents: 'none',
});

export const LoadingWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));
