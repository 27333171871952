import { PageContainer, PageContainerWrapper } from './PageWrapper.css';
import { ReactElement } from 'react';

type PageWrapperProps = {
  children: ReactElement | Array<ReactElement>;
};

const PageWrapper = ({ children }: PageWrapperProps) => {
  return (
    <PageContainerWrapper>
      <PageContainer>{children}</PageContainer>
    </PageContainerWrapper>
  );
};

export default PageWrapper;
