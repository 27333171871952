import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import fileSaver from 'file-saver';

const useGetAllDocument = () => {
  const { apiUrl } = useContext(ApiContext);
  const downloadAllDocument = async (
    parentRecordId: string,
    parentRecordType: string,
    parentRecordPublicId: string,
  ) => {
    let url = '';
    switch (parentRecordType) {
      case 'request':
        url = `${apiUrl}/trading/requests/${parentRecordId}/documents/download/`;
        break;
      case 'order':
        url = `${apiUrl}/orders/${parentRecordId}/buyer-documents/download/`;
        break;
      case 'container':
        url = `${apiUrl}/orders/containers/${parentRecordId}/documents/download/`;
        break;
      default:
        break;
    }

    return axiosClient
      .get(url, { responseType: 'blob' })
      .then(response => {
        fileSaver.saveAs(
          response.data,
          `${parentRecordType}_${parentRecordPublicId}.zip`,
        );
      })
      .catch(error => error);
  };

  return { downloadAllDocument };
};
export default useGetAllDocument;
