import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const submitPutUnsubscribeEmail = (
  apiUrl: string,
  userId: string | undefined,
  token: string | undefined,
) => {
  return axiosClient.put(
    `${apiUrl}/accounts/user/${userId}/email/unsubscribe/${token}/`,
  );
};

const usePutUnsubscribeEmailQuery = (
  userId: string | undefined,
  token: string | undefined,
) => {
  const { apiUrl } = useContext(ApiContext);
  const QueryKey = ['unsubscribe', userId, token];
  return useQuery<
    AxiosResponse<unknown>,
    AxiosError<unknown>,
    AxiosResponse<unknown>
  >({
    queryFn: () => submitPutUnsubscribeEmail(apiUrl, userId, token),
    queryKey: QueryKey,
  });
};

export default usePutUnsubscribeEmailQuery;
