import { Stage } from '@frontend/ui-elements';

export const REQUEST_ORDER_CARD_STAGES: Stage[] = [
  {
    order: 0,
    label: 'Preparing',
  },
  {
    order: 1,
    label: 'Loading',
  },
  {
    order: 2,
    label: 'In transit',
  },
  {
    order: 3,
    label: 'Arrived',
  },
];
export const LISTING_ORDER_CARD_STAGES: Stage[] = [
  {
    order: 0,
    label: 'Signing deals',
  },
  {
    order: 1,
    label: 'Confirming details',
  },
  {
    order: 2,
    label: 'Loading',
  },
  {
    order: 3,
    label: 'Shipment completed',
  },
];
