import axiosClient from '../axiosClient';
import {
  ISellerOrderDownloadFileData,
  ISellerOrderDownloadFileVariant,
} from './downloadSellerOrderFiles.types';
import fileSaver from 'file-saver';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';

const useDownloadSellerOrderFiles = (
  orderFileVariant: ISellerOrderDownloadFileVariant,
  orderId: string,
) => {
  const { apiUrl } = useContext(ApiContext);

  const downloadSingleSellerOrderFile = async (
    file: ISellerOrderDownloadFileData,
  ) => {
    return await axiosClient
      .get(
        `${apiUrl}/orders/seller/${orderId}/${orderFileVariant}/${file.uniqueIdentifier}/download/`,
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        fileSaver.saveAs(
          response.data,
          file.fileName ?? `${file.uniqueIdentifier}.${file.fileType}`,
        );
      });
  };

  const downloadAllSellerOrderFiles = async () => {
    return await axiosClient
      .get(`${apiUrl}/orders/seller/${orderId}/${orderFileVariant}/download/`, {
        responseType: 'blob',
      })
      .then(response => {
        fileSaver.saveAs(
          response.data,
          `order_${orderId}_${orderFileVariant}.zip`,
        );
      })
      .catch(error => error);
  };

  return { downloadSingleSellerOrderFile, downloadAllSellerOrderFiles };
};

export default useDownloadSellerOrderFiles;
