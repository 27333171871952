import {
  ContentWrapper,
  LogoWrapper,
  MessageWrapper,
  PageContainer,
} from './EmailUnsubscription.css';
import { Button, Logo } from '@frontend/ui-elements';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { usePutUnsubscribeEmail } from '@frontend/api';
import { Translate } from '@frontend/translation';

export default function EmailUnsubscription() {
  const { userId, token } = useParams();
  const { isError, isPending, isSuccess } = usePutUnsubscribeEmail(
    userId,
    token,
  );
  const navigate = useNavigate();
  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {isPending && <CircularProgress data-testid="CircularProgress" />}
        <MessageWrapper>
          <Typography variant="h2">
            {isSuccess && (
              <Translate id="customer-platform.email-unsubscription.success" />
            )}
            {isError && (
              <Translate id="customer-platform.email-unsubscription.error" />
            )}
          </Typography>
          <Button
            buttonType="primary"
            id="customer-platform.email-unsubscription.button.back"
            size="large"
            onClick={handleBackHome}
          >
            <Translate id="customer-platform.email-unsubscription.button.back" />
          </Button>
        </MessageWrapper>
      </ContentWrapper>
    </PageContainer>
  );
}
