import { ToastAlert } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { useMatch } from 'react-router-dom';
import { ROUTE_NAMES } from '@frontend/utils';

export default function EmptyOrder() {
  const buyerDashboardMatch = useMatch(PLATFORM_ROUTES.buyerDashboard);
  const buyerDashboardRoutesMatch = useMatch(
    `${PLATFORM_ROUTES.buyerDashboard}/*`,
  );
  const buyerDashboardRouteMatched =
    buyerDashboardMatch || buyerDashboardRoutesMatch;
  const currentVariantType = buyerDashboardRouteMatched
    ? ROUTE_NAMES.REQUEST
    : ROUTE_NAMES.LISTING;

  return (
    <ToastAlert
      title={
        <Translate id="customer-platform.request-details.order-list.empty-order-title" />
      }
      message={
        <Translate
          id={`customer-platform.${currentVariantType}-details.order-list.empty-order-subtitle`}
        />
      }
      severity="info"
    />
  );
}
