import Step from './Step/Step';
import { StepperSt } from './StepsBar.css';
import { StepsBarProps } from './StepsBar.types';

export function StepsBar(props: StepsBarProps) {
  const { stages, ...rest } = props;

  return (
    <StepperSt {...rest}>
      {stages
        .sort((stageA, stageB) => stageA.order - stageB.order)
        .map((stage, index) => {
          const stepNumber = index + 1;
          const { activeStep } = rest;
          const isCompleted =
            activeStep === stages.length - 1 || activeStep > stage.order;
          return (
            <Step
              key={stage.order}
              number={stepNumber}
              label={stage.label}
              optional={stage.optional ?? undefined}
              last={index === stages.length - 1}
              completed={isCompleted}
            />
          );
        })}
    </StepperSt>
  );
}

export default StepsBar;
