import { styled } from '@frontend/theme';
import { Tabs as MUITabs } from '@mui/material';

export const TabsSt = styled(MUITabs)(({ theme }) => ({
  minHeight: '29px',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary[500],
    height: '3px',
  },
}));
