import { styled } from '@frontend/theme';
import { MenuItem as MenuItemBase } from '@frontend/ui-elements';
import {
  Box as MUIBox,
  Container as MUIContainer,
  Drawer as MUIDrawer,
} from '@mui/material';

export const BurgerDrawer = styled(MUIDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiPaper-root': {
    marginTop: '88px',
    width: '100%',
  },
}));

export const BurgerDrawerContainer = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '16px 20px 24px',
}));

export const BurgerMenuBox = styled(MUIBox)(({ theme }) => ({
  '& > .MuiMenuItem-root': {
    padding: '8px 0',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.neutral[100]}`,
    },
  },
}));

export const BurgerUserBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: 0,
}));

export const BurgerUserMenuBox = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const GuessButtonWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  width: '100%',

  '& > .MuiButton-root': {
    width: '100%',
  },
}));

export const MenuItem = styled(MenuItemBase)(() => ({
  gap: '8px',
  padding: '0 8px',
}));
