import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosClient from '../axiosClient';
import { ApiContext } from '../ApiContext/ApiContext';
import {
  IGetContainerDetailsResponseFailure,
  TGetContainerDetailsResponse,
} from './getContainerDetails.types';
import { getQueryKey } from './getContainerDetails.const';

const submitGetContainerDetails = (apiUrl: string, containerId: string) => {
  return axiosClient.get(`${apiUrl}/orders/containers/${containerId}/details/`);
};

const useGetContainerDetailsQuery = (containerId: string) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetContainerDetailsResponse>,
    AxiosError<IGetContainerDetailsResponseFailure>,
    AxiosResponse<TGetContainerDetailsResponse>
  >({
    queryFn: () => submitGetContainerDetails(apiUrl, containerId),
    queryKey: getQueryKey(containerId),
  });
};

export default useGetContainerDetailsQuery;
