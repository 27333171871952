import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Link, ToastAlert } from '@frontend/ui-elements';
import { useRequestEmailVerificationLink } from '@frontend/api';
import { useAuth } from '@frontend/auth';
import { Translate } from '@frontend/translation';
import { head } from 'lodash';
import { generateUrlFromLocation } from '@frontend/routing';
import { RiMailLine } from '@remixicon/react';
import useModal from '../Modals/useModal/useModal';
import EmailVerificationModal from '../Modals/EmailVerificationModal/EmailVerificationModal';
import { EmailText } from './EmailVerificationBanner.css';
import EmailVerificationLinkExpiredModal from '../Modals/EmailVerificationLinkExpiredModal/EmailVerificationLinkExpiredModal';

export default function EmailVerificationBanner() {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSuccessBannerVisible, setSuccessBannerVisible] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);
  const {
    openEmailVerificationModal,
    openEmailVerificationLinkExpiredModal,
    closeModal,
    isEmailVerificationModalOpen,
    isEmailVerificationLinkExpiredModalOpen,
  } = useModal();

  const {
    isSuccess: isRequestEmailVerificationSuccess,
    refetch: refetchEmailVerification,
  } = useRequestEmailVerificationLink();

  useEffect(() => {
    if (resetStatus && isRequestEmailVerificationSuccess) {
      openEmailVerificationModal();
      setResetStatus(false);
    }
  }, [
    isRequestEmailVerificationSuccess,
    openEmailVerificationModal,
    resetStatus,
  ]);

  useEffect(() => {
    if (location.state?.emailVerificationSuccess) {
      setSuccessBannerVisible(true);
      navigate(generateUrlFromLocation(location), { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location.state?.emailVerificationExpired) {
      openEmailVerificationLinkExpiredModal();
    }
  }, [location, openEmailVerificationLinkExpiredModal]);

  if (!currentUser) {
    return null;
  }
  const { email, emailVerified } = currentUser;

  const currentTitle = emailVerified ? (
    <Translate id="customer-platform.request-list.email-verification-status.complete.title" />
  ) : (
    <Translate id="customer-platform.request-list.email-verification-status.incomplete.title" />
  );

  const currentSubtitle = emailVerified ? (
    <Translate id="customer-platform.request-list.email-verification-status.complete.subtitle" />
  ) : (
    <Translate
      id="customer-platform.request-list.email-verification-status.incomplete.subtitle"
      values={{
        a: (chunks: string[]) => (
          <Link
            id="email-verification-banner.send-email"
            data-testid="test.email-verification-banner.send-email"
            color={theme.palette.neutral[500]}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleRefetchEmailVerification}
          >
            {head(chunks)}
          </Link>
        ),
        span: () => (
          <EmailText id="email-verification-banner.email">{email}</EmailText>
        ),
      }}
    />
  );

  const handleRefetchEmailVerification = () => {
    setResetStatus(true);
    refetchEmailVerification();
    if (isEmailVerificationLinkExpiredModalOpen) {
      handleCloseModal();
    }
  };
  const handleClose = () => {
    setSuccessBannerVisible(false);
  };
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <>
      {isSuccessBannerVisible && (
        <ToastAlert
          title={currentTitle}
          message={currentSubtitle}
          severity="success"
          onClose={handleClose}
        />
      )}
      {!emailVerified && (
        <ToastAlert
          title={currentTitle}
          message={currentSubtitle}
          severity="info"
          icon={<RiMailLine size={24} />}
        />
      )}
      {isEmailVerificationModalOpen && (
        <EmailVerificationModal isOpen={true} onClose={closeModal} />
      )}
      {isEmailVerificationLinkExpiredModalOpen && (
        <EmailVerificationLinkExpiredModal
          onClick={handleRefetchEmailVerification}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
