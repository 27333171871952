import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const OuterCircle = styled(MUIBox, {
  shouldForwardProp: prop => !['active', 'left'].includes(prop as string),
})<{ active?: boolean; left?: number }>(({ theme, active, left }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: left !== undefined ? 'absolute' : 'relative',
  left: left ? `calc(${left}% - 24px)` : 0,
  zIndex: 1,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: active
    ? theme.palette.primary[500]
    : theme.palette.primary[200],
}));

export const InnerCircle = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary[50],
}));
