import { Lightbox, RenderSlideFooterProps } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { imagesLightboxProps } from './ImagesLightbox.types';
import { LightboxSlideFooter } from './ImagesLightbox.css';
import { Typography } from '@mui/material';
import { includes } from 'lodash';
import { PlaceholderSlide } from '../ImageListWithMagnifier/ImageCardWithMagnifier/PlaceholderImage/PlaceholderImage';
import { useIntl } from 'react-intl';
import { RenderSlideProps } from 'yet-another-react-lightbox/dist/types';
import { theme } from '@frontend/theme';
import { trackClickEvent, trackModalViewEvent } from '@frontend/tracking';
import { RiZoomInLine } from '@remixicon/react';
import { RiZoomOutLine } from '@remixicon/react';
import { RiArrowLeftSLine } from '@remixicon/react';
import { RiArrowRightSLine } from '@remixicon/react';

export default function ImagesLightbox({
  slidesList,
  isOpen,
  imageIndex,
  closeLightbox,
  getImageURLFromId,
}: imagesLightboxProps) {
  const intl = useIntl();

  const getCurrentImageIndex = (uuid: string) => {
    return slidesList.findIndex(element => {
      return getImageURLFromId(element.uuid) === uuid;
    });
  };

  const renderFooter = (props: RenderSlideFooterProps) => (
    <LightboxSlideFooter>
      <Typography variant="h3" color={theme.palette.common.white}>
        {`${getCurrentImageIndex(props.slide.src) + 1}/${slidesList.length}`}
      </Typography>
    </LightboxSlideFooter>
  );

  const renderSlide = (props: RenderSlideProps) =>
    props.slide.type !== 'image' ? (
      <PlaceholderSlide
        icon={'hourglass'}
        caption={intl.formatMessage({
          id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-loading',
        })}
      />
    ) : undefined;

  return (
    <Lightbox
      open={isOpen}
      close={() => closeLightbox()}
      plugins={[Zoom]}
      zoom={{ maxZoomPixelRatio: 2 }}
      index={imageIndex}
      on={{
        entered() {
          trackModalViewEvent({
            elementId: `images.magnifier.open`,
          });
        },
        exited() {
          trackClickEvent({
            elementId: `images.magnifier.close`,
          });
        },
      }}
      styles={{
        container: { backgroundColor: 'rgba(0, 0, 0, .5)' },
        slide: { padding: '10vh' },
      }}
      slides={slidesList.map(slide => {
        return {
          src: getImageURLFromId(slide.uuid),
          type: includes(['image', undefined], slide.slideType)
            ? 'image'
            : undefined,
        };
      })}
      render={{
        slideFooter: renderFooter,
        slide: renderSlide,
        iconZoomIn: () => (
          <RiZoomInLine
            aria-label={intl.formatMessage({
              id: 'customer-platform.images.magnifier.zoom-in',
            })}
            onClick={() =>
              trackClickEvent({
                elementId: `images.magnifier.zoom-in`,
              })
            }
          />
        ),
        iconZoomOut: () => (
          <RiZoomOutLine
            aria-label={intl.formatMessage({
              id: 'customer-platform.images.magnifier.zoom-out',
            })}
            onClick={() =>
              trackClickEvent({
                elementId: `images.magnifier.zoom-out`,
              })
            }
          />
        ),
        iconPrev: () => (
          <RiArrowLeftSLine
            aria-label={intl.formatMessage({
              id: 'customer-platform.images.magnifier.previous',
            })}
            onClick={() =>
              trackClickEvent({
                elementId: `images.magnifier.previous`,
              })
            }
          />
        ),
        iconNext: () => (
          <RiArrowRightSLine
            aria-label={intl.formatMessage({
              id: 'customer-platform.images.magnifier.next',
            })}
            onClick={() =>
              trackClickEvent({
                elementId: `images.magnifier.next`,
              })
            }
          />
        ),
      }}
    />
  );
}
