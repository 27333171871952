import { ReactNode } from 'react';
import {
  ICreateSessionRequest,
  IEmailVerificationPayload,
  IGetCurrentUserResponse,
} from '@frontend/api';

export interface IAuthContext {
  isAuthenticated: boolean;
  login?: (createSessionRequest: ICreateSessionRequest) => void;
  emailVerification?: (
    emailVerificationPayload: IEmailVerificationPayload,
  ) => void;
  logout?: () => void;
  checkSession?: () => void;
  currentUser?: IGetCurrentUserResponse;
  getCurrentUser?: () => void;
  authenticationInProgress: boolean;
  checkSessionInProgress: boolean;
  isEmailVerificationInProgress: boolean;
  isEmailVerificationNotStarted: boolean;
  isEmailVerificationSuccess: boolean;
  isEmailVerificationFail: boolean;
  isCurrentUserBuyer: boolean;
  isCurrentUserSeller: boolean;
  isCurrentOperationModeBuyer: boolean;
  isCurrentOperationModeSeller: boolean;
}

export interface IAuthContextProvider {
  children: ReactNode;
}

export interface IState {
  currentUser?: IGetCurrentUserResponse;
  authenticationInProgress: boolean;
  authenticationSucceeded: boolean;
  authenticationFailed: boolean;
  checkSessionInProgress: boolean;
  checkSessionSucceeded: boolean;
  checkSessionFailed: boolean;
  emailVerificationNotStarted: boolean;
  emailVerificationInProgress: boolean;
  emailVerificationSucceeded: boolean;
  emailVerificationFailed: boolean;
}

export type TAction =
  | ILoginProgressAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILogoutAction
  | IEmailVerificationProgressAction
  | IEmailVerificationSuccessAction
  | IEmailVerificationFailedAction
  | ICheckSessionProgressAction;

export const AUTH_ACTION_TYPES = {
  LOGIN_SUCCESS: 'login_success',
  LOGIN_PROGRESS: 'login_progress',
  LOGIN_FAILED: 'login_failed',
  CHECK_SESSION_PROGRESS: 'check_session_progress',
  LOGOUT: 'logout',
  EMAIL_VERIFICATION_PROGRESS: 'email_verification_progress',
  EMAIL_VERIFICATION_FAILED: 'email_verification_failed',
  EMAIL_VERIFICATION_SUCCESS: 'email_verification_success',
} as const;

interface ILoginSuccessAction {
  type: typeof AUTH_ACTION_TYPES.LOGIN_SUCCESS;
  payload: IGetCurrentUserResponse;
}

interface ILoginProgressAction {
  type: typeof AUTH_ACTION_TYPES.LOGIN_PROGRESS;
}

interface ILoginFailedAction {
  type: typeof AUTH_ACTION_TYPES.LOGIN_FAILED;
}

interface ICheckSessionProgressAction {
  type: typeof AUTH_ACTION_TYPES.CHECK_SESSION_PROGRESS;
}

interface ILogoutAction {
  type: typeof AUTH_ACTION_TYPES.LOGOUT;
}

interface IEmailVerificationProgressAction {
  type: typeof AUTH_ACTION_TYPES.EMAIL_VERIFICATION_PROGRESS;
  payload?: IGetCurrentUserResponse;
}
interface IEmailVerificationFailedAction {
  type: typeof AUTH_ACTION_TYPES.EMAIL_VERIFICATION_FAILED;
  payload?: IGetCurrentUserResponse;
}
interface IEmailVerificationSuccessAction {
  type: typeof AUTH_ACTION_TYPES.EMAIL_VERIFICATION_SUCCESS;
  payload?: IGetCurrentUserResponse;
}
