import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const Separator = styled(MUIBox)(({ theme }) => ({
  width: '100%',
  height: '1px',
  background: theme.palette.neutral[50],
}));

export default Separator;
