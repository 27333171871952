import { AlertTitleSt } from './ToastAlertTitle.css';
import { ToastAlertTitleProps } from './ToastAlertTitle.types';
import { Typography } from '@mui/material';

export default function ToastAlertTitle(props: ToastAlertTitleProps) {
  const { children, ...rest } = props;
  return (
    <AlertTitleSt {...rest}>
      <Typography component="h3" variant="p1">
        {children}
      </Typography>
    </AlertTitleSt>
  );
}
