import { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TScrollToSection } from './useRequestOrderCardScroll.types';

export function useRequestOrderCardScroll({
  enableScroll,
}: {
  enableScroll: boolean;
}) {
  const documentsSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const billOfLadingSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const containerSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const orderCardRef: RefObject<HTMLDivElement> = useRef(null);

  const location = useLocation();
  const queryParamsSection = new URLSearchParams(location?.search).get(
    'scrollToSection',
  ) as TScrollToSection | null;

  useEffect(() => {
    if (orderCardRef.current && enableScroll) {
      let scrollToRef: RefObject<HTMLDivElement> | null = null;

      if (queryParamsSection === 'documents') {
        scrollToRef = documentsSectionRef;
      } else if (queryParamsSection === 'billOfLading') {
        scrollToRef = billOfLadingSectionRef;
      } else if (queryParamsSection === 'containers') {
        scrollToRef = containerSectionRef;
      }
      if (orderCardRef.current) {
        const refToScroll = scrollToRef?.current || orderCardRef.current;
        refToScroll.scrollIntoView({
          behavior: 'instant',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }, [enableScroll, queryParamsSection]);

  return {
    documentsSectionRef,
    orderCardRef,
    billOfLadingSectionRef,
    containerSectionRef,
  };
}
