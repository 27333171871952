import { IDocument, IDocumentContext } from './getDocument.types';

export const mockedDocument: IDocument = {
  uniqueIdentifier: 'xxx-xxx-xxxx-xxx',
  fileType: 'pdf',
  title: null,
  subTitle: null,
  documentType: 'request.document.sales_order',
};
export const mockedDocumentOther: IDocument = {
  uniqueIdentifier: 'xxx-xxx-xxxx-123',
  fileType: 'pdf',
  title: 'Other Document',
  subTitle: 'Subtitle',
  documentType: 'request.document.other',
};
export const mockedDocuments: IDocumentContext[] = [
  {
    document: { ...mockedDocument },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: { ...mockedDocumentOther },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
];
export const mockedDocumentsPlus5: IDocumentContext[] = [
  {
    document: { ...mockedDocument },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: {
      ...{ ...mockedDocument, uniqueIdentifier: 'xxx-xxx-xxxx-1234a' },
    },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: {
      ...{ ...mockedDocument, uniqueIdentifier: 'xxx-xxx-xxxx-1234b' },
    },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: { ...mockedDocumentOther },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: {
      ...{ ...mockedDocumentOther, uniqueIdentifier: 'xxx-xxx-xxxx-1234c' },
    },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
  {
    document: {
      ...{ ...mockedDocumentOther, uniqueIdentifier: 'xxx-xxx-xxxx-1234d' },
    },
    parentRecordType: 'request',
    parentRecordId: 'requestId',
    parentRecordPublicId: 'requestPublicId',
  },
];
export const emptyMockedDocuments: IDocumentContext[] = [];

export const mockedDocumentContext: IDocumentContext = {
  parentRecordPublicId: '123',
  parentRecordId: 'id',
  parentRecordType: 'request',
  document: mockedDocument,
};

export const mockedDocumentOtherContext: IDocumentContext = {
  parentRecordPublicId: '1234',
  parentRecordId: 'id',
  parentRecordType: 'request',
  document: mockedDocumentOther,
};
