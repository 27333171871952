import { useEffect, useRef } from 'react';
import { trackScrollEvent } from '../tracking/tracking';

const useScrollTracking = () => {
  /* We use refs instead of states to avoid re-renders when values are updated */

  /* Last tracked range (so we can avoid it) */
  const lastTrackedPageSlice = useRef(-1);
  /* Direction control variable */
  const lastScrollPosition = useRef(0);

  useEffect(() => {
    const getScrollPercentage = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      const contentScrolled = scrollTop;
      const windowArea = scrollHeight - clientHeight;

      return (contentScrolled / windowArea) * 100;
    };

    const scrollTracker = () => {
      const scrollPercent = getScrollPercentage();
      const currentPageSlice = Math.floor(scrollPercent / 20);
      const scrollDirection =
        window.scrollY > lastScrollPosition.current ? 'down' : 'up';

      if (currentPageSlice !== lastTrackedPageSlice.current) {
        trackScrollEvent({
          scrollDirection,
          scrolledPercentage: currentPageSlice * 20,
        });

        lastTrackedPageSlice.current = currentPageSlice;
      }

      lastScrollPosition.current = window.scrollY;
    };

    window.addEventListener('scroll', scrollTracker);

    return () => {
      window.removeEventListener('scroll', scrollTracker);
    };
  }, [lastTrackedPageSlice, lastScrollPosition]);
};

export default useScrollTracking;
