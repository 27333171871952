import { Reducer } from 'react';
import { AUTH_ACTION_TYPES, TAction, IState } from './AuthContext.types';

export const authReducer: Reducer<IState, TAction> = (state, action) => {
  const { type } = action;
  switch (type) {
    case AUTH_ACTION_TYPES.CHECK_SESSION_PROGRESS:
      return {
        ...state,
        currentUser: undefined,
        checkSessionInProgress: true,
        checkSessionSucceeded: false,
        checkSessionFailed: false,
      };
    case AUTH_ACTION_TYPES.LOGIN_PROGRESS:
      return {
        ...state,
        currentUser: undefined,
        authenticationInProgress: true,
        authenticationSucceeded: false,
        authenticationFailed: false,
      };
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        authenticationInProgress: false,
        authenticationSucceeded: true,
        authenticationFailed: false,
        checkSessionInProgress: false,
        checkSessionSucceeded: true,
        checkSessionFailed: false,
      };
    case AUTH_ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        currentUser: undefined,
        authenticationInProgress: false,
        authenticationSucceeded: false,
        authenticationFailed: true,
        checkSessionInProgress: false,
        checkSessionSucceeded: false,
        checkSessionFailed: true,
      };
    case AUTH_ACTION_TYPES.LOGOUT:
      return {
        ...state,
        currentUser: undefined,
        authenticationInProgress: false,
        authenticationSucceeded: false,
        authenticationFailed: false,
        checkSessionInProgress: false,
        checkSessionSucceeded: false,
        checkSessionFailed: false,
      };
    case AUTH_ACTION_TYPES.EMAIL_VERIFICATION_PROGRESS:
      return {
        ...state,
        emailVerificationNotStarted: false,
        emailVerificationInProgress: true,
        emailVerificationSucceeded: false,
        emailVerificationFailed: false,
      };
    case AUTH_ACTION_TYPES.EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        emailVerificationNotStarted: false,
        emailVerificationInProgress: false,
        emailVerificationSucceeded: false,
        emailVerificationFailed: true,
      };
    case AUTH_ACTION_TYPES.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationNotStarted: false,
        emailVerificationInProgress: false,
        emailVerificationSucceeded: true,
        emailVerificationFailed: false,
      };
    default:
      return state;
  }
};
