import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { trackClickEvent } from '@frontend/tracking';
import {
  ButtonPropsVariantOverrides,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
  LoadingIndicator,
  LoadingWrapper,
  InlineLoadingWrapper,
  MUIButtonSt,
} from './Button.css';
import { ButtonProps } from './Button.types';

export default function Button({
  buttonType = 'primary',
  children,
  href,
  id = 'button',
  linkedButtonColorValue,
  onClick,
  size = 'medium',
  isSubmitting = false,
  submittingCaption = '',
  ...props
}: ButtonProps) {
  const intl = useIntl();

  const BUTTON_TYPOGRAPHIES = {
    small: 'caption1',
    medium: 'p2',
    large: 'p1',
  };
  const BUTTON_VARIANTS = {
    primary: 'contained',
    secondary: 'outlined',
    linked: 'text',
  };

  const buttonVariant = (BUTTON_VARIANTS[buttonType] ||
    'contained') as OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  const typographyVariant = (BUTTON_TYPOGRAPHIES[size] ||
    'p2') as OverridableStringUnion<
    'caption1' | 'p1' | 'p2',
    TypographyPropsVariantOverrides
  >;

  const getStringFromChildren = (children: ReactElement | string) => {
    if (typeof children === 'string') {
      return children;
    }
    return intl.formatMessage({
      id: get(children, 'props.id', 'unknown'),
    });
  };

  const handleClick = (event: never) => {
    trackClickEvent({
      elementId: id,
      elementText: getStringFromChildren(children as ReactElement) as string,
      href,
    });

    if (onClick !== undefined) {
      onClick(event);
    }
  };

  const ProgressIndicator = () => {
    return buttonType === 'linked' ? (
      <InlineLoadingWrapper>
        <Typography variant={typographyVariant} component="span">
          {submittingCaption}
        </Typography>
        <LoadingIndicator inline={true} />
      </InlineLoadingWrapper>
    ) : (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );
  };

  return (
    <MUIButtonSt
      {...props}
      disableRipple
      disableFocusRipple
      disableElevation
      href={href}
      disabled={isSubmitting || props.disabled}
      id={id}
      linkedButtonColorValue={linkedButtonColorValue}
      onClick={handleClick}
      size={size}
      variant={buttonVariant}
    >
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <Typography variant={typographyVariant} component="span">
          {children}
        </Typography>
      )}
    </MUIButtonSt>
  );
}
