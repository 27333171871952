import { styled } from '@frontend/theme';
import { MenuItem as MUIMenuItem, Paper as MUIPaper } from '@mui/material';
import TextField from '../TextField/TextField';

export const MenuItemSt = styled(MUIMenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    color: theme.palette.neutral[700],
    fontSize: theme.typography.p3.fontSize,
    fontStyle: theme.typography.p3.fontStyle,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.p3.lineHeight,
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.neutral[50],
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary[50],
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral[50],
    },
  },
}));

export const PaperSt = styled(MUIPaper)(({ theme }) => ({
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.primary[50]}`,
  borderRadius: 0,
  boxShadow: theme.shadows[2],
}));

export const TextFieldSt = styled(TextField)(({ theme }) => ({
  // Label
  '& > .MuiInputLabel-root': {
    color: theme.palette.neutral[500],
    fontSize: theme.typography.p3.fontSize,
    fontStyle: theme.typography.p3.fontStyle,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.p3.lineHeight,
    transform: 'translate(16px, 13px) scale(1)',

    '&.Mui-disabled': {
      color: theme.palette.neutral[100],
    },
    '&.Mui-focused': {
      color: theme.palette.primary[500],
      '&.Mui-error': {
        color: theme.palette.error[500],
      },
      '&.MuiInputLabel-animated': {
        transform: 'translate(16px, -14px) scale(1)',
      },
    },
    '&.MuiFormLabel-filled': {
      color: theme.palette.neutral[500],
      '&.Mui-error': {
        color: theme.palette.error[500],
      },
      '&.MuiInputLabel-animated': {
        transform: 'translate(16px, -14px) scale(1)',
      },
    },
  },

  // HelperText
  '& .MuiFormHelperText-root': {
    fontSize: theme.typography.p3.fontSize,
    fontStyle: theme.typography.p3.fontStyle,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.p3.lineHeight,
    marginTop: '4px',
    marginRight: '12px',
    marginLeft: '16px',

    '&.Mui-disabled': {
      color: theme.palette.neutral[300],
    },
    '&.Mui-error': {
      color: theme.palette.error[500],
    },
  },

  // Select Box
  '& > .MuiInputBase-root': {
    borderRadius: 0,

    '.MuiSelect-icon': {
      color: theme.palette.neutral[700],
      top: 'calc(50% - 0.6em)',
    },

    '& > .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.neutral[200],

      '& legend': {
        fontSize: '16px',
      },
    },
    '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.primary[500],
    },
    '&.Mui-disabled': {
      '& > .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.neutral[100],
      },
      '& > .MuiSelect-icon': {
        color: theme.palette.neutral[300],
      },
    },
    '&.Mui-error': {
      '& > .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error[500],
      },
      '& > .MuiSelect-icon': {
        color: theme.palette.error[500],
      },
    },

    '& > .MuiSelect-select.MuiInputBase-input': {
      fontSize: theme.typography.p3.fontSize,
      fontStyle: theme.typography.p3.fontStyle,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.p3.lineHeight,
      padding: '12px 12px 12px 16px',
    },
  },
}));
