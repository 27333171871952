import { Icon } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  PlaceholderImageWrapper,
  PlaceholderSlideWrapper,
} from './PlaceholderImage.css';
import { PlaceholderImageProps } from './PlaceholderImage.types';

export default function PlaceholderImage({
  icon,
  caption,
  onClick,
}: PlaceholderImageProps) {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <PlaceholderImageWrapper data-testid="placeholder-image" onClick={onClick}>
      {icon && (
        <Icon
          color={theme.palette.neutral[500]}
          size={24}
          variant={icon}
          aria-label={intl.formatMessage({
            id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-alt',
          })}
        />
      )}
      <Typography variant="p2">{caption}</Typography>
    </PlaceholderImageWrapper>
  );
}

export function PlaceholderSlide({ icon, caption }: PlaceholderImageProps) {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <PlaceholderSlideWrapper data-testid="placeholder-image">
      {icon && (
        <Icon
          color={theme.palette.neutral[500]}
          size={54}
          variant={icon}
          aria-label={intl.formatMessage({
            id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-alt',
          })}
        />
      )}
      <Typography variant="p1">{caption}</Typography>
    </PlaceholderSlideWrapper>
  );
}
