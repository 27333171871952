import { Link, LinkProps, useHref } from 'react-router-dom';
import { trackClickEvent } from '@frontend/tracking';

export default function RouterLink({
  children,
  id = 'link',
  onClick,
  to,
  ...rest
}: LinkProps) {
  const href = useHref(to);

  const handleTrackingClick = (event: never) => {
    trackClickEvent({
      elementId: id,
      href: href,
    });

    if (onClick !== undefined) {
      onClick(event);
    }
  };

  return (
    <Link {...rest} id={id} to={to} onClick={handleTrackingClick}>
      {children}
    </Link>
  );
}
