import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IUpdateOperationModeError } from './updateOperationMode.types';

const submitOperationMode = (apiUrl: string) => {
  return axiosClient.patch(`${apiUrl}/accounts/user/switch-operation-mode/`);
};

const useUpdateOperationMode = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<AxiosResponse, AxiosError<IUpdateOperationModeError>>({
    mutationFn: () => submitOperationMode(apiUrl),
  });
};

export default useUpdateOperationMode;
