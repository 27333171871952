import { useState, useEffect, useRef } from 'react';

const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie.split(';').map(cookie => {
    const [key, ...valueParts] = cookie.trim().split('=');
    return { key, value: valueParts.join('=') };
  });

  return cookies.find(cookie => cookie.key === name)?.value;
};

const useCookieConsent = () => {
  const [isCookieScriptLoaded, setIsCookieScriptLoaded] =
    useState<boolean>(false);
  const [consentCategories, setConsentCategories] = useState<string[]>([]);
  const categoriesRef = useRef<string[]>([]);

  useEffect(() => {
    const getCookieConsent = () => {
      const cookieConsent = getCookie('CookieScriptConsent');

      if (cookieConsent) {
        setIsCookieScriptLoaded(true);

        try {
          const parsedConsent = JSON.parse(cookieConsent);

          if (parsedConsent && typeof parsedConsent === 'object') {
            const categories: string[] =
              'categories' in parsedConsent
                ? typeof parsedConsent['categories'] === 'string'
                  ? JSON.parse(parsedConsent['categories'])
                  : parsedConsent['categories']
                : [];

            if (
              JSON.stringify(categoriesRef.current) !==
              JSON.stringify(categories)
            ) {
              categoriesRef.current = categories;
              setConsentCategories(categories);
            }
          }
        } catch (error) {
          console.error('Error parsing cookie consent: ', error);
        }
      }
    };

    getCookieConsent();

    const observer = new MutationObserver(getCookieConsent);
    observer.observe(document, {
      subtree: true,
      childList: true,
    });

    return () => observer.disconnect();
  }, []);

  return { consentCategories, isCookieScriptLoaded };
};

export default useCookieConsent;
