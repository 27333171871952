import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { Button } from '@frontend/ui-elements';
import { ActionListWrapper } from './DatePickerActionList.css';

export default function DatePickerActionList(props: PickersActionBarProps) {
  const { actions, onAccept, onCancel, onClear, onSetToday } = props;

  const actionMapping = {
    cancel: { text: 'Cancel', event: onCancel, order: 1 },
    accept: { text: 'OK', event: onAccept, order: 2 },
    clear: { text: 'Clear', event: onClear, order: 3 },
    today: { text: 'Today', event: onSetToday, order: 4 },
  };

  return actions && actions?.length !== 0 ? (
    <ActionListWrapper>
      {actions
        .sort(
          (actionA, actionB) =>
            actionMapping[actionA].order - actionMapping[actionB].order,
        )
        .map(action => {
          return action in actionMapping ? (
            <Button
              key={action}
              buttonType={action === 'cancel' ? 'linked' : 'primary'}
              id={`datepicker-action-list.button.${action}`}
              size="small"
              onClick={actionMapping[action].event}
            >
              {actionMapping[action].text}
            </Button>
          ) : null;
        })}
    </ActionListWrapper>
  ) : undefined;
}
