import { LoaderWrapper } from './FullPageLoader.css';
import { CircularProgress } from '@mui/material';

export default function FullPageLoader() {
  return (
    <LoaderWrapper>
      <CircularProgress size={160} data-testid="CircularProgress" />
    </LoaderWrapper>
  );
}
