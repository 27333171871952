import { MainWrapper } from './ListingOrderCardDetails.css';
import { IListingOrderCardDetails } from './ListingOrderCardDetails.types';
import OrderBasicDetails from './OrderBasicDetails/OrderBasicDetails';
import OrderDocuments from './OrderDocuments/OrderDocuments';
import OrderPhotos from './OrderPhotos/OrderPhotos';

export default function ListingOrderCardDetails({
  order,
  refs: { orderBasicDetailsSectionRef },
  onOrderUpdate,
}: IListingOrderCardDetails) {
  return (
    <MainWrapper>
      <OrderBasicDetails
        order={order}
        orderBasicDetailsSectionRef={orderBasicDetailsSectionRef}
      />
      <OrderPhotos
        orderId={order.uuid}
        alreadyUploadedFiles={order.photos}
        onOrderUpdate={onOrderUpdate}
      />
      <OrderDocuments
        orderId={order.uuid}
        alreadyUploadedFiles={order.documents}
        onOrderUpdate={onOrderUpdate}
      />
    </MainWrapper>
  );
}
