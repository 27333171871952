import { Link as MUILink, LinkOwnProps } from '@mui/material';
import { trackClickEvent } from '@frontend/tracking';

interface LinkProps extends LinkOwnProps {
  href?: string;
  id?: string;
  onClick?: () => void;
  target?: string;
  rel?: string;
}

export default function Link({
  children,
  href,
  id = 'link',
  onClick,
  ...rest
}: LinkProps) {
  const handleTrackingClick = (_: never) => {
    trackClickEvent({
      elementId: id,
      href: href,
    });
    onClick && onClick();
  };

  return (
    <MUILink
      {...rest}
      href={href}
      id={id}
      sx={{
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={handleTrackingClick}
    >
      {children}
    </MUILink>
  );
}
