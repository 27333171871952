import { forwardRef, SyntheticEvent } from 'react';
import { trackClickEvent } from '@frontend/tracking';
import { AccordionProps } from '@mui/material';
import { MUIAccordionSt } from './Accordion.css';

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ children, id = 'accordion', onChange, ...rest }, ref) => {
    const handleTrackingClick = (
      event: SyntheticEvent<Element, Event>,
      expanded: boolean,
    ) => {
      if (onChange !== undefined) {
        trackClickEvent({
          elementId: id,
        });

        onChange(event, expanded);
      }
    };

    return (
      <MUIAccordionSt
        id={id}
        onChange={handleTrackingClick}
        ref={ref}
        {...rest}
      >
        {children}
      </MUIAccordionSt>
    );
  },
);

export default Accordion;
