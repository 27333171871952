import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { useLocation } from 'react-router-dom';
import {
  GuessButtonWrapper,
  Menu,
  MenuItem,
  UserIconButton,
  UserNavbar,
} from './HeaderDesktopMenu.css';
import { HEADER_VARIANTS } from '../PlatformHeader.types';
import IHeaderDesktopMenu from './HeaderDesktopMenu.types';
import {
  RiArrowRightUpLine,
  RiLogoutBoxRLine,
  RiExchangeFundsLine,
} from '@remixicon/react';
import UserBox from '../UserBox/UserBox';

export default function HeaderDesktopMenu({
  closeElement,
  currentOpenElement,
  isDropdownMenuOpen,
  openDropdownMenu,
  openFormDrawer,
  openLogoutModal,
  switchOperationMode,
  variant,
}: IHeaderDesktopMenu) {
  const { pathname } = useLocation();
  const pathnameIsBuyMetal = pathname === PLATFORM_ROUTES.buyMetal;
  const pathnameIsNotSellMetal = pathname !== PLATFORM_ROUTES.sellMetal;

  const BuyMetalButton = () => (
    <Button
      buttonType="primary"
      endIcon={<RiArrowRightUpLine size={24} />}
      id="user-header.button.go-to-buy-metal"
      href={PLATFORM_ROUTES.buyMetal}
      size="large"
    >
      <Translate id="customer-platform.header.user.menu.cta.guest-primary-cta" />
    </Button>
  );

  const OpenVariantFormDrawerButton = () => {
    const VARIANT_BUTTON_TYPE = {
      [HEADER_VARIANTS.BUYER]: 'request',
      [HEADER_VARIANTS.SELLER]: 'listing',
    };
    const getVariantType = VARIANT_BUTTON_TYPE[variant] || '';

    return (
      <Button
        buttonType="primary"
        endIcon={<RiArrowRightUpLine size={24} />}
        id={`user-header.button.open-create-${getVariantType}-drawer`}
        onClick={openFormDrawer}
        size="large"
      >
        <Translate
          id={`customer-platform.header.user.menu.cta.create-${getVariantType}`}
        />
      </Button>
    );
  };

  const SellMetalButton = () => (
    <Button
      buttonType={pathnameIsBuyMetal ? 'primary' : 'secondary'}
      endIcon={
        pathnameIsBuyMetal ? <RiArrowRightUpLine size={24} /> : undefined
      }
      id="user-header.button.go-to-sell-metal"
      href={PLATFORM_ROUTES.sellMetal}
      size="large"
    >
      <Translate id="customer-platform.header.user.menu.cta.guest-secondary-cta" />
    </Button>
  );

  return (
    <UserNavbar>
      {variant === HEADER_VARIANTS.GUEST && (
        <GuessButtonWrapper>
          {pathnameIsNotSellMetal && <SellMetalButton />}
          {!pathnameIsBuyMetal && <BuyMetalButton />}
        </GuessButtonWrapper>
      )}

      {[HEADER_VARIANTS.BUYER, HEADER_VARIANTS.SELLER].includes(variant) && (
        <>
          <OpenVariantFormDrawerButton />
          <UserIconButton
            id="user-header.icon-button.open-user-box"
            onClick={openDropdownMenu}
          >
            <UserBox open={isDropdownMenuOpen} variant={variant} />
          </UserIconButton>
          <Menu
            anchorEl={
              isDropdownMenuOpen
                ? (currentOpenElement as HTMLElement)
                : undefined
            }
            onClose={closeElement}
            open={isDropdownMenuOpen}
          >
            <MenuItem
              icon={<RiExchangeFundsLine size={16} />}
              id="user-header.menu-item.switch-role"
              onClick={switchOperationMode}
            >
              <Translate
                id={
                  variant === HEADER_VARIANTS.BUYER
                    ? 'customer-platform.header.user.menu.menu-item.switch-to-seller-role'
                    : 'customer-platform.header.user.menu.menu-item.switch-to-buyer-role'
                }
              />
            </MenuItem>
            <MenuItem
              icon={<RiLogoutBoxRLine size={16} />}
              id="user-header.menu-item.logout"
              onClick={openLogoutModal}
            >
              <Translate id="customer-platform.header.user.menu.menu-item.log-out" />
            </MenuItem>
          </Menu>
        </>
      )}
    </UserNavbar>
  );
}
