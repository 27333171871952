import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getQueryKey } from './getRequestOrderList.util';
import {
  IGetRequestOrderListResponseFailure,
  TGetRequestOrderListResponse,
} from './getRequestOrderList.types';

const submitGetRequestOrderList = (apiUrl: string, requestId: string) => {
  return axiosClient.get(`${apiUrl}/orders/buyer?request-uuid=${requestId}`);
};

const useRequestGetOrderListQuery = (requestId: string) => {
  const { apiUrl } = useContext(ApiContext);

  return useQuery<
    AxiosResponse<TGetRequestOrderListResponse>,
    AxiosError<IGetRequestOrderListResponseFailure>,
    AxiosResponse<TGetRequestOrderListResponse>
  >({
    queryFn: () => submitGetRequestOrderList(apiUrl, requestId),
    queryKey: getQueryKey(requestId),
  });
};

export default useRequestGetOrderListQuery;
