import { styled } from '@frontend/theme';
import { Box as MUIBox, Button as MUIButton, keyframes } from '@mui/material';
import { IButtonSt } from './Button.types';

export const MUIButtonSt = styled(MUIButton, {
  shouldForwardProp: prop => prop !== 'linkedButtonColorValue',
})<IButtonSt>(({ theme, linkedButtonColorValue, isSubmitting }) => {
  const hoverColorMap = {
    primary: theme.palette.primary[500],
    black: theme.palette.neutral[500],
    white: theme.palette.common.white,
  };

  const focusColorMap = {
    primary: theme.palette.primary[700],
    black: theme.palette.neutral[700],
    white: theme.palette.common.white,
  };

  return {
    borderRadius: 0,
    textTransform: 'none',
    gap: '8px',
    '&.MuiButton-contained': {
      backgroundColor: isSubmitting
        ? theme?.palette.neutral[50]
        : theme?.palette.secondary[500],
      color: isSubmitting
        ? theme?.palette.neutral[300]
        : theme?.palette.neutral[500],
      '&:hover': {
        backgroundColor: theme?.palette.secondary[600],
        boxShadow: theme?.shadows[4],
      },
      '&:focus': {
        backgroundColor: theme?.palette.secondary[700],
        boxShadow: theme?.shadows[4],
      },
      '&:disabled': {
        backgroundColor: theme?.palette.neutral[50],
        color: theme?.palette.neutral[300],
      },

      '&.MuiButton-containedSizeSmall': {
        height: '32px',
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      '&.MuiButton-containedSizeMedium': {
        height: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      '&.MuiButton-containedSizeLarge': {
        height: '48px',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },

    '&.MuiButton-outlined': {
      borderColor: theme?.palette.primary[500],
      color: theme?.palette.primary[500],
      backgroundColor: 'transparent',
      '&:hover': {
        borderColor: theme?.palette.primary[600],
        boxShadow: theme?.shadows[3],
      },
      '&:focus': {
        borderColor: theme?.palette.primary[700],
      },
      '&:disabled': {
        borderColor: theme?.palette.neutral[100],
        color: theme?.palette.neutral[100],
      },
      '&.MuiButton-outlinedSizeSmall': {
        height: '32px',
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      '&.MuiButton-outlinedSizeMedium': {
        height: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      '&.MuiButton-outlinedSizeLarge': {
        height: '48px',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },

    '&.MuiButton-text': {
      textDecoration: 'none',
      color:
        (linkedButtonColorValue && hoverColorMap[linkedButtonColorValue]) ||
        theme?.palette.primary[500],
      padding: 0,
      minWidth: 'auto',

      '& > span': {
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: '1px',
          left: 0,
          width: 0,
          height: '1px',
          backgroundColor:
            (linkedButtonColorValue && hoverColorMap[linkedButtonColorValue]) ||
            theme?.palette.primary[500],
          transformOrigin: 'left',
          transition: 'width 0.5s ease-in-out',
        },
      },

      '&:hover': {
        backgroundColor: 'transparent',
        color:
          (linkedButtonColorValue && hoverColorMap[linkedButtonColorValue]) ||
          theme?.palette.primary[500],

        '& > span::before': {
          width: '100%',
        },
      },
      '&:focus': {
        color:
          (linkedButtonColorValue && focusColorMap[linkedButtonColorValue]) ||
          theme?.palette.primary[700],
        textDecoration: 'underline',
      },
      '&:focus:hover': {
        textDecoration: 'none',
      },
      '&:disabled': {
        color: theme?.palette.neutral[200],
      },
      '&.MuiButton-textSizeSmall': {
        height: '32px',
      },
      '&.MuiButton-textSizeMedium': {
        height: '40px',
      },
      '&.MuiButton-textSizeLarge': {
        height: '48px',
      },
    },

    '& .MuiButton-startIcon': {
      margin: 0,
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiButton-endIcon': {
      margin: 0,
      '&::before': {
        display: 'none',
      },
    },
  };
});

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

const inlineDotPulseBefore = keyframes`
  0% {
    box-shadow: 9990px 0 0 -5px;
  }
  40% {
    box-shadow: 9990px 0 0 2px;
  }
  80%, 100% {
    box-shadow: 9990px 0 0 -5px;
  }
`;

const inlineDotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  40% {
    box-shadow: 9999px 0 0 2px;
  }
  80%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const inlineDotPulseAfter = keyframes`
  0% {
    box-shadow: 10008px 0 0 -5px;
  }
  40% {
    box-shadow: 10008px 0 0 2px;
  }
  80%, 100% {
    box-shadow: 10008px 0 0 -5px;
  }
`;

export const LoadingWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  width: '60px',
  justifyContent: 'center',
}));

export const InlineLoadingWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  paddingRight: '30px',
  justifyContent: 'center',
}));

export const LoadingIndicator = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'inline',
})<{ inline?: boolean }>(({ theme, inline }) => ({
  position: 'relative',
  left: '-9999px',
  width: inline ? '4px' : '10px',
  height: inline ? '4px' : '10px',
  borderRadius: '5px',
  backgroundColor: theme.palette.neutral[500],
  color: theme.palette.neutral[500],
  animation: inline
    ? `${inlineDotPulse} 1.5s infinite linear`
    : `${dotPulse} 1.5s infinite linear`,
  animationDelay: '0.25s',
  top: inline ? '10px' : 0,
  marginLeft: inline ? '25px' : 'initial',
  '&::before, &::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    width: inline ? '4px' : '10px',
    height: inline ? '4px' : '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.neutral[500],
    color: theme.palette.neutral[500],
  },

  '&::before': {
    right: inline ? '4px' : '8px',
    animation: inline
      ? `${inlineDotPulseBefore} 1.5s infinite linear`
      : `${dotPulseBefore} 1.5s infinite linear`,
    animationDelay: '0s',
  },

  '&::after': {
    left: inline ? '4px' : '8px',
    animation: inline
      ? `${inlineDotPulseAfter} 1.5s infinite linear`
      : `${dotPulseAfter} 1.5s infinite linear`,
    animationDelay: '0.5s',
  },
}));
