import { styled } from '@frontend/theme';
import {
  Autocomplete as MUIAutocomplete,
  Popper as MUIPopper,
} from '@mui/material';
import { AutocompleteOption } from './Autocomplete.types';

export const MUIAutocompleteSt = styled(MUIAutocomplete<AutocompleteOption>)(
  ({ theme }) => ({
    width: '100%',

    // Text input
    '& > .MuiFormControl-root > .MuiInputBase-root > input': {
      color: theme.palette.neutral[500],
      fontSize: theme.typography.p3.fontSize,
      fontStyle: theme.typography.p3.fontStyle,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.p3.lineHeight,
      padding: '0 0 0 7px',
    },
  }),
);

export const MUIPopperSt = styled(MUIPopper)(({ theme }) => ({
  '& > .MuiPaper-root': {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary[50]}`,
    borderRadius: 0,
    boxShadow: theme.shadows[2],
    overflow: 'auto',
    // Dropdown
    '& > .MuiAutocomplete-listbox': {
      padding: 0,
      '& > .MuiAutocomplete-option': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.neutral[500],
        fontSize: theme.typography.p3.fontSize,
        fontStyle: theme.typography.p3.fontStyle,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.typography.p3.lineHeight,
        '&.Mui-focused': {
          backgroundColor: theme.palette.neutral[50],
        },
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.primary[50],
          fontWeight: theme.typography.fontWeightMedium,
        },
        '&[aria-selected="true"]:hover': {
          backgroundColor: theme.palette.neutral[50],
        },
      },
    },
  },
}));
