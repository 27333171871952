import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const PlaceholderImageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.neutral[50],
  width: '100%',
  height: '100%',
  minHeight: '126px',
  padding: '16px 8px',
  cursor: 'pointer',

  '& .MuiTypography-root': {
    textAlign: 'center',
    maxWidth: '125px',
  },
}));

export const PlaceholderSlideWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.neutral[50],
  width: '100%',
  height: '100%',

  '& .MuiTypography-root': {
    textAlign: 'center',
    maxWidth: '280px',
  },
}));
