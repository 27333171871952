import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IBillOfLadingErrors,
  IBillOfLading,
  IBillOfLadingRequest,
} from './upsertBillOfLading.types';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { getQueryKey as getOrdersListQueryKey } from '../getRequestOrderList/getRequestOrderList.util';

const submitUpdateBillOfLading = (
  billOfLading: IBillOfLading,
  apiUrl: string,
  orderId: string,
) => {
  return axiosClient.put(
    `${apiUrl}/orders/${orderId}/bill-of-lading/`,
    billOfLading,
  );
};

const submitCreateBillOfLading = (
  billOfLading: IBillOfLading,
  apiUrl: string,
  orderId: string,
) => {
  return axiosClient.post(
    `${apiUrl}/orders/${orderId}/bill-of-lading/`,
    billOfLading,
  );
};

const useCreateBillOfLading = (orderId: string, requestId: string) => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosError<IBillOfLadingErrors>,
    IBillOfLadingRequest
  >({
    mutationFn: ({ billOfLading, newRecord }: IBillOfLadingRequest) => {
      if (newRecord)
        return submitCreateBillOfLading(billOfLading, apiUrl, orderId);
      else return submitUpdateBillOfLading(billOfLading, apiUrl, orderId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getOrdersListQueryKey(requestId),
      });
    },
  });
};

export default useCreateBillOfLading;
