import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  TGetRequestListResponse,
  IGetRequestListResponseFailure,
} from './getRequestList.types';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { QueryKey } from './getRequestList.const';

const submitGetRequestList = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/trading/requests/`);
};

const useGetRequestListQuery = () => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetRequestListResponse>,
    AxiosError<IGetRequestListResponseFailure>,
    AxiosResponse<TGetRequestListResponse>
  >({
    queryFn: () => submitGetRequestList(apiUrl),
    queryKey: QueryKey,
  });
};

export default useGetRequestListQuery;
