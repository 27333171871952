import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IUpdateNotificationBannerStatusParams,
  IUpdateNotificationBannerStatusResponse,
  IUpdateNotificationBannerStatusResponseFailure,
} from './updateNotificationBannerStatus.types';
import { QueryKey as getNotificationBannerQueryKey } from '../getNotificationBanner/getNotificationBanner.const';

const submitUpdateNotificationBannerStatus = (
  apiUrl: string,
  { uuid, isSeen }: IUpdateNotificationBannerStatusParams,
) => {
  const requestBody = {
    isSeen: isSeen,
  };
  return axiosClient.patch(
    `${apiUrl}/notifications/${uuid}/update/`,
    requestBody,
  );
};

const useGetNotificationBannerStatus = () => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IUpdateNotificationBannerStatusResponse>,
    AxiosError<IUpdateNotificationBannerStatusResponseFailure>,
    IUpdateNotificationBannerStatusParams
  >({
    mutationFn: (
      notificationBannerParams: IUpdateNotificationBannerStatusParams,
    ) => submitUpdateNotificationBannerStatus(apiUrl, notificationBannerParams),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getNotificationBannerQueryKey,
      });
    },
  });
};

export default useGetNotificationBannerStatus;
