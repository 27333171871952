import { styled } from '@frontend/theme';
import {
  Box as MUIBox,
  Checkbox as MUICheckbox,
  FormControlLabel as MUIFormControlLabel,
} from '@mui/material';

export const CheckboxInput = styled(MUICheckbox)({
  padding: '8px',
});

export const CheckBoxLabel = styled(MUIFormControlLabel)({
  marginRight: 0,
});

export const CheckBoxWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '100%',
});
export const HelperTextWrapper = styled(MUIBox)({
  paddingLeft: '16px',
  width: '100%',
});
