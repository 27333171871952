import { useContext } from 'react';
import fileSaver from 'file-saver';
import { ApiContext } from '../ApiContext/ApiContext';
import { IMAGE_VARIANT_TYPE } from '../getImage/getImage.types';
import axiosClient from '../axiosClient';

const useDownloadAllImages = () => {
  const { apiUrl } = useContext(ApiContext);

  const downloadAllImages = async (
    variantId: string,
    variant: IMAGE_VARIANT_TYPE,
    publicName: string,
  ) => {
    const getDownloadUrl = () => {
      const IMAGE_URL_VARIANTS = {
        CONTAINER: `${apiUrl}/orders/containers/${variantId}/loading-photos/download/`,
        LISTING: `${apiUrl}/trading/seller/listings/${variantId}/photos/download/`,
      } as Record<IMAGE_VARIANT_TYPE, string>;

      return IMAGE_URL_VARIANTS[variant] || '';
    };

    return axiosClient
      .get(getDownloadUrl(), {
        responseType: 'blob',
      })
      .then(response => {
        fileSaver.saveAs(
          response.data,
          `${variant.toString().toLowerCase()}_${publicName}_photos.zip`,
        );
      })
      .catch(error => error);
  };

  return { downloadAllImages };
};

export default useDownloadAllImages;
