import * as yup from 'yup';
import {
  FILE_MANAGER_MAX_FILE_SIZE,
  FILE_MANAGER_SUPPORTED_FORMATS,
} from './FileManagerForm.const';
import { FileManagerFormSchemaTranslations } from './FileManagerForm.types';

function fileSizeValidation(file: File) {
  return file.size <= FILE_MANAGER_MAX_FILE_SIZE;
}

function fileTypeValidation(file: File) {
  return Object.values(FILE_MANAGER_SUPPORTED_FORMATS).includes(file.type);
}

export default function getFileManagerFormSchema(
  translations: FileManagerFormSchemaTranslations,
) {
  const fileArrayShape = yup
    .array()
    .of(
      yup
        .mixed<File>()
        .test(
          'fileType',
          translations.fileType,
          file => file && fileTypeValidation(file),
        )
        .test(
          'fileSize',
          translations.fileSize,
          file => file && fileSizeValidation(file),
        )
        .required(),
    )
    .optional();

  return yup.lazy(value => {
    return value.sellerOrderDocuments
      ? yup.object().shape({
          sellerOrderDocuments: fileArrayShape,
        })
      : yup.object().shape({
          sellerOrderPhotos: fileArrayShape,
        });
  });
}
