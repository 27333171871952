import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../../translations/en.json';

type TranslationProviderProps = {
  children?: ReactNode;
};

const allowedLocale = {
  en: English,
};
const defaultLocale = 'en';
const locale = 'en';

const message: Record<string, string> =
  allowedLocale[locale] ?? allowedLocale[defaultLocale];

function TranslationProvider({ children }: TranslationProviderProps) {
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={message}
    >
      {children}
    </IntlProvider>
  );
}

export default TranslationProvider;
