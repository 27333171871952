import { PLATFORM_ROUTES } from '@frontend/routing';
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useSearchParams,
} from 'react-router-dom';
import useAuth from '../AuthContext/useAuth';
import PlatformHelmet from '../PlatformHelmet/PlatformHelmet';

const PublicRoute = () => {
  const {
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
    isCurrentOperationModeBuyer,
  } = useAuth();
  const location = useLocation();
  const matchesEmailVerificationPath = useMatch(
    PLATFORM_ROUTES.emailVerification,
  );
  const [searchParams] = useSearchParams();
  const redirectUrl: string = searchParams.get('redirectUrl') ?? '';
  const sourceUrl: string = searchParams.get('sourceUrl') ?? '';

  const getPathFromRoleAndOperationMode = () => {
    if (isCurrentUserSeller && isCurrentUserBuyer) {
      return isCurrentOperationModeBuyer
        ? PLATFORM_ROUTES.buyerDashboard
        : PLATFORM_ROUTES.sellerDashboard;
    } else {
      return isCurrentUserBuyer
        ? PLATFORM_ROUTES.buyerDashboard
        : PLATFORM_ROUTES.sellerDashboard;
    }
  };

  const getRootPathFromSourceUrl = () => {
    const sourceUrl = searchParams.get('sourceUrl');
    if (sourceUrl === PLATFORM_ROUTES.buyMetal && isCurrentUserBuyer) {
      return PLATFORM_ROUTES.buyerDashboard;
    }
    if (sourceUrl === PLATFORM_ROUTES.sellerDashboard && isCurrentUserSeller) {
      return PLATFORM_ROUTES.sellerDashboard;
    }
    return getPathFromRoleAndOperationMode();
  };

  const getDestinationFromCurrentUser = () => {
    return isCurrentUserSeller && isCurrentUserBuyer && sourceUrl
      ? getRootPathFromSourceUrl()
      : getPathFromRoleAndOperationMode();
  };

  if (currentUser && !matchesEmailVerificationPath) {
    const toPath = redirectUrl ? redirectUrl : getDestinationFromCurrentUser();

    return <Navigate to={toPath} state={{ from: location }} replace />;
  }

  return (
    <>
      <PlatformHelmet />
      <Outlet />
    </>
  );
};

export default PublicRoute;
