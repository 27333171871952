import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { DocumentListProps } from './DocumentList.types';
import DocumentCard from './DocumentCard/DocumentCard';
import EmptyDocuments from './EmptyDocuments/EmptyDocuments';
import { Translate } from '@frontend/translation';
import { ButtonsWrapper, DocumentsWrapper } from './DocumentList.css';
import { Button } from '@frontend/ui-elements';
import DownloadAllDocuments from './DownloadAllDocuments/DownloadAllDocuments';

export default function DocumentList({
  documentContextList,
  fullWidth = false,
}: DocumentListProps) {
  const [itemsToShow, setItemsToShow] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('tabletL'));
  const isTablet = useMediaQuery(theme.breakpoints.up('tablet'));

  const getMinItemsToShow = useCallback(() => {
    switch (true) {
      case isDesktop:
        return 5;
      case isTablet:
        return 4;
      default:
        return 3;
    }
  }, [isDesktop, isTablet]);

  useEffect(() => {
    setItemsToShow(getMinItemsToShow());
  }, [getMinItemsToShow, isDesktop, isTablet]);

  const showMore = () => {
    setItemsToShow(documentContextList.length);
  };

  const showLess = () => {
    setItemsToShow(getMinItemsToShow());
  };

  return documentContextList.length ? (
    <>
      <DocumentsWrapper>
        {documentContextList
          ?.slice(0, itemsToShow)
          .map(documentContext => (
            <DocumentCard
              documentContext={documentContext}
              id="document-list"
              key={documentContext.document.uniqueIdentifier}
            />
          ))}
      </DocumentsWrapper>
      <ButtonsWrapper>
        {documentContextList.length > getMinItemsToShow() &&
          (documentContextList.length > itemsToShow ? (
            <Button buttonType="linked" size="large" onClick={showMore}>
              <Translate id="customer-platform.request-details.order-list.show-more" />
            </Button>
          ) : (
            <Button buttonType="linked" size="large" onClick={showLess}>
              <Translate id="customer-platform.request-details.order-list.show-less" />
            </Button>
          ))}
        <DownloadAllDocuments
          documentContextList={documentContextList}
          id="request-documents.button.download-all"
        />
      </ButtonsWrapper>
    </>
  ) : (
    <EmptyDocuments fullWidth={fullWidth} />
  );
}
