import { styled } from '@frontend/theme';
import { MapContainer } from 'react-leaflet';
import { Box as MUIBox } from '@mui/material';

export const LocationMapWrapper = styled(MUIBox)(() => ({
  width: '100%',
  height: '100%',
  minHeight: '100%',
}));

export const Map = styled(MapContainer)(() => ({
  height: '100%',
  '& .leaflet-div-icon': {
    //Makes div under markers transparent
    background: 'transparent',
    border: 'none',
  },
}));
