import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetListingListResponseFailure,
  TGetListingListResponse,
} from './getListingList.types';
import { QueryKey } from './getListingList.const';

const submitGetListingList = (apiUrl: string) => {
  return axiosClient.get(`${apiUrl}/trading/listings/`);
};

const useGetListingListQuery = () => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetListingListResponse>,
    AxiosError<IGetListingListResponseFailure>,
    AxiosResponse<TGetListingListResponse>
  >({
    queryFn: () => submitGetListingList(apiUrl),
    queryKey: QueryKey,
  });
};

export default useGetListingListQuery;
