import { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TScrollToSection } from './useListingOrderCardScroll.types';

export function useListingOrderCardScroll({
  enableScroll,
}: {
  enableScroll: boolean;
}) {
  const orderBasicDetailsSectionRef: RefObject<HTMLDivElement> = useRef(null);
  const orderCardRef: RefObject<HTMLDivElement> = useRef(null);

  const location = useLocation();
  const queryParamsSection = new URLSearchParams(location?.search).get(
    'scrollToSection',
  ) as TScrollToSection | null;

  useEffect(() => {
    if (orderCardRef.current && enableScroll) {
      let scrollToRef: RefObject<HTMLDivElement> | null = null;

      if (queryParamsSection === 'orderBasicDetails') {
        scrollToRef = orderBasicDetailsSectionRef;
      }
      setTimeout(() => {
        if (orderCardRef.current) {
          const refToScroll = scrollToRef?.current || orderCardRef.current;
          refToScroll.scrollIntoView({
            behavior: 'instant',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 100);
    }
  }, [enableScroll, queryParamsSection]);

  return {
    orderCardRef,
    orderBasicDetailsSectionRef,
  };
}
